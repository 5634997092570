import { CommonTrackAPI, TrackAPI } from "./musicSlice";
import { Box, Stack, Typography } from "@mui/material";
import { Favorite, PlayCircleOutlined, StopCircleOutlined } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import "./beatSyncIcon.scss"
import { musicItemHelper } from "./musicItemHelper";
import { ReadMoreTypography } from "../ui/readMoreTypography";
import IconButton from "@mui/material/IconButton";
import { amplitudeFeatureFlagsVariables } from "../amplitude/amplitudeFeatureFlagsVariables";
import { musicServices } from "../../services/music.services";
import { TRACK_TYPE_MELODIE, TRACK_TYPE_UPLOADED } from "../constants";
import { MouseEvent, useCallback, useContext } from 'react';
import { FavoriteTrackIdsContext } from "../context/favoriteTrackIdsContext";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { eventTracker } from "../../helpers/eventTracker";

interface MusicItemProps
{
  track: CommonTrackAPI | TrackAPI;
  handleClicked: () => void;
  selected: boolean;
  loading: boolean;
  showStopIcon: boolean;
  showBottomText: boolean;
  showFavoriteIcon: boolean;
}

export function MusicItemView( props: MusicItemProps )
{
  const DEBOUNCE_DELAY = 200;
  const dispatch = useDispatch();
  const favoriteTrackIdsByTypeContext = useContext( FavoriteTrackIdsContext );
  const selected = props.selected;
  const loading = props.loading;
  const showMusicFavoriteIcon = props.showFavoriteIcon && amplitudeFeatureFlagsVariables.shouldShowMusicFavorites();
  const debounceSendToggleFavoriteTrack = useCallback( debounce( sendToggleFavoriteTrack, DEBOUNCE_DELAY ), [] );

  function getIcon()
  {
    const iconStyle = { height: "45px", width: "45px" };
    if ( props.showStopIcon )
    {
      return <StopCircleOutlined sx={iconStyle}/>
    }
    else
    {
      return <PlayCircleOutlined sx={iconStyle}/>
    }
  }

  async function sendToggleFavoriteTrack( newFavoritedValue: boolean )
  {
    try
    {
      const favoritedTrackPayload = await musicServices.favoriteMusicTrack( props.track, newFavoritedValue );
      favoriteTrackIdsByTypeContext.updateFavoriteTrackIds( props.track, favoritedTrackPayload.favorited );

      if ( newFavoritedValue )
      {
        eventTracker.logMusicTrackFavorited( props.track );
      }
      else
      {
        eventTracker.logMusicTrackUnfavorited( props.track );
      }
    }
    catch (e)
    {
      favoriteTrackIdsByTypeContext.refreshFavoriteTrackIdsMap();
      dispatch( setAlertMessage( errorAlert( "An error occurred while updating favorites. Please try again later." ) ) );
    }
  }

  function handleToggleFavoriteTrack( event: MouseEvent<HTMLButtonElement> )
  {
    event.stopPropagation();

    if ( props.track.type === TRACK_TYPE_MELODIE || props.track.type === TRACK_TYPE_UPLOADED )
    {
      const newFavoritedValue = !isFavoritedTrack();
      favoriteTrackIdsByTypeContext.updateFavoriteTrackIds( props.track, newFavoritedValue );
      debounceSendToggleFavoriteTrack( newFavoritedValue );
    }
  }

  function isFavoritedTrack()
  {
    if ( props.track.type === TRACK_TYPE_MELODIE )
    {
      return favoriteTrackIdsByTypeContext[TRACK_TYPE_MELODIE][props.track.id];
    }
    else if ( props.track.type === TRACK_TYPE_UPLOADED )
    {
      return favoriteTrackIdsByTypeContext[TRACK_TYPE_UPLOADED][props.track.id];
    }
    return false;
  }

  function getFavoriteIcon()
  {
    return (<IconButton sx={{ width: 45, height: 45, color: isFavoritedTrack() ? "red" : "black" }}
                        onClick={handleToggleFavoriteTrack}>
      {isFavoritedTrack() ? <Favorite/> : <FavoriteBorderIcon/>}
    </IconButton>);
  }

  const border = selected ? "2px solid #3f51b5" : "2px solid transparent";
  const bottomText = musicItemHelper.getBottomText( props.track );
  return (
    <Box sx={{ display: "flex", m: 5, border, borderRadius: "5px", position: "relative", cursor: "pointer" }} onClick={props.handleClicked}>
      {getIcon()}

      <Stack sx={{ ml: 5 }} justifyContent={"center"}>
        <Stack direction="row" sx={{ width: "250px" }} gap={4} alignItems={"center"}>
          <Typography sx={{ fontWeight: "bold" }} noWrap>{props.track.display_name}</Typography>
        </Stack>
        {!!bottomText && props.showBottomText && <Box sx={{ width: "250px", color: "#9D9D9D" }}><ReadMoreTypography moreText="Show more"
                                                                                                                    lessText="Show less"
                                                                                                                    cutoffLength={45}>{bottomText}</ReadMoreTypography></Box>}
      </Stack>

      {loading && <Box sx={{
        position: "absolute",
        background: "rgba(0,0,0,.15)",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "5px",
        pointerEvents: "none"
      }}>
        <CircularProgress sx={{ position: "absolute", top: "calc(50% - 20px)", left: "calc(50% - 20px)" }}/>
      </Box>}
      {showMusicFavoriteIcon && getFavoriteIcon()}
    </Box>
  );
}
