import { SxProps } from "@mui/material";
import { merge } from "lodash";
import { ReactNode } from "react";
import { BaseMusicItemView } from "./BaseMusicItemView";

interface SelectableMusicItemViewProps
{
  selected: boolean;
  handleOnClick: () => void;
  icon: ReactNode;
  text: string;
  sx?: SxProps;
}

export function SelectableMusicItemView( props: SelectableMusicItemViewProps )
{
  const border = props.selected ? { border: "2px solid #3f51b5" } : { border: "2px solid transparent" };
  return (
    <BaseMusicItemView handleOnClick={props.handleOnClick} icon={props.icon} text={props.text} sx={merge( border, props.sx )}/>
  );
}