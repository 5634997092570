import React from "react";
import { Stack, Typography } from "@mui/material";
import { WarningAmber } from "@mui/icons-material";

interface LoadMusicErrorViewProps
{
  title: string;
  children?: React.ReactNode;
}

export function LoadMusicErrorView( props: LoadMusicErrorViewProps )
{
  return (<Stack justifyContent={"center"} alignItems={"center"} sx={{ my: 20 }}>
    <WarningAmber sx={{ width: 50, height: 50 }}/>
    <Typography>{props.title}</Typography>
    {props.children}
  </Stack>)
}
