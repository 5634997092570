import { createContext } from "react";
import { TRACK_TYPE_MELODIE, TRACK_TYPE_UPLOADED } from "../constants";
import { CommonTrackAPI } from "../music/musicSlice";

export interface FavoriteTrackIdsMappedContextType
{
  [TRACK_TYPE_MELODIE]: { [key: string]: boolean };
  [TRACK_TYPE_UPLOADED]: { [key: string]: boolean };
}

export interface FavoriteTrackIdsContextType extends FavoriteTrackIdsMappedContextType
{
  updateFavoriteTrackIds: ( track: CommonTrackAPI, favorited: boolean ) => void;
  refreshFavoriteTrackIdsMap: () => void;
}

export const FavoriteTrackIdsContext = createContext<FavoriteTrackIdsContextType>( {} as FavoriteTrackIdsContextType );