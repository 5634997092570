import { Post } from "./postsSlice";
import { addHours, parseISO } from "date-fns";
import { musicServices } from "../../services/music.services";
import { postIdeaServices } from "../../services/postIdeaServices";
import { chain, concat, findIndex, includes, replace, size, some } from "lodash";
import { TemplateInputData } from "../assistantChat/assistantChatSlice";
import { DesignOutputMode, MAX_NUMBER_OF_MEDIA, TRACK_TYPE_EPIDEMIC_SOUND } from "../constants";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { store } from "../../app/store";

const S3_CURATED_MEDIA_ASSETS_PARTIAL_PATH = "shared-assets/curated_media";

export function getHasAudioInPost( post?: Post )
{
  if ( !post )
  {
    return false;
  }
  const globalSettingsData = JSON.parse( post.global_settings_data );
  return !!globalSettingsData?.musicData?.audio_url;
}

export async function refreshExpiredEpidemicMusicTrack( post: Post, customErrorMessage?: string )
{
  if ( !getHasAudioInPost( post ) )
  {
    return;
  }
  const globalSettingsData = JSON.parse( post.global_settings_data )
  const musicData = globalSettingsData.musicData;
  const isEpidemicTrack = musicData.type === TRACK_TYPE_EPIDEMIC_SOUND;
  if ( !isEpidemicTrack )
  {
    return;
  }
  const epidemicTrackId = musicData.id;
  const epidemicTrackExpiration = musicData.metadata.expires;

  const expirationWindowToEnsureUpdatedEpidemicTrack = 6;
  const effectiveExpirationDateTime = addHours( new Date(), expirationWindowToEnsureUpdatedEpidemicTrack );

  if ( parseISO( epidemicTrackExpiration ) < effectiveExpirationDateTime )
  {
    try
    {
      const track = await musicServices.getEpidemicSoundTrack( epidemicTrackId );
      const startTimeInSeconds = track.start_time_in_seconds ? track.start_time_in_seconds : 0;
      return postIdeaServices.replaceMusic( post.post_idea_id, track.url, epidemicTrackId, TRACK_TYPE_EPIDEMIC_SOUND, track.expires,
        startTimeInSeconds );
    }
    catch (error)
    {
      const errorMessage = customErrorMessage || "There was a problem refreshing your music. Select a new track from the Music tab.";
      store.dispatch( setAlertMessage( errorAlert( errorMessage ) ) );
      return Promise.reject( "Unable to refresh music track" );
    }
  }
}

function getTemplateInputData( post: Post ): TemplateInputData
{
  return JSON.parse( post.template_input_data ) as TemplateInputData;
}

function getTemplateInputDataMediaList( post: Post )
{
  let templateInputData = getTemplateInputData( post );
  return templateInputData.mediaList;
}

export function getTemplateInputDataMediaUrls( post: Post )
{
  const mediaList = getTemplateInputDataMediaList( post );
  return mediaList.map( ( media: any ) => media.media_url );
}

export function hasInputVideosWithAudio( post: Post )
{
  const mediaList = getTemplateInputDataMediaList( post );
  return some( mediaList, ( mediaItem ) => mediaItem.is_video );
}

export function isAnimatedPost( post: Post )
{
  const templateInputData = getTemplateInputData( post );
  const designOutputMode = templateInputData.designOutputMode;
  return !designOutputMode || designOutputMode === DesignOutputMode.Animated;
}

export function isStatic( post: Post )
{
  const templateInputData = getTemplateInputData( post );
  const designOutputMode = templateInputData.designOutputMode;
  return designOutputMode === DesignOutputMode.Static;
}

export function canAddUrls( post: Post, urlsToAdd: string[] )
{
  const totalNewUrlsIfAdded = concat( getOrderedMediaUrls( post ), urlsToAdd );
  return size( totalNewUrlsIfAdded ) <= MAX_NUMBER_OF_MEDIA;
}

export function maxMediaReached( post: Post )
{
  const urls = getOrderedMediaUrls( post );
  return maxMediaReachedForUrls( urls );
}

export function maxMediaReachedForUrls( urls: string[] )
{
  return size( urls ) >= MAX_NUMBER_OF_MEDIA;
}

export function getOrderedMediaUrls( post: Post ): string[]
{
  const urls = getTemplateInputDataMediaUrls( post );
  let globalSettingsData = JSON.parse( post.global_settings_data )
  const orderedMediaIds: string[] = chain( globalSettingsData.slides )
    .map( ( slide: any ) =>
    {
      const slideStoreElement = globalSettingsData.slideStore[slide];
      const isBrandSlide = slideStoreElement.slide_config_type === "brand_slide";
      if ( isBrandSlide )
      {
        return [];
      }
      return slideStoreElement.mediaIds
    } )
    .flatten()
    .value();

  return chain( orderedMediaIds ).map( ( mediaId: string ) =>
  {
    const fileNameFromMediaId = replace( mediaId, /\?\d+/, '' );
    const idx = findIndex( urls, ( url ) => includes( url, fileNameFromMediaId ) );
    if ( idx !== -1 )
    {
      return urls[idx];
    }
    return "";
  } ).compact().uniq().value();
}

export function containsCuratedMedia( post: Post )
{
  const urls = getTemplateInputDataMediaUrls( post );
  return some( urls, ( url ) => includes( url, S3_CURATED_MEDIA_ASSETS_PARTIAL_PATH ) );
}

export function allowRemoveMedia( post: Post )
{
  return post.allow_add_or_remove_media && getOrderedMediaUrls( post ).length > 1;
}

export function allowAddMedia( post: Post )
{
  return post.allow_add_or_remove_media;
}

