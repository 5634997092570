import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { CommonTrackAPI, MusicSelection } from "./musicSlice";
import * as React from "react";
import { useContext, useRef, useState } from "react";
import { PostIdeaContext } from "../context/postIdeaContext";
import { getSelectedTrackIdFromPostJson, getSelectedTrackTypeFromPostJson } from "../postIdea/postIdeaHelper";
import { useDispatch } from "react-redux";
import { isNumber, map, size, uniqBy } from "lodash";
import { eventTracker } from "../../helpers/eventTracker";
import { logToConsoleError } from "../utils/devLoggingHelper";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import InfiniteScroll from "react-infinite-scroller";
import { CircularProgress, Stack } from "@mui/material";
import { MusicItem } from "./MusicItem";
import { musicItemHelper } from "./musicItemHelper";
import { EmptyMusicView } from "./EmptyMusicView";
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import { EditMelodieMusicHeader } from "../editing/editMelodieMusicHeader";
import { FullScreenModalInnerWrap } from "../editing/fullScreenModalInnerWrap";
import { AudioDrawer } from "../ui/audioDrawer";
import { musicServices } from "../../services/music.services";
import { LoadMusicErrorView } from "./LoadMusicErrorView";
import RefreshIcon from '@mui/icons-material/Refresh';

const HEADER_HEIGHT = "65px";

interface MyFavoritesListProps extends WithFullScreenDialogProps
{
  pendingSelection?: MusicSelection;
  setPendingSelection( pendingSelection: MusicSelection | undefined ): void;
  handleCloseAfterApplyingMusic: () => void;
}

function MyFavoritesList( props: MyFavoritesListProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const selectedTrackId = getSelectedTrackIdFromPostJson( postIdea );
  const selectedTrackType = getSelectedTrackTypeFromPostJson( postIdea );
  let tracks = useRef<CommonTrackAPI[]>( [] );

  const [nextPage, setNextPage] = useState<number | undefined>( 1 );
  const [hasError, setHasError] = useState<boolean>( false );
  const [loading, setLoading] = useState( false );
  const dispatch = useDispatch();

  function hasTracks()
  {
    return size( tracks.current ) > 0;
  }

  function handleMusicSelected( track: MusicSelection )
  {
    logMusicChanged( track.id );
    props.setPendingSelection( track );
  }

  function logMusicChanged( toTrackId: string )
  {
    const fromTrackType = props.pendingSelection ? props.pendingSelection.type : selectedTrackType;
    const fromTrackId = props.pendingSelection ? props.pendingSelection.id : selectedTrackId;
    const fromTrackIdToReport = musicItemHelper.getTrackIdForReporting( fromTrackType, fromTrackId );

    eventTracker.logEditPostMusicChanged( postIdea, fromTrackIdToReport, toTrackId );
  }

  function loadMore( page: number )
  {
    // ReactInfiniteScroller page param is ignored because it doesn't sync up with the nextPage that we want to load from server
    if ( isNumber( nextPage ) )
    {
      loadNextPageOfFavoritedTracks( nextPage );
    }
  }

  function loadNextPageOfFavoritedTracks( page: number )
  {
    if ( !isNumber( page ) || loading )
    {
      return;
    }

    setLoading( true );
    musicServices.getMyFavorites( page ).then(
      ( response ) =>
      {
        const uniqueTracks = uniqBy( [...tracks.current, ...response.tracks], "id" );
        tracks.current = uniqueTracks;
        if ( response.next_page )
        {
          setNextPage( response.next_page );
        }
        else
        {
          setNextPage( undefined );
        }
      }
    )
      .catch( ( e ) =>
      {
        logToConsoleError( "my favorites load error", e );
        dispatch( setAlertMessage( errorAlert( "There was a problem loading your favorited tracks. Please try again." ) ) );
        setHasError( true )
      } )
      .finally( () =>
      {
        setLoading( false );
      } );
  }

  function hasMore()
  {
    return !loading && !hasError && isNumber( nextPage );
  }

  function retryOnError()
  {
    setHasError( false );
  }

  function contents()
  {
    if ( isNumber( nextPage ) || hasTracks() )
    {
      return <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore()}
        loader={<Stack key="0" alignItems="center" sx={{ my: 4 }}><CircularProgress className="spinner small" id="spinner"/></Stack>}
        useWindow={false}>
        {map( tracks.current, ( track ) => <MusicItem key={track.id} track={track} handleTrackSelected={handleMusicSelected}
                                                      selected={musicItemHelper.isTrackIdSelected( track.id, track.type,
                                                        selectedTrackId,
                                                        selectedTrackType,
                                                        props.pendingSelection )}/> )}
        {hasError && <LoadMusicErrorView title={"Error loading favorites"}>
          <RefreshIcon onClick={retryOnError}/>
        </LoadMusicErrorView>}
      </InfiniteScroll>;
    }
    else if ( !loading )
    {
      return <EmptyMusicView title={"No favorites yet"}/>;
    }
    else
    {
      return <Stack alignItems="center" sx={{ mt: 60 }}>
        <CircularProgress/>
      </Stack>
    }
  }

  return (
    <FullPageMobileContainer>
      <EditMelodieMusicHeader title="Add music"
                              subtitle={"My Favorites"}
                              handleClose={props.handleClose}
                              useArrowIcon={true}
                              sx={{ height: HEADER_HEIGHT }}/>
      <FullScreenModalInnerWrap>
        <Stack width="100%" sx={{ overflow: "auto", height: `calc(100vh - ${HEADER_HEIGHT})` }}>
          {contents()}
        </Stack>
      </FullScreenModalInnerWrap>
      <AudioDrawer pendingSelection={props.pendingSelection} setPendingSelection={props.setPendingSelection}
                   closeAfterApplying={props.handleCloseAfterApplyingMusic}/>
    </FullPageMobileContainer>
  );
}

export const MyFavoritesFullScreenDialog = withFullScreenDialog( MyFavoritesList )