import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import StarIcon from '@mui/icons-material/StarBorder';
import { PlanItemIconLabel } from "./PlanItemIconLabel";
import { BusinessHolidayAPI } from "../../services/business.services";
import { PlanAccordionHeader } from "./planAccordionHeader";
import { map } from "lodash";
import { HolidayCarouselLayoutPreview } from "./holidayCarouselLayoutPreview";
import { HolidayLayoutPreviewDialog } from "./holidayLayoutPreviewDialog";
import { HolidayLayoutDataAPI, holidayLayoutServices } from "../../services/holidayLayout.services";
import { postIdeaServices } from "../../services/postIdeaServices";
import { useDispatch } from "react-redux";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { errorReporter } from "../error/errorReporter";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { ROUTE_PARAM, ROUTES } from "../constants";
import { parseISO } from "date-fns";
import { getInitialScheduleTimeForDraft } from "../postIdea/shareBatchHelper";
import { PostIdeaEditor } from "../editing/postIdeaEditor";

interface HolidayCarouselProps
{
  businessHoliday: BusinessHolidayAPI;
}

export function HolidayCarousel( props: HolidayCarouselProps )
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const [showHolidayLayoutPreview, setShowHolidayLayoutPreview] = React.useState( false );
  const selectedHolidayLayoutRef = React.useRef<HolidayLayoutDataAPI | undefined>( undefined );
  const holidayScheduleDate = parseISO( props.businessHoliday.date );
  const [postIdeaIdForEditor, setPostIdeaIdForEditor] = useState<string>();
  const [loading, setLoading] = useState<boolean>( false );

  function getAccentColor()
  {
    return "planIdea.main";
  }

  function getLabel()
  {
    return "Holiday";
  }

  function getIcon()
  {
    return <PlanItemIconLabel label={getLabel()} color={getAccentColor()}>
      <StarIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
    </PlanItemIconLabel>;
  }

  function getHolidayLayouts()
  {
    return props.businessHoliday.holiday_collection?.layouts;
  }

  async function handleHolidayLayoutSelected( slug: string )
  {
    const layoutData = await holidayLayoutServices.getHolidayLayoutData( slug );
    if ( selectedHolidayLayoutRef.current?.slug !== layoutData.slug )
    {
      setPostIdeaIdForEditor( undefined )
    }
    selectedHolidayLayoutRef.current = layoutData;
    setShowHolidayLayoutPreview( true );
  }

  function handleClosePreview()
  {
    setShowHolidayLayoutPreview( false );
  }

  async function handleOpenEditorWithLayout( holidayLayout: HolidayLayoutDataAPI )
  {
    setLoading( true );
    await generateHolidayPostIdea( holidayLayout );
  }

  async function generateHolidayPostIdea( holidayLayout: HolidayLayoutDataAPI )
  {
    if ( !postIdeaIdForEditor )
    {
      try
      {
        const generatedPostIdea = await postIdeaServices.generateHolidayPostIdea( props.businessHoliday.id, holidayLayout.slug );
        setPostIdeaIdForEditor( generatedPostIdea.id );
      }
      catch (error)
      {
        dispatch( setAlertMessage( errorAlert( "Could not load holiday post idea details.", 'top' ) ) );
        errorReporter.reportErrorToSentry( error );
        setLoading( false );
      }
    }
  }

  function handleCloseFullScreenEditor()
  {
    setShowHolidayLayoutPreview( false )
    navigateWithSearchParams( ROUTES.PLAN, { [ROUTE_PARAM.PLAN_TAB_REFRESH_REQUEST]: true } );
  }

  function handlePostIdeaReadyToEdit()
  {
    setLoading( false );
  }

  function handleErrorLoadingPostIdeaData()
  {
    setPostIdeaIdForEditor( undefined );
    setLoading( false );
  }

  return <Box sx={{
    my: 5,
    backgroundColor: "planIdea.light",
    borderRadius: "5px",
    p: 0,
  }}>
    <PlanAccordionHeader icon={getIcon()}
                         accentColor={getAccentColor()}
                         expanded={false}
                         hideExpandIcon={true}>
    </PlanAccordionHeader>
    <Typography sx={{ fontSize: "14px", textAlign: "left", fontWeight: "bold", ml: 5, mb: 5 }}>{props.businessHoliday.holiday_name}</Typography>
    <Stack sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      overflowY: "hidden",
      height: 145,
      gap: 5,
      px: 5,
      mx: 5,
    }}>
      {
        map( getHolidayLayouts(), ( layout, index ) =>
          <HolidayCarouselLayoutPreview key={index} layout={layout} handleHolidayLayoutSelected={handleHolidayLayoutSelected}/>
        )
      }
    </Stack>
    {!!selectedHolidayLayoutRef.current && <HolidayLayoutPreviewDialog open={showHolidayLayoutPreview}
                                                                       handleClose={handleClosePreview}
                                                                       holidayLayout={selectedHolidayLayoutRef.current}
                                                                       handleLayoutSelected={handleOpenEditorWithLayout}
                                                                       loading={loading}/>}


    {!!postIdeaIdForEditor && <PostIdeaEditor postIdeaId={postIdeaIdForEditor}
                                              initialScheduleForDate={getInitialScheduleTimeForDraft( holidayScheduleDate )}
                                              handlePostIdeaReadyToEdit={handlePostIdeaReadyToEdit}
                                              handleClose={handleCloseFullScreenEditor}
                                              handleErrorLoadingPostIdeaData={handleErrorLoadingPostIdeaData}/>}
  </Box>
}
