import React from "react";
import { Stack, Typography } from "@mui/material";
import { MusicNote } from "@mui/icons-material";

interface EmptyMusicViewProps
{
  title: string;
  children?: React.ReactNode;
}

export function EmptyMusicView( props: EmptyMusicViewProps )
{
  return (<Stack justifyContent={"center"} alignItems={"center"} sx={{ mt: 60 }}>
    <MusicNote sx={{ width: 100, height: 100 }}/>
    <Typography>{props.title}</Typography>
    {props.children}
  </Stack>)
}
