import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Stack, Typography } from "@mui/material";
import { map } from "lodash";
import { isAmplitudeFeatureFlagsInitialized } from "./amplitudeFeatureFlagsSlice";
import { amplitudeFeatureFlagsVariables } from "./amplitudeFeatureFlagsVariables";

export function AmplitudeFeatureFlagVariablesLogView()
{
  const [featureFlags, setFeatureFlags] = useState( {} );

  useEffect( () =>
    {
      amplitudeFeatureFlagsVariables.BOOLEAN_FEATURE_FLAGS.forEach( ( featureFlag ) =>
      {
        amplitudeFeatureFlagsVariables.isFeatureEnabled( featureFlag )
          .then( ( flag ) =>
          {
            setFeatureFlags( ( prev ) => ({ ...prev, [featureFlag]: flag }) );
          } )
      } );
    }, []
  );

  const isInitialized = useSelector( ( state: RootState ) => isAmplitudeFeatureFlagsInitialized( state ) );
  return (
    <Stack>
      <Typography variant="subtitle2">Amplitude feature flag state log</Typography>
      <Typography variant="caption">initialized: {isInitialized ? "true" : "false"}</Typography>
      <table style={{ fontSize: "10px" }}>
        <thead>
        <tr>
          <th>Name</th>
          <th>Direct (isFeatureFlagEnabled)</th>
        </tr>
        </thead>

        <tbody>
        {map( amplitudeFeatureFlagsVariables.BOOLEAN_FEATURE_FLAGS, ( featureFlag ) =>
        {
          return <tr key={featureFlag}>
            <td>{featureFlag}</td>
            <td>{featureFlags[featureFlag] ? "true" : "false"}</td>
          </tr>
        } )}
        </tbody>
      </table>
    </Stack>
  )
}
