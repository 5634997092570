import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "../../app/store";

export interface AmplitudeFeatureFlagsState
{
  isInitialized: boolean;
  featureFlags?: {},
}

export const initialState: AmplitudeFeatureFlagsState = {
  isInitialized: false,
}

export const amplitudeFeatureFlagsSlice = createSlice( {
  name: 'amplitudeFeatureFlags',
  initialState,
  reducers: {
    setAmplitudeFeatureFlagsInitialized: ( state, action ) =>
    {
      return {
        ...state,
        isInitialized: action.payload,
      }
    },
  },
} )

export const isAmplitudeFeatureFlagsInitialized = ( state: RootState ) => state.amplitudeFeatureFlags.isInitialized;

export const {
  setAmplitudeFeatureFlagsInitialized,
} = amplitudeFeatureFlagsSlice.actions

export default amplitudeFeatureFlagsSlice.reducer
