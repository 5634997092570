import { logToConsole, logToConsoleError } from "../utils/devLoggingHelper";
import { getUserSlug } from "../user/userSlice";
import { store } from "../../app/store";
import { authenticationServices } from "../../services/authentication.services";
import { iosSubscriberServices } from "../../services/ios_subscription.services";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";

export const iOSMessageSender = {
  startSubscription,
  login,
  logout,
  startDownload,
  startManageSubscription,
  setupCommandsAppCanInvoke,
}

function setupCommandsAppCanInvoke()
{
  if ( window.webkit )
  {
    window.subscribeComplete = ( successFlag: boolean, entitlementLookupKey: string ) =>
    {
      store.dispatch( setApplicationLoadingState( true ) );

      iosSubscriberServices.createSubscriber( entitlementLookupKey ).then( () =>
      {
        authenticationServices.refreshUser();
      } ).finally( () =>
      {
        store.dispatch( setApplicationLoadingState( false ) );
      } );
      logToConsole( "subscribeComplete message received with successFlag", successFlag );
    }
    window.downloadComplete = ( successFlag: boolean ) =>
    {
      logToConsole( "downloadComplete message received with successFlag", successFlag );
    }
    window.connectSocialNetworkComplete = ( successFlag: boolean, errorData?: object ) =>
    {
      logToConsole( "connectSocialNetworkComplete message received with successFlag", successFlag, "error data?", errorData );
      if ( window.iosConnectSocialAccountsListener )
      {
        window.iosConnectSocialAccountsListener( successFlag, errorData );
        window.iosConnectSocialAccountsListener = undefined;
      }
    }
  }
}

function startSubscription()
{
  sendMessageIfSupported( 'subscribe', {
    "message": "startSubscription",
    "userSlug": getUserSlug( store.getState() ),
  } );
}

function startDownload( outputMediaUrl: String, outputFileName: String )
{
  sendMessageIfSupported( 'download', {
    "message": "startDownload",
    "outputFileName": outputFileName,
    "outputMediaUrl": outputMediaUrl,
  } );
}

function startManageSubscription(useNativeManageSubscriptionUX: boolean)
{
  sendMessageIfSupported( 'manageSubscription', {
    "message": "startManageSubscription",
    "userSlug": getUserSlug( store.getState() ),
    "useNativeManageSubscriptionUX": useNativeManageSubscriptionUX,
  } );
}

function login()
{
  sendMessageIfSupported( 'login', {
    "userSlug": getUserSlug( store.getState() )
  } );
}

function logout()
{
  sendMessageIfSupported( 'logout', {
    "userSlug": getUserSlug( store.getState() )
  } );
}

function sendMessageIfSupported( messageName: string, payload: any )
{
  // @ts-ignore
  const webkit = window.webkit;
  if ( webkit && webkit.messageHandlers && webkit.messageHandlers[messageName] )
  {
    logToConsole( `Sending iOS message "${messageName}" with payload ${JSON.stringify( payload || {} )}` )
    webkit.messageHandlers[messageName].postMessage( payload )
    return true;
  }
  else
  {
    logToConsoleError( `iOS message handler "${messageName}" is not supported` );
  }
  return false;
}
