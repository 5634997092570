import { Accordion, AccordionDetails, Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { PostPreview } from "../postIdea/postPreview";
import { postIdeaServices, ShareBatchAPI } from "../../services/postIdeaServices";
import { addPost, convertPost } from "../postIdea/postsSlice";
import { FullScreenEditor } from "../editing/editAndSharePostFlow";
import { useDispatch, useSelector } from "react-redux";
import { eventTracker } from "../../helpers/eventTracker";
import { PostIdeaContext } from "../context/postIdeaContext";
import { PlanItemIconLabel } from "./PlanItemIconLabel";
import { PlanAccordionSummary } from "./planAccordionSummary";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { errorReporter } from "../error/errorReporter";
import { PlanItemCtaFooterContainer } from "./planItemCTAFooterContainer";
import EditIcon from "@mui/icons-material/Edit";
import { PlanIdeaTitleTypography } from "./planIdeaTitleTypography";
import { setPlanLastPostIdeaEdited, setUpsellShown } from "../ui/uiSlice";
import { ShareDrawer } from "./shareDrawer";
import { RootState } from "../../app/store";
import { shouldBlockForUpsell } from "../user/userSlice";
import { needsRecording } from "../postIdea/postIdeaHelper";
import SendIcon from "@mui/icons-material/Send";
import { ShareNowTabContentSources } from "../editing/directShare";
import { PlanDateContext } from "../context/planDateContext";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { ROUTE_PARAM, ROUTES } from "../constants";
import { isDevModeEnabled } from "../featureFlags/featureFlagsSlice";
import { addPostIdea, getPostIdea } from "../postIdea/postIdeaSlice";
import { join } from "lodash";
import { OutputFormatIconAndLabel } from "./outputFormatIconAndLabelSmall";
import { ContentGoalIconAndLabel } from "./contentGoalIconAndLabelSmall";
import { isShareBatchCanceled, isShareBatchDraft } from "../postIdea/shareBatchHelper";

interface PlanIdeaPreviewProps
{
  postIdeaTitle?: string;
  expanded: boolean;
  postIdeaId?: string;
  initialScheduleTimeForDate: Date;
  shareBatch?: ShareBatchAPI;
  outputFormatSlug?: string;
  contentGoal?: string;
}

export function PlanIdeaPreview( props: PlanIdeaPreviewProps )
{
  const dispatch = useDispatch();
  const devMode = useSelector( isDevModeEnabled );

  const [editButtonEnabled, setEditButtonEnabled] = React.useState<boolean>( true );
  const savedPostIdeaTitle = props.postIdeaTitle;
  const [expanded, setExpanded] = React.useState<boolean>( props.expanded );
  const [postJson, setPostJson] = React.useState<any>( null );
  const postIdeaDataFromStore = useSelector( ( state: any ) => getPostIdea( state, props.postIdeaId ) );
  const [postIdea, setPostIdea] = React.useState<any>( postIdeaDataFromStore );
  const [showEditDialog, setShowEditDialog] = React.useState( false );
  const [loading, setLoading] = useState<boolean>( false );

  const [showShareDrawer, setShowShareDrawer] = React.useState( false );
  const blockForUpsell = useSelector( ( state: RootState ) => shouldBlockForUpsell( state ) );
  const [shareButtonEnabled, setShareButtonEnabled] = React.useState<boolean>( true );

  const initialScheduleTimeForDraft = props.initialScheduleTimeForDate;

  const planDateContext = useContext( PlanDateContext );
  const planDate = planDateContext.planDate;

  const navigateWithSearchParams = useNavigateWithSearchParams();
  useEffect( () =>
  {
    if ( !!postIdeaDataFromStore )
    {
      // must resync post idea when new data in chat part of store otherwise ui may not sync
      setPostIdea( postIdeaDataFromStore );
    }
  }, [postIdeaDataFromStore] );

  const handleEditClicked = () =>
  {
    eventTracker.logPlanIdeaEditClicked( planDate, savedPostIdeaTitle, props.postIdeaId );
    setEditButtonEnabled( false );
    setShowEditDialog( true );
    dispatch( setPlanLastPostIdeaEdited( props.postIdeaId ) );
  }

  async function handleCloseFullScreenEditor()
  {
    setEditButtonEnabled( true );
    setShowEditDialog( false );
    await fetchPostIdea( true );
    navigateWithSearchParams( ROUTES.PLAN, { [ROUTE_PARAM.PLAN_TAB_REFRESH_REQUEST]: true } );
  }

  async function fetchPostIdea( forcePostIdeaFetch?: boolean )
  {
    if ( !!props.postIdeaId && (!postIdea || forcePostIdeaFetch) )
    {
      try
      {
        setLoading( true );
        const postIdeaData = await postIdeaServices.getPostIdeaData( props.postIdeaId )
        dispatch( addPostIdea( postIdeaData ) )
        setPostIdea( postIdeaData );
        await fetchPostJson();
        return postIdeaData;
      }
      catch (error)
      {
        if ( !postJson )
        {
          dispatch( setAlertMessage( errorAlert( "Could not load post idea details.", 'top' ) ) );
        }
        errorReporter.reportErrorToSentry( error );
      }
      setLoading( false );
    }
    else
    {
      await fetchPostJson();
      return postIdea;
    }
  }

  async function fetchPostJson()
  {
    if ( !!props.postIdeaId )
    {
      try
      {
        setLoading( true );
        const postJson = await postIdeaServices.getPostJson( props.postIdeaId )
        const convertedPostJson = convertPost( postJson )
        setPostJson( convertedPostJson );
        dispatch( addPost( postJson ) );
      }
      catch (error)
      {
        if ( !postJson )
        {
          dispatch( setAlertMessage( errorAlert( "Could not load post details.", 'top' ) ) );
        }
        errorReporter.reportErrorToSentry( error );
      }
      setLoading( false );
    }
  }

  async function handleAccordionClicked()
  {
    if ( !expanded )
    {
      setExpanded( true );

      await fetchPostIdea();
    }
    else
    {
      setExpanded( false );
    }

  }

  function getAccentColor()
  {
    return "planDraft.main";
  }

  function getLabel()
  {
    if ( devMode && !!props.shareBatch )
    {
      if ( isShareBatchCanceled( props.shareBatch ) )
      {
        return "Draft (canceled ShareBatch)";
      }
      else if ( isShareBatchDraft( props.shareBatch ) )
      {
        return "Draft (drafted ShareBatch)";
      }
    }
    return "Draft";
  }

  function getIcon()
  {
    return <PlanItemIconLabel label={getLabel()} color={getAccentColor()}>
      <EditNoteIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
    </PlanItemIconLabel>;
  }

  function planCTAFooter()
  {
    const editButtonVariant = "outlined";
    if ( !postJson && expanded && !loading )
    {
      return <PlanItemCtaFooterContainer>
        <Button onClick={handleRetryClicked} variant="contained" sx={{ mx: "auto", my: 5 }}>Retry</Button>
      </PlanItemCtaFooterContainer>
    }
    return <PlanItemCtaFooterContainer>
      <Button
        startIcon={<EditIcon/>}
        variant={editButtonVariant}
        disabled={!editButtonEnabled}
        sx={{ display: "flex", ml: "auto" }}
        onClick={handleEditClicked}>
        Edit post
      </Button>
      <Button
        startIcon={<SendIcon/>}
        variant="contained"
        disabled={!shareButtonEnabled}
        onClick={handleShareClicked}>
        Share
      </Button>
    </PlanItemCtaFooterContainer>
  }

  async function handleShareClicked()
  {
    const postIdeaFetched = await fetchPostIdea();

    if ( !!postIdeaFetched )
    {
      eventTracker.logPlanPostShareClicked( postIdeaFetched );
      if ( blockForUpsell && needsRecording( postIdeaFetched ) )
      {
        dispatch( setUpsellShown( true ) );
      }
      else
      {
        setShareButtonEnabled( false );
        setShowShareDrawer( true );
      }
    }
  }

  function onDrawerClosed()
  {
    setShowShareDrawer( false );
    setShareButtonEnabled( true );
  }

  async function handleRetryClicked()
  {
    await fetchPostJson();
  }

  const iframeId = join( ["planPreview", postJson?.post_idea_id, props.shareBatch?.id], "_" );

  return <Box sx={{ mb: 5 }}>
    <Accordion
      expanded={expanded}
      sx={{
        backgroundColor: "planDraft.light",
        borderRadius: "5px",
        p: 0,
      }}>
      <PlanAccordionSummary
        onClick={handleAccordionClicked}
        icon={getIcon()}
        savedPostIdeaTitle={<Stack>
          <PlanIdeaTitleTypography>{savedPostIdeaTitle}</PlanIdeaTitleTypography>
          <OutputFormatIconAndLabel outputFormatSlug={props.outputFormatSlug}/>
          <ContentGoalIconAndLabel contentGoal={props.contentGoal}/>
        </Stack>}
        accentColor={getAccentColor()}
        expanded={expanded}
        allowedToCollapse={true}
        planCTAFooter={planCTAFooter()}/>
      <AccordionDetails sx={{ p: 0 }}>
        {!!postJson && expanded &&
         <Box sx={{ borderRadius: "5px", mb: 5 }}>
           <PostPreview post={postJson}
                        iframeId={iframeId}
                        showSafeArea={false}
                        showInstagramOverlay={false}/>
         </Box>
        }
        {!!postJson && postIdea && <Typography variant={"body1"}
                                               sx={{
                                                 mx: "auto",
                                                 my: 5,
                                                 width: "90%",
                                                 color: "text.primary",
                                                 textAlign: "left"
                                               }}>{postIdea.caption}</Typography>}
        {!postJson && !loading
         && <Box>
           <Typography variant={"subtitle1"}>There was a problem loading your post</Typography>
         </Box>
        }
        {planCTAFooter()}
      </AccordionDetails>
    </Accordion>
    {!!postIdea && <PostIdeaContext.Provider value={{ postIdea }}>
      <FullScreenEditor
        editable={true}
        externalStateOpen={showEditDialog}
        handleClose={handleCloseFullScreenEditor}
        shouldSuppressFeedback={true}
        initialScheduleForDate={initialScheduleTimeForDraft}
        shareBatch={props.shareBatch}/>
      {showShareDrawer && !!postJson && <ShareDrawer sharedFrom={ShareNowTabContentSources.PLAN}
                                                     postIdea={postIdea}
                                                     showShareDrawer={showShareDrawer}
                                                     previousShareBatch={props.shareBatch}
                                                     closeDrawer={onDrawerClosed}
                                                     onCloseFromBackdropClick={onDrawerClosed}
                                                     initialScheduleForDate={initialScheduleTimeForDraft}/>}
    </PostIdeaContext.Provider>}
  </Box>
}
