import { Box, Grid, Stack, SxProps, Typography } from "@mui/material";
import { MelodieGenreGroupOrPurposeAPI } from "./musicSlice";

interface MelodieGenreGroupOrPurposeItemProps
{
  melodieGenreGroupOrPurpose: MelodieGenreGroupOrPurposeAPI;
  onClick: ( melodieGenreGroupOrPurpose: MelodieGenreGroupOrPurposeAPI ) => void;
  sx?: SxProps
}

export function MelodieGenreGroupOrPurposeItem( props: MelodieGenreGroupOrPurposeItemProps )
{

  function handleOnClick()
  {
    props.onClick( props.melodieGenreGroupOrPurpose )
  }

  return (
    <Grid item xs={6}>
      <Stack direction="row" onClick={handleOnClick} alignItems="center" justifyContent="flex-start" sx={props.sx}>
        <Box sx={{
          width: "50px",
          height: "50px",
          ml: 2,
          background: `url('${props.melodieGenreGroupOrPurpose.image_url}'), url('${props.melodieGenreGroupOrPurpose.default_image_url}')`,
          backgroundSize: "contain",
          minWidth: "50px"
        }}/>
        <Typography variant="subtitle1" sx={{ ml: 5, lineHeight: "22px" }}>{props.melodieGenreGroupOrPurpose.display_name
                                                                            || props.melodieGenreGroupOrPurpose.name}</Typography>
      </Stack>
    </Grid>
  );
}

