import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useEffect, useRef } from "react";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { UpsellBenefitRow } from "./upsellBenefitRow";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentDialogOpen, setUpsellShown } from "../ui/uiSlice";
import { PurchaseFlowCTA } from "../payment/purchaseFlowCTA";
import { PurchaseFlowHeader } from "../payment/purchaseFlowHeader";
import {
  ALKAI_SUBSCRIPTION_TERMS_FAQ_URL,
  CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT,
  PAYWALL_BUTTON_GRADIENT,
  PAYWALL_GRADIENT,
  UPSELL_GRADIENT
} from "../constants";
import { isGuestUser } from "../user/userSlice";
import { RootState } from "../../app/store";
import { CreateAccountOrSignIn } from "../registration/createAccountOrSignIn";
import { eventTracker } from "../../helpers/eventTracker";
import UpsellSeal from "../../assets/upsell-seal.svg";
import { AlkaiWordmarkLogoWhite } from "../registration/alkaiWordmarkLogoWhite";
import { amplitudeFeatureFlagsVariables } from "../amplitude/amplitudeFeatureFlagsVariables";
import BackgroundImage from "../../assets/upsell_background_with_overlay.jpg";
import GetAlkaiPro from "../../assets/get_alkai_pro.svg";
import FiveStars from "../../assets/five_stars_red.svg";
import { PaywallBenefitPill } from "./paywallBenefitPill";
import { PaywallBenefitsSection } from "./paywallBenefitsSection";
import CircularProgress from "@mui/material/CircularProgress";
import { browserUtils } from "../utils/browserUtils";
import { iOSMessageSender } from "../payment/iOSMessageSender";

function UpsellContainer( props: WithFullScreenDialogProps )
{
  const [loading, setLoading] = React.useState<boolean>( true );
  const [shouldShowJan2025Paywall, setShouldShowJan2025Paywall] = React.useState<boolean>( false );

  useEffect( () =>
  {
    if ( props.externalStateOpen )
    {
      amplitudeFeatureFlagsVariables.shouldShowJan2025Paywall().then( ( value ) =>
      {
        if ( value )
        {
          setShouldShowJan2025Paywall( true );
        }
        else
        {
          setShouldShowJan2025Paywall( false );
        }
      } ).catch( ( error ) =>
      {
        setShouldShowJan2025Paywall( false );
      } ).finally( () =>
      {
        setLoading( false );
      } );
    }
  }, [props.externalStateOpen] );

  if ( loading )
  {
    return <Stack sx={{ width: "100vw", height: "100vh", alignItems: "center", justifyContent: "center" }}><CircularProgress/></Stack>
  }
  else if ( shouldShowJan2025Paywall )
  {
    return <Jan2025Paywall {...props}/>
  }
  else
  {
    return <Upsell {...props}/>
  }
}

function Upsell( props: WithFullScreenDialogProps )
{
  const dispatch = useDispatch();
  const userIsGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const userIsGuestRef = useRef( userIsGuest );
  const [showCreateLogin, setShowCreateLogin] = React.useState<boolean>( false );
  const isInAlkaiIOSApp = browserUtils.isAlkaiIOSApp();

  useEffect( () =>
  {
    userIsGuestRef.current = userIsGuest;
  }, [userIsGuest] );

  const handleCloseUpsell = () =>
  {
    eventTracker.logUpsellCancelClicked( userIsGuest );
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  function openSubscriptionFlow()
  {
    if ( isInAlkaiIOSApp )
    {
      iOSMessageSender.startSubscription();
    }
    else
    {
      dispatch( setPaymentDialogOpen( true ) );
    }
  }

  function handleStripeCheckout()
  {
    eventTracker.logUpsellContinueToPaymentClicked( userIsGuest );
    if ( userIsGuest )
    {
      setShowCreateLogin( true )
    }
    else
    {
      dispatch( setUpsellShown( false ) );
      openSubscriptionFlow();
    }
  }

  function handleAccountCreated()
  {
    setShowCreateLogin( false );
    if ( !userIsGuestRef.current )
    {
      dispatch( setUpsellShown( false ) );
      openSubscriptionFlow();
    }
  }

  function getPurchaseFlowCTA()
  {
    return "Save 50% for 3 months!"
  }

  function getUpsellSeal()
  {
    let monthlyPriceAfterDiscount = "$20/mo";
    let ongoingMonthlyPrice = "$40";

    return (<Box sx={{ position: "absolute", width: "90px", height: "90px", top: "-65px", right: "-55px" }}>
      <Box component="img" src={UpsellSeal} alt="save now" sx={{ width: "100%", position: "absolute", top: 0, left: 0, right: 0 }}/>
      <Stack sx={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, right: 0 }} alignItems="center"
             justifyContent="center">
        <Typography variant="subtitle2"
                    sx={{ textDecoration: "line-through", color: "white", fontSize: "16px", lineHeight: "18px" }}>{ongoingMonthlyPrice}</Typography>
        <Typography variant="subtitle2"
                    sx={{ color: "white", fontSize: "16px", lineHeight: "18px" }}>{monthlyPriceAfterDiscount}</Typography>
      </Stack>
    </Box>);
  }

  return (
    <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>
      <IconButton
        aria-label="close"
        color="secondary"
        size="small"
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
          zIndex: 1,
        }}
        onClick={handleCloseUpsell}>
        <CloseIcon fontSize="inherit"/>
      </IconButton>
      <Stack sx={{ display: "flex", alignItems: "center", my: 12, mx: "auto" }}>
        <AlkaiWordmarkLogoWhite sx={{ width: "110px" }}/>
        <PurchaseFlowHeader sx={{ width: "280px" }}>Your AI Social Media Assistant</PurchaseFlowHeader>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Compelling post ideas</Box> tailored for your business
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          Keeps you on track with a <Box component="span" fontWeight='bold'>custom weekly plan</Box>
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          Automatically creates <Box component="span" fontWeight='bold'>eye-catching content</Box> when it's time to post
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Unlimited</Box> ideas, content, and cloud storage
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Free stock</Box> photos, videos, and music
        </UpsellBenefitRow>
        <PurchaseFlowCTA onClick={handleStripeCheckout}>
          {getPurchaseFlowCTA()}
          {getUpsellSeal()}
        </PurchaseFlowCTA>
        <Typography variant="caption" sx={{ color: "primary.contrastText" }}>Discount available to first-time customers only.</Typography>
      </Stack>
      <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT}
                             shouldShowSignIn={showCreateLogin}
                             onClose={handleAccountCreated}/>
    </FullPageMobileContainer>
  )
}

function Jan2025Paywall( props: WithFullScreenDialogProps )
{
  const dispatch = useDispatch();
  const userIsGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const userIsGuestRef = useRef( userIsGuest );
  const [showCreateLogin, setShowCreateLogin] = React.useState<boolean>( false );
  const isInAlkaiIOSApp = browserUtils.isAlkaiIOSApp();

  useEffect( () =>
  {
    userIsGuestRef.current = userIsGuest;
  }, [userIsGuest] );

  const handleCloseUpsell = () =>
  {
    eventTracker.logUpsellCancelClicked( userIsGuest );
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  function openSubscriptionFlow()
  {
    if ( isInAlkaiIOSApp )
    {
      iOSMessageSender.startSubscription();
    }
    else
    {
      dispatch( setPaymentDialogOpen( true ) );
    }
  }

  function handleStripeCheckout()
  {
    eventTracker.logUpsellContinueToPaymentClicked( userIsGuest );
    if ( userIsGuest )
    {
      setShowCreateLogin( true )
    }
    else
    {
      dispatch( setUpsellShown( false ) );
      openSubscriptionFlow();
    }
  }

  function handleAccountCreated()
  {
    setShowCreateLogin( false );
    if ( !userIsGuestRef.current )
    {
      dispatch( setUpsellShown( false ) );
      openSubscriptionFlow();
    }
  }

  return (
    <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>
      <Stack sx={{ width: "100%", maxWidth: "475px", mx: "auto", position: "relative" }}>
        <Stack position={"absolute"} sx={{ width: "100%", alignItems: "center", height: "100%" }}>
          <Box
            sx={{
              minHeight: "440px",
              height: "60svh",
              width: "100%",
              background: `url(${BackgroundImage})`,
              backgroundPosition: "top center",
              backgroundSize: "cover"
            }}>
          </Box>
        </Stack>
        <Stack sx={{ width: "100%", alignItems: "center", pt: "6svh", zIndex: 1, minHeight: "100svh", justifyContent: "space-between" }}>
          <IconButton
            aria-label="close"
            color="secondary"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            onClick={handleCloseUpsell}>
            <CloseIcon sx={{ color: "white", fontSize: "36px" }}/>
          </IconButton>
          <Box component="img" alt="Alkai" width="55%" src={GetAlkaiPro} sx={{}}/>
          <Box>
            <Stack sx={{ width: "100%", alignItems: "center" }}>
              <Box component="img" alt="Alkai" width="32%" src={FiveStars}/>
              <Typography color={"white"} textAlign={"center"} width={"78%"}
                          sx={{ mt: "10px", lineHeight: "19px", fontWeight: 400, fontFamily: "Roboto", fontSize: "14px" }}>
                "This has been the easiest way to get months worth of content completed, in a very minimal amount of time."
              </Typography>
            </Stack>

            <Box sx={{ width: "100%", position: "relative", mt: "30px", minHeight: "calc(40svh + 60px)" }}>
              <Box sx={{
                position: "absolute",
                top: "40px",
                bottom: 0,
                width: "100%",
                backgroundColor: "black",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px"
              }}>
              </Box>
              <Box sx={{ width: "86%", mx: "auto", position: "relative" }}>
                <PaywallBenefitsSection/>
                <Box sx={{ background: PAYWALL_GRADIENT, borderRadius: "10px", mt: "20px", py: "1px", width: "100%" }}>
                  <Box sx={{ background: "black", borderRadius: "10px", mx: "auto", width: "calc(100% - 2px)" }}>
                    <Stack sx={{ mx: "auto", py: "20px", width: "88%" }} spacing={8}>
                      <Stack>
                        <PaywallBenefitPill>Introductory 50% Offer</PaywallBenefitPill>
                        <Stack direction={"row"} sx={{ alignItems: "center", mt: "8px" }}>
                          <Box sx={{ position: "relative", mr: "5px" }}>
                            <Typography variant={"h5"} sx={{ color: "white", fontWeight: "bold", fontSize: "20px" }}>$40</Typography>
                            <Box sx={{ height: "2px", width: "35px", backgroundColor: "white", position: "absolute", top: "calc(50% - 1px)" }}/>
                          </Box>
                          <Typography sx={{ color: "white", fontSize: "12px", fontWeight: "500" }}>$20/month for 3 months, $40/month
                                                                                                   after</Typography>
                        </Stack>
                      </Stack>
                      <Button sx={{
                        background: PAYWALL_BUTTON_GRADIENT,
                        color: "white",
                        textTransform: "none",
                        height: "50px",
                        fontSize: "16px",
                        borderRadius: "6px"
                      }}
                              onClick={handleStripeCheckout}
                      >Continue</Button>
                    </Stack>
                  </Box>
                </Box>
                <Stack direction={"row"} sx={{ py: "6px", flexWrap: "wrap", justifyContent: "center" }}>
                  <Typography variant={"caption"} sx={{ color: "white", fontSize: "9px" }}>No commitment. Cancel anytime. Bills
                                                                                           Monthly.&nbsp;</Typography>
                  <Link variant={"caption"} target="_blank" rel="noopener" sx={{ color: "white", textDecoration: "underline", fontSize: "9px" }}
                        href={ALKAI_SUBSCRIPTION_TERMS_FAQ_URL}>Subscription Terms</Link>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Stack>
      <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT}
                             shouldShowSignIn={showCreateLogin}
                             onClose={handleAccountCreated}/>
    </FullPageMobileContainer>
  )
}

export const FullScreenUpsellDialog = withFullScreenDialog( UpsellContainer )
