import { Box, Stack, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import { chain, filter, groupBy, head, isEmpty, map, partition, size, sortBy, values } from "lodash";
import { DateHeader } from "./dateHeader";
import { businessServices, PlannerDateAPI, PlanSuggestionAPI, PostingPlanDateAPI } from "../../services/business.services";
import React from "react";
import { eventTracker } from "../../helpers/eventTracker";
import { PlanIdeaPreview } from "./planIdeaPreview";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DateUtils } from "../utils/dateUtils";
import { useDispatch } from "react-redux";
import { setPlanLastDateClicked } from "../ui/uiSlice";
import { ShareBatchAPI } from "../../services/postIdeaServices";
import { CompletedPostView } from "./completedPostView";
import {
  getDraftedAtDate,
  getEffectiveCompletedDisplayDate,
  getInitialScheduleTimeForDraft,
  getValidScheduledDate,
  isShareBatchDraft
} from "../postIdea/shareBatchHelper";
import { ScheduledPostView } from "./scheduledPostView";
import { PlanDateContext } from "../context/planDateContext";
import { PlanIdeaForm } from "./planIdeaForm";
import { HolidayCarousel } from "./holidayCarousel";
import { amplitudeFeatureFlagsVariables } from "../amplitude/amplitudeFeatureFlagsVariables";

interface DateStackProps
{
  plannerDateAPI: PlannerDateAPI;
  handleUpdatePlannerData: () => void;
}

export function DateStack( props: DateStackProps )
{
  const shouldAllowEditingOfCompletedPosts = amplitudeFeatureFlagsVariables.shouldAllowEditingOfCompletedPosts();

  const date = parseISO( props.plannerDateAPI.date_with_timezone );
  const dateStackId = DateUtils.formatDateToYearMonthDay( date );

  const shareBatches = props.plannerDateAPI.share_batches;
  const postingPlanDates = props.plannerDateAPI.posting_plan_dates;

  const postingPlanDateWithoutGeneratedPostIdea = filter( postingPlanDates,
    ( postingPlanDate ) => isEmpty( postingPlanDate?.plan_suggestion?.post_idea_id ) );
  const drafts = props.plannerDateAPI.suggestions
  const businessHolidays = props.plannerDateAPI.business_holidays;
  const holidayName = size( businessHolidays ) > 0 ? businessHolidays[0].holiday_name : undefined;

  const [userAddingPlanIdea, setUserAddingPlanIdea] = React.useState( false );

  const showAddPlanIdeaButton = !userAddingPlanIdea &&
                                size( postingPlanDates ) === 0 &&
                                size( drafts ) === 0 &&
                                size( shareBatches ) === 0;
  const dispatch = useDispatch();

  function getShareBatchesWithRolledUpDownloads()
  {
    const nonDraftShareBatches = filter( shareBatches, ( shareBatch: ShareBatchAPI ) => !isShareBatchDraft( shareBatch ) );
    const partitionedShareBatches = partition( nonDraftShareBatches, ( shareBatch: ShareBatchAPI ) => size( shareBatch.social_network_posts ) > 0 );
    const shareBatchesWithSocialPosts = partitionedShareBatches[0];
    const shareBatchesDownloadOnly = partitionedShareBatches[1];

    const partitionedDownloadOnlyShareBatches = partition( shareBatchesDownloadOnly,
      ( shareBatch: ShareBatchAPI ) => !!shareBatch.scheduled_for && !shareBatch.completed_at );
    const shareBatchesDownloadOnlyScheduled = partitionedDownloadOnlyShareBatches[0];
    const shareBatchesDownloadOnlyNotScheduled = partitionedDownloadOnlyShareBatches[1];

    // we want to roll up the completed posts that are download only, grouping by unique video and or image url
    const downloadOnlyNotScheduledGroupedByMediaUrls = groupBy( shareBatchesDownloadOnlyNotScheduled,
      ( shareBatch: ShareBatchAPI ) => `${shareBatch.video_url}${shareBatch.image_url}` );

    const groupedByValues = values( downloadOnlyNotScheduledGroupedByMediaUrls );
    const downloadOnlyShareBatchesToDisplay = map( groupedByValues, ( shareBatches: ShareBatchAPI[] ) =>
    {
      const sortedShareBatches = sortBy( shareBatches, ( shareBatch: ShareBatchAPI ) => new Date( shareBatch.first_downloaded_at ) );
      return head( sortedShareBatches );
    } );

    return chain( downloadOnlyShareBatchesToDisplay )
      .concat( shareBatchesDownloadOnlyScheduled )
      .concat( shareBatchesWithSocialPosts )
      .compact()
      .sortBy( ( shareBatch: ShareBatchAPI ) =>
      {
        return getEffectiveCompletedDisplayDate( shareBatch );
      } )
      .value();
  }

  const handleRequestAddPlannedPost = () =>
  {
    eventTracker.logPlanIdeaAddClicked( date );
    setUserAddingPlanIdea( true );
  }

  const handleRequestRemovePlannedPost = ( postingPlanDate?: PostingPlanDateAPI ) =>
  {
    if ( postingPlanDate )
    {
      businessServices.removePlannedPost( postingPlanDate ).then( () =>
      {
        props.handleUpdatePlannerData();
      } );
    }
    setUserAddingPlanIdea( false );
  }

  const handleRequestSavePlannedPost = ( postIdeaTitle: string, outputFormatSlug?: string, postingPlanDateId?: string ) =>
  {
    businessServices.addPlannedPost( date, postIdeaTitle, outputFormatSlug, postingPlanDateId ).then( () =>
    {
      props.handleUpdatePlannerData();
    } ).finally( () =>
    {
      setUserAddingPlanIdea( false );
    } );
  }

  function shouldShowPlanWithForms( date: Date )
  {
    const today = new Date();
    return date > today;
  }

  function handleDateStackClick()
  {
    dispatch( setPlanLastDateClicked( dateStackId ) );
  }

  const shareBatchesWithRolledUpDownloads = getShareBatchesWithRolledUpDownloads();
  const draftShareBatches = filter( shareBatches, ( shareBatch: ShareBatchAPI ) => isShareBatchDraft( shareBatch ) );

  const partitionedShareBatches = partition( shareBatchesWithRolledUpDownloads,
    ( shareBatch: ShareBatchAPI ) => !!shareBatch.scheduled_for && !shareBatch.completed_at );
  const shareBatchesScheduledOnly = partitionedShareBatches[0];
  const shareBatchesNotScheduled = partitionedShareBatches[1];

  return (
    <Stack id={dateStackId} key={props.plannerDateAPI.date_with_timezone} sx={{ mb: 10, px: 10, scrollMarginTop: "40px" }}
           onClick={handleDateStackClick}>
      <PlanDateContext.Provider value={{ planDate: date }}>
        <DateHeader date={date} holidayName={holidayName}/>
        {shouldShowPlanWithForms( date ) && showAddPlanIdeaButton &&
         <Box display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              onClick={handleRequestAddPlannedPost}
              sx={{ border: "2px dashed lightgray", borderRadius: "5px", p: 10 }}>
           <Stack direction={"row"} gap={"5px"} alignItems={"center"} sx={{ textAlign: "center" }}>
             <AddCircleOutlineIcon sx={{ color: "primary.main", height: "26px", width: "26px" }}/>
             <Typography variant={"body2"} sx={{ color: "primary.main" }}>Add a post idea to this day</Typography>
           </Stack>
         </Box>
        }

        {shouldShowPlanWithForms( date ) && userAddingPlanIdea &&
         <PlanIdeaForm key={`addingNewPlanIdea-${date}`}
                       requestRemovePlannedPost={handleRequestRemovePlannedPost}
                       requestSavePlannedPost={handleRequestSavePlannedPost}
                       handleUpdatePlannerData={props.handleUpdatePlannerData}
                       date={date}/>
        }

        {
          map( shareBatchesScheduledOnly, ( shareBatch: ShareBatchAPI ) =>
          {
            return <ScheduledPostView key={shareBatch.id} shareBatch={shareBatch} handleUpdatePlannerData={props.handleUpdatePlannerData}/>
          } )
        }

        {
          map( shareBatchesNotScheduled, ( shareBatch: ShareBatchAPI ) =>
          {
            return <CompletedPostView key={shareBatch.id} shareBatch={shareBatch} handleUpdatePlannerData={props.handleUpdatePlannerData}/>
          } )
        }

        {
          shouldAllowEditingOfCompletedPosts &&
          map( draftShareBatches, ( shareBatch: ShareBatchAPI ) =>
          {
            if ( !!shareBatch.post_idea )
            {
              const draftedAtDate = getDraftedAtDate( shareBatch ) || new Date();
              return <PlanIdeaPreview key={`${shareBatch.id}-${shareBatch.created_at}`}
                                      expanded={false}
                                      initialScheduleTimeForDate={getValidScheduledDate( draftedAtDate )}
                                      postIdeaTitle={shareBatch.post_idea?.topic_name}
                                      postIdeaId={shareBatch.post_idea.id}
                                      outputFormatSlug={shareBatch.post_idea.output_format_slug}
                                      shareBatch={shareBatch}/>;
            }
            return null;
          } )
        }

        {
          map( drafts, ( suggestion: PlanSuggestionAPI ) =>
          {
            return <PlanIdeaPreview key={`${suggestion.date_with_timezone}-${suggestion.post_idea_id}`}
                                    expanded={!suggestion}
                                    postIdeaTitle={suggestion.post_idea_title}
                                    postIdeaId={suggestion.post_idea_id}
                                    outputFormatSlug={suggestion.output_format_slug}
                                    contentGoal={suggestion.content_goal}
                                    initialScheduleTimeForDate={getInitialScheduleTimeForDraft( date )}/>
          } )
        }

        {
          map( postingPlanDateWithoutGeneratedPostIdea, ( postingPlanDate: PostingPlanDateAPI ) =>
          {
            return <PlanIdeaForm key={`planDateWithoutPostIdea-${postingPlanDate.id}`}
                                 postingPlanDate={postingPlanDate}
                                 requestRemovePlannedPost={handleRequestRemovePlannedPost}
                                 requestSavePlannedPost={handleRequestSavePlannedPost}
                                 handleUpdatePlannerData={props.handleUpdatePlannerData}
                                 date={date}/>
          } )
        }
        {
          map( businessHolidays, ( businessHoliday ) =>
          {
            if ( businessHoliday.has_post_idea )
            {
              return null;
            }
            return <HolidayCarousel key={`holidayCarousel-${businessHoliday.id}`} businessHoliday={businessHoliday}/>;
          } )
        }
      </PlanDateContext.Provider>
    </Stack>);
}
