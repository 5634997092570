import { clearBusiness } from "../business/businessSlice";
import { clearPosts } from "../postIdea/postsSlice";
import { clearUserData } from "./userSlice";
import { clearUI } from "../ui/uiSlice";
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from "@sentry/react";
import { clearAssistantChat } from "../assistantChat/assistantChatSlice";
import { chatServices } from "../../services/chat.services";
import { clearTracking } from "../tracking/trackingSlice";
import { clearAlertMessage, errorAlert, setAlertMessage } from "../alert/alertSlice";
import { clearAbortController, getAbortController } from "../../services/requestManager";
import { clearPostIdeas } from "../postIdea/postIdeaSlice";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";
import { businessServices } from "../../services/business.services";
import { authenticationServices } from "../../services/authentication.services";
import { ROUTE_PARAM, ROUTES } from "../constants";
import { inAppNoticeServices } from "../../services/inAppNotice.services";
import { authHelper } from "../../helpers/authHelper";
import { iOSMessageSender } from "../payment/iOSMessageSender";

export const userHelper = {
  signOutUser,
  clearBusinessSpecificData,
  clearChatAndSwitchBusiness,
  clearChatAndMakeNewBusiness,
  stopInFlightBrowserRequests,
  switchBusinesses,
}

function signOutUser( dispatch )
{
  stopInFlightBrowserRequests();

  iOSMessageSender.logout();
  clearBusinessSpecificData( dispatch )
  authHelper.removeAuthFromLocalStorage();
  dispatch( clearUserData() );
  dispatch( clearTracking() );

  amplitude.reset();
  Sentry.setUser( null );
}

async function clearChatAndSwitchBusiness( dispatch )
{
  dispatch( setApplicationLoadingState( true ) );
  try
  {
    stopInFlightBrowserRequests();
    clearBusinessSpecificData( dispatch );
    await authenticationServices.refreshUser();
    await inAppNoticeServices.getNotices();
  }
  catch (e)
  {
    dispatch( setAlertMessage( errorAlert( "Error refreshing user", "top" ) ) );
  }
  finally
  {
    dispatch( setApplicationLoadingState( false ) );
  }
}

function stopInFlightBrowserRequests()
{
  getAbortController().abort();
  clearAbortController();
}

function clearBusinessSpecificData( dispatch )
{
  chatServices.clearLongRunningFunctionTimeout();
  chatServices.clearCheckMessagesTimeout();
  dispatch( clearBusiness() );
  dispatch( clearAlertMessage() );
  dispatch( clearAssistantChat() );
  dispatch( clearPosts() );
  dispatch( clearPostIdeas() );
  dispatch( clearUI() );
}

async function clearChatAndMakeNewBusiness( dispatch, navigateWithSearchParams, removeSearchParamByKey )
{
  dispatch( setApplicationLoadingState( true ) );

  try
  {
    stopInFlightBrowserRequests();
    await businessServices.createNewBusiness();
    await authenticationServices.refreshUser();
    navigateWithSearchParams( ROUTES.CHAT, {}, [ROUTE_PARAM.CHANGE_BUSINESSES] );
  }
  catch (e)
  {
    dispatch( setAlertMessage( errorAlert( "Error creating new business" ) ) );
    removeSearchParamByKey( ROUTE_PARAM.CHANGE_BUSINESSES );
  }
  finally
  {
    dispatch( setApplicationLoadingState( false ) );
  }
}

async function switchBusinesses( slug: string, dispatch )
{
  try
  {
    await businessServices.switchBusiness( slug );
    await userHelper.clearChatAndSwitchBusiness( dispatch );
  }
  catch
  {
    dispatch( setAlertMessage( errorAlert( "Error switching business", "top" ) ) );
  }
}
