import React, { useEffect, useState } from 'react';
import './styles/App.scss';
import { Helmet } from "react-helmet";
import { APP_TAB_BAR_HEIGHT, FEATURE_FLAGS, ROUTE_SEO, ROUTES, SEARCH_PARAM_KEY_BUSINESS_SLUG } from "./features/constants";
import { setFeatureFlagBoolean } from "./features/featureFlags/featureFlagsSlice";
import { each, isNull } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./app/store";
import { hasMultipleBusinesses, hasRemainingFreePosts, isUserLoggedIn, wasSubscribedUser } from "./features/user/userSlice";
import { FullPageMobileFooter } from "./features/layout/fullPageMobileFooter";
import { BottomNavigation, BottomNavigationAction, Link, Paper } from "@mui/material";
import { CustomTabPanel } from "./features/editing/customTabPanel";
import { FullPageMobileContent } from "./features/layout/fullPageMobileContent";
import SmsIcon from '@mui/icons-material/Sms';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { AppContainer } from "./features/layout/appContainer";
import { Outlet, useBeforeUnload, useLocation, useSearchParams } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { eventTracker } from "./helpers/eventTracker";
import { chatServices } from "./services/chat.services";
import { ApplicationLevelLoader } from "./features/ui/applicationLevelLoader";
import { RemainingPostsBanner } from "./features/layout/remainingPostsBanner";
import { FullScreenUpsellDialog } from "./features/upsell/upsell";
import {
  getPaymentDialogOpen,
  getShowAddMultipleBusinessesUpsell,
  getUpsellShown,
  setPaymentDialogOpen,
  setShowAddMultipleBusinessesUpsell,
  setUpsellShown
} from "./features/ui/uiSlice";
import { FullScreenPaymentDialog } from "./features/payment/paymentDialog";
import useNavigateWithSearchParams from "./features/hooks/useNavigateWithSearchParams";
import { EventTracking } from "./features/tracking/eventTracking";
import { authenticationServices, UserApiData } from "./services/authentication.services";
import { InAppNoticeBanners } from "./features/inAppNotices/InAppNoticeBanners";
import { setChannelTrackingParameters } from "./features/tracking/trackingSlice";
import { ManageSubscriptionBanner } from "./features/layout/manageSubscriptionBanner";
import FilterIcon from "@mui/icons-material/Filter";
import { getAbortController } from "./services/requestManager";
import { AfterAppInitializationSetup } from "./features/afterAppInitializationSetup/afterAppInitializationSetup";
import { setApplicationLoadingState } from "./features/loadingIndicator/loadingSlice";
import { StreakDialogFullScreen } from "./features/planner/streakDialog";
import { businessServices, CurrentStreakAPI } from "./services/business.services";
import { BusinessSwitcher } from "./features/businessSwitcher/businessSwitcher";
import { AddMultipleBusinessesUpsellDialog } from "./features/upsell/addMultipleBusinessesUpsell";
import useRemoveSearchParamByKey from "./features/hooks/useRemoveSearchParamByKey";
import { getBusinessSlug } from "./features/business/businessSlice";
import { errorAlert, setAlertMessage, successAlert } from "./features/alert/alertSlice";
import { userHelper } from "./features/user/userHelper";
import { ENABLE_AMPLITUDE_FEATURE_FLAGS } from "./constants";
import { amplitudeFeatureFlagsWrapper } from "./features/amplitude/amplitudeFeatureFlagsWrapper";
import { amplitudeFeatureFlagsVariables } from "./features/amplitude/amplitudeFeatureFlagsVariables";
import GlobalErrorDialog from "./features/error/globalErrorDialog";
import { UnsupportedBrowserBanner } from "./features/layout/unsupportedBrowserBanner";
import { browserUtils } from "./features/utils/browserUtils";
import { IOSInterfaceInitializationSetup } from "./features/iOSInterfaceInitializationSetup/IOSInterfaceInitializationSetup";
import { PreloadedAssets } from "./features/ui/preloadedAssets";

function App()
{
  const APP_TAB_OUTLET_INDEX = 0;

  const location = useLocation();
  const navigateWithSearchParams = useNavigateWithSearchParams();

  const dispatch = useDispatch();
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );
  const userHasMultipleBusinesses = useSelector( ( state: RootState ) => hasMultipleBusinesses( state ) );
  const shouldRenderBusinessSwitcher = userIsLoggedIn && userHasMultipleBusinesses;
  const showManageSubscriptionBanner = useSelector( ( state: RootState ) => wasSubscribedUser( state ) );
  const showRemainingFreePosts = useSelector( ( state: RootState ) => hasRemainingFreePosts( state ) ) && !showManageSubscriptionBanner;
  const showUpsell = useSelector( ( state: RootState ) => getUpsellShown( state ) );
  const shouldShowAddMultipleBusinessesUpsell = useSelector( ( state: RootState ) => getShowAddMultipleBusinessesUpsell( state ) );
  const showPaymentDialog = useSelector( ( state: RootState ) => getPaymentDialogOpen( state ) );
  const [streak, setStreak] = React.useState<CurrentStreakAPI | undefined>( undefined );
  const [showStreakDialog, setStreakDialogShown] = React.useState( false );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const currentBusinessSlug = useSelector( ( state: RootState ) => getBusinessSlug( state ) );
  const removeSearchParamByKey = useRemoveSearchParamByKey();
  const [isProcessingBusiness, setIsProcessingBusiness] = useState<boolean>( userIsLoggedIn || false );
  const shouldShowUnsupportedBrowserBanner = browserUtils.isUnsupportedBrowser();

  useBeforeUnload(
    React.useCallback( () =>
    {
      eventTracker.logAlkaiAppHidden();
      eventTracker.logAlkaiAppUnloaded();
    }, [] )
  );

  useEffect( () =>
  {
    each( FEATURE_FLAGS, ( flag ) =>
    {
      const flagValue = searchParams.get( flag );
      if ( searchParams.has( flag ) )
      {
        dispatch( setFeatureFlagBoolean( { [`${flag}`]: flagValue === "true" } ) );
      }
    } );

    const queryParamsAsObject = Object.fromEntries(
      searchParams
    );

    eventTracker.initializeAmplitude( queryParamsAsObject.amplitude_device_id );
    if ( ENABLE_AMPLITUDE_FEATURE_FLAGS )
    {
      amplitudeFeatureFlagsWrapper.setup();
    }
    updateChannelTrackingParameters( queryParamsAsObject )
    eventTracker.initializeGoogleAnalytics();
    eventTracker.logAlkaiAppLoaded( window.location.pathname, queryParamsAsObject )
    eventTracker.initializeFacebookPixel();
    dispatch( setApplicationLoadingState( false ) );
    getAbortController();
  }, [] );

  useEffect( () =>
  {
    if ( userIsLoggedIn )
    {
      onMountWhenUserLoggedIn();
    }
  }, [userIsLoggedIn] );

  async function onMountWhenUserLoggedIn()
  {
    try
    {
      await refreshUserIfLoggedIn();
      const businessSlugParameter = searchParams.get( SEARCH_PARAM_KEY_BUSINESS_SLUG );
      removeSearchParamByKey( SEARCH_PARAM_KEY_BUSINESS_SLUG );

      if ( !!businessSlugParameter )
      {
        setIsProcessingBusiness( true );

        if ( currentBusinessSlug !== businessSlugParameter )
        {
          try
          {
            const response = await businessServices.switchBusiness( businessSlugParameter );
            await userHelper.clearChatAndSwitchBusiness( dispatch );
            dispatch( setAlertMessage( successAlert( `Business changed to ${response.business.name || "New Business"}`, "top" ) ) );
          }
          catch
          {
            dispatch( setAlertMessage( errorAlert( "Error switching business", "top" ) ) );
          }
        }
      }
      setIsProcessingBusiness( false );
    }
    catch (error)
    {
      setIsProcessingBusiness( false );
    }
  }

  async function refreshUserIfLoggedIn()
  {
    const data = await authenticationServices.refreshUserIfLoggedIn()
    if ( !!data?.current_business.pending_streak )
    {
      setStreak( data.current_business.pending_streak );
      setStreakDialogShown( true );
    }
    if ( !!data )
    {
      handleUpdateFeatureFlagsForLoggedInUser( data );
    }
  }

  function handleUpdateFeatureFlagsForLoggedInUser( data: UserApiData )
  {
    // The response from updating these flags on the server is ignored by the client
    if ( data.is_subscribed )
    {
      amplitudeFeatureFlagsVariables.shouldAlwaysSendPostIdeaNotifications().then( ( alwaysSendPostIdeaNotifications ) =>
      {
        if ( data.current_business.always_send_post_idea_notifications_feature_flag !== alwaysSendPostIdeaNotifications )
        {
          businessServices.updateAlwaysSendPostIdeaNotificationsFeatureFlag( alwaysSendPostIdeaNotifications );
        }
      } );
    }

    if ( isNull( data.current_business.should_rescrape_website_on_extend_plan_feature_flag ) )
    {
      amplitudeFeatureFlagsVariables.shouldRescrapeWebsiteOnExtendPlan().then( ( shouldRescrapeWebsiteOnExtendPlan ) =>
      {
        businessServices.updateShouldRescrapeWebsiteOnExtendPlanFeatureFlag( shouldRescrapeWebsiteOnExtendPlan );
      } );
    }
  }

  function updateChannelTrackingParameters( queryParams )
  {
    const { s, fbclid } = queryParams
    const channelTrackingParams = {
      alkai_source: s,
      fbclid,
    }

    dispatch( setChannelTrackingParameters( channelTrackingParams ) );
  }

  function handleNavigateToChat()
  {
    if ( !(location.pathname === ROUTES.CHAT) )
    {
      eventTracker.logChatMenuClicked();
    }
    navigateWithSearchParams( ROUTES.CHAT );
  }

  function handleNavigateToMediaLibrary()
  {
    if ( !(location.pathname === ROUTES.MEDIA_LIBRARY) )
    {
      chatServices.clearCheckMessagesTimeout();
      eventTracker.logMediaLibraryMenuClicked();
    }
    navigateWithSearchParams( ROUTES.MEDIA_LIBRARY );
  }

  function handleNavigateToPlan()
  {
    if ( !(location.pathname === ROUTES.PLAN) )
    {
      chatServices.clearCheckMessagesTimeout();
      eventTracker.logPlanMenuClicked();
    }
    navigateWithSearchParams( ROUTES.PLAN );
  }

  function handleNavigateToSettings()
  {
    if ( !(location.pathname === ROUTES.USER_SETTINGS) )
    {
      chatServices.clearCheckMessagesTimeout();
      eventTracker.logSettingsMenuClicked()
    }
    navigateWithSearchParams( ROUTES.USER_SETTINGS );
  }

  function handleUpsellClosed()
  {
    dispatch( setUpsellShown( false ) );
  }

  function handlePaymentDialogClosed()
  {
    dispatch( setPaymentDialogOpen( false ) );
  }

  function handleAddMultipleBusinessesDialogClosed()
  {
    dispatch( setShowAddMultipleBusinessesUpsell( false ) );
  }

  function handleStreakDialogClose()
  {
    setStreakDialogShown( false );
  }

  return (
    <div className="alki-app">
      <ErrorBoundary>
        <EventTracking/>
        <AfterAppInitializationSetup/>
        <IOSInterfaceInitializationSetup/>
        <AppContainer>
          <Helmet>
            <title>{ROUTE_SEO.ROOT.title}</title>
            <meta name="description" content={ROUTE_SEO.ROOT.description}/>
          </Helmet>

          {showRemainingFreePosts && <RemainingPostsBanner/>}
          {showManageSubscriptionBanner && <ManageSubscriptionBanner/>}
          <InAppNoticeBanners/>
          {shouldShowUnsupportedBrowserBanner && <UnsupportedBrowserBanner/>}
          {shouldRenderBusinessSwitcher && <BusinessSwitcher/>}
          <FullScreenUpsellDialog externalStateOpen={showUpsell}
                                  handleClose={handleUpsellClosed}/>
          <FullScreenPaymentDialog externalStateOpen={showPaymentDialog}
                                   handleClose={handlePaymentDialogClosed}/>
          <AddMultipleBusinessesUpsellDialog externalStateOpen={shouldShowAddMultipleBusinessesUpsell}
                                             handleClose={handleAddMultipleBusinessesDialogClosed}/>
          {showStreakDialog && streak && <StreakDialogFullScreen streak={streak}
                                                                 externalStateOpen={showStreakDialog}
                                                                 workedAhead={true}
                                                                 handleClose={handleStreakDialogClose}/>}
          <GlobalErrorDialog/>
          <FullPageMobileContent>
            <CustomTabPanel value={APP_TAB_OUTLET_INDEX} index={APP_TAB_OUTLET_INDEX} sx={{ px: 0, pt: 0 }}>
              {!isProcessingBusiness && <Outlet/>}
            </CustomTabPanel>
          </FullPageMobileContent>
          {userIsLoggedIn && <FullPageMobileFooter>
            <Paper elevation={3}>
              <BottomNavigation
                sx={{ height: `${APP_TAB_BAR_HEIGHT}px` }}
                showLabels
                value={location.pathname}>
                <BottomNavigationAction
                  label="Chat"
                  icon={<SmsIcon/>}
                  LinkComponent={Link}
                  onClick={handleNavigateToChat}
                  value={ROUTES.CHAT}
                />
                <BottomNavigationAction
                  label="Media"
                  icon={<FilterIcon/>}
                  LinkComponent={Link}
                  onClick={handleNavigateToMediaLibrary}
                  value={ROUTES.MEDIA_LIBRARY}
                />
                <BottomNavigationAction
                  label="Plan"
                  icon={<CalendarMonthIcon/>}
                  LinkComponent={Link}
                  onClick={handleNavigateToPlan}
                  value={ROUTES.PLAN}
                />
                <BottomNavigationAction
                  label="Settings"
                  icon={<ManageAccountsIcon/>}
                  onClick={handleNavigateToSettings}
                  value={ROUTES.USER_SETTINGS}
                />
              </BottomNavigation>
            </Paper>
          </FullPageMobileFooter>}
          <PreloadedAssets/>
        </AppContainer>
        <ApplicationLevelLoader/>
      </ErrorBoundary>
    </div>
  );
}

export default App;
