import React, { useEffect } from 'react'
import {
  businessServices,
  FACEBOOK_INSTAGRAM,
  FACEBOOK_PAGE,
  SocialNetworkAccountAPI,
  SocialNetworkAccountType,
  TIKTOK,
  YOUTUBE
} from "../../services/business.services";
import { SocialMediaAccountRow } from "./SocialMediaAccountRow";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ConnectFacebookPagesButton } from "../buttons/connectFacebookPagesButton";
import { ConnectFacebookInstagramButton } from "../buttons/connectFacebookInstagramButton";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { CONNECTS_WITH_RIPL_TEXT } from "../constants";
import { SocialNetworkAccountsBadBrowserWall } from "../editing/socialNetworkAccountsBadBrowserWall";
import { currentUserBusinessId } from "../business/businessSlice";
import { RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { ExternalConnectionInfoSection } from "../socialMediaAccounts/externalConnectionInfoSection";
import { ConnectOAuthSocialNetworkButton } from "../buttons/connectOAuthSocialNetworkButton";
import { SETTINGS_PAGE_SOURCE } from "../../helpers/trackingConstants";
import { YouTube } from "@mui/icons-material";
import TiktokIcon from "../../assets/TiktokIcon";
import { eventTracker } from "../../helpers/eventTracker";
import CircularProgress from "@mui/material/CircularProgress";
import { setAlertMessage, warningAlert } from "../alert/alertSlice";

export function SocialNetworkAccountsSection()
{
  const [socialNetworkAccounts, setSocialNetworkAccounts] = React.useState<SocialNetworkAccountAPI[]>( [] );
  const isShowingConnectButton = socialNetworkAccountHelper.hasYetToConnectBothSocialNetworks( socialNetworkAccounts );

  const isCurrentBusinessSet = useSelector( ( state: RootState ) => !!currentUserBusinessId( state ) );
  const [connectingSocialNetworkAccountType, setConnectingSocialNetworkAccountType] = React.useState<SocialNetworkAccountType | undefined>(
    undefined );
  const facebookInstagramConnectionInProgress = connectingSocialNetworkAccountType === FACEBOOK_INSTAGRAM || connectingSocialNetworkAccountType
                                                === FACEBOOK_PAGE;
  const youtubeConnectionInProgress = connectingSocialNetworkAccountType === YOUTUBE;
  const tiktokConnectionInProgress = connectingSocialNetworkAccountType === TIKTOK;
  const oauthConnectionInProgress = tiktokConnectionInProgress || youtubeConnectionInProgress;
  const connecting = facebookInstagramConnectionInProgress || tiktokConnectionInProgress || youtubeConnectionInProgress;
  const [loadingMessage, setLoadingMessage] = React.useState( "" );
  const [isLoadingData, setIsLoadingData] = React.useState( true );
  const [reloadSocialNetworkAccountSucceeded, setReloadSocialNetworkAccountSucceeded] = React.useState( false );
  const dispatch = useDispatch();

  useEffect( () =>
  {
    if ( isCurrentBusinessSet )
    {
      reloadSocialNetworkAccounts();
    }
  }, [isCurrentBusinessSet] );

  function reloadSocialNetworkAccounts()
  {
    setIsLoadingData( true );
    businessServices.listSocialNetworkAccounts().then( ( response ) =>
    {
      setSocialNetworkAccounts( response.social_network_accounts );
      setReloadSocialNetworkAccountSucceeded( true );
    } ).catch( () =>
    {
      setReloadSocialNetworkAccountSucceeded( false );
    } ).finally( () =>
    {
      setIsLoadingData( false );
    } )

  }

  function getFacebookRow()
  {
    const accountType = FACEBOOK_PAGE;
    const connectedFacebookAccount = socialNetworkAccountHelper.getConnectedFacebookPage( socialNetworkAccounts );
    if ( connectedFacebookAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedFacebookAccount}
                                    otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}
                                    source={SETTINGS_PAGE_SOURCE}
      />
    }
    else
    {
      return <SocialNetworkAccountsBadBrowserWall>
        <ConnectFacebookPagesButton source={SETTINGS_PAGE_SOURCE}
                                    handleConnectionStarted={handleFacebookPageConnectionStarted}
                                    handleConnectionSucceeded={handleAccountConnectionFinished}
                                    handleConnectionFailed={handleAccountConnectionFinished}
        />
      </SocialNetworkAccountsBadBrowserWall>
    }
  }

  function getInstagramRow()
  {
    const accountType = FACEBOOK_INSTAGRAM;
    const connectedInstagramAccount = socialNetworkAccountHelper.getConnectedInstagram( socialNetworkAccounts );
    if ( connectedInstagramAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedInstagramAccount}
                                    otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}
                                    source={SETTINGS_PAGE_SOURCE}/>
    }
    else
    {
      return <SocialNetworkAccountsBadBrowserWall>
        <ConnectFacebookInstagramButton source={SETTINGS_PAGE_SOURCE}
                                        handleConnectionStarted={handleFacebookInstagramConnectionStarted}
                                        handleConnectionSucceeded={handleAccountConnectionFinished}
                                        handleConnectionFailed={handleAccountConnectionFinished}/>
      </SocialNetworkAccountsBadBrowserWall>
    }
  }

  function handleRefreshAccountComplete( accountType: SocialNetworkAccountType, succeeded: boolean, switchedAccount?: boolean )
  {
    if ( succeeded && switchedAccount )
    {
      dispatch( setAlertMessage( warningAlert( `You are now connected to a new ${accountType} account` ) ) );
    }
  }

  function getTiktokRow()
  {
    const accountType = TIKTOK;
    const connectedTiktokAccount = socialNetworkAccountHelper.getConnectedTiktok( socialNetworkAccounts );
    if ( connectedTiktokAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedTiktokAccount}
                                    otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}
                                    onRefreshAccountComplete={handleRefreshAccountComplete}
                                    source={SETTINGS_PAGE_SOURCE}
      />
    }
    else
    {
      return <SocialNetworkAccountsBadBrowserWall>
        <ConnectOAuthSocialNetworkButton accountType={TIKTOK}
                                         source={SETTINGS_PAGE_SOURCE}
                                         iconType={TiktokIcon}
                                         handleConnectionStarted={handleTikTokAccountConnectionStarted}
                                         handleConnectionSucceeded={handleAccountConnectionFinished}
                                         handleConnectionFailed={handleAccountConnectionFailed}/>
      </SocialNetworkAccountsBadBrowserWall>
    }
  }

  function getYoutubeRow()
  {
    const accountType = YOUTUBE;
    const connectedYoutubeAccount = socialNetworkAccountHelper.getConnectedYoutube( socialNetworkAccounts );
    if ( connectedYoutubeAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedYoutubeAccount}
                                    otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}
                                    onRefreshAccountComplete={handleRefreshAccountComplete}
                                    source={SETTINGS_PAGE_SOURCE}/>
    }
    else
    {
      return <SocialNetworkAccountsBadBrowserWall>
        <ConnectOAuthSocialNetworkButton accountType={YOUTUBE}
                                         source={SETTINGS_PAGE_SOURCE}
                                         iconType={YouTube}
                                         handleConnectionStarted={handleYoutubeAccountConnectionStarted}
                                         handleConnectionSucceeded={handleAccountConnectionFinished}
                                         handleConnectionFailed={handleAccountConnectionFailed}/>
      </SocialNetworkAccountsBadBrowserWall>
    }
  }

  function handleTikTokAccountConnectionStarted()
  {
    setConnectingSocialNetworkAccountType( TIKTOK );
    setLoadingMessage( "Connecting TikTok..." );
  }

  function handleFacebookInstagramConnectionStarted()
  {
    setConnectingSocialNetworkAccountType( FACEBOOK_INSTAGRAM );
    setLoadingMessage( "Connecting Instagram..." );
  }

  function handleFacebookPageConnectionStarted()
  {
    setConnectingSocialNetworkAccountType( FACEBOOK_PAGE );
    setLoadingMessage( "Connecting Facebook..." );
  }

  function handleYoutubeAccountConnectionStarted()
  {
    setConnectingSocialNetworkAccountType( YOUTUBE );
    setLoadingMessage( "Connecting YouTube..." );
  }

  function handleUserCancelConnectFromInfoSection()
  {
    const errorText = 'User cancel from progress overlay'
    if ( connectingSocialNetworkAccountType === FACEBOOK_PAGE )
    {
      eventTracker.logFacebookPageConnectFailed( SETTINGS_PAGE_SOURCE, errorText )
    }
    else if ( connectingSocialNetworkAccountType === FACEBOOK_INSTAGRAM )
    {
      eventTracker.logInstagramConnectFailed( SETTINGS_PAGE_SOURCE, errorText )
    }
    else if ( !!connectingSocialNetworkAccountType )
    {
      eventTracker.logSocialNetworkConnectFailed( connectingSocialNetworkAccountType, SETTINGS_PAGE_SOURCE, errorText )
    }
    handleAccountConnectionFinished( connectingSocialNetworkAccountType );
  }

  function handleAccountConnectionFinished( accountType?: SocialNetworkAccountType, switchedAccount?: boolean )
  {
    setConnectingSocialNetworkAccountType( undefined );
    setLoadingMessage( "" );
    if ( switchedAccount && !!accountType )
    {
      dispatch( setAlertMessage( warningAlert( `You are now connected to a new ${accountType} account` ) ) );
    }
  }

  function handleAccountConnectionFailed()
  {
    setConnectingSocialNetworkAccountType( undefined );
    setLoadingMessage( "" );
  }

  function updateSocialNetworkAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[] )
  {
    setSocialNetworkAccounts( socialNetworkAccounts );
  }

  return (
    <>
      <SocialNetworkAccountsContext.Provider value={{ socialNetworkAccounts, updateSocialNetworkAccounts }}>
        {<Stack sx={{ width: "100%", px: 5, py: 5 }}>

          {isLoadingData && <Stack justifyContent="center" alignItems="center" width="100%">
            <CircularProgress/>
          </Stack>}
          {!reloadSocialNetworkAccountSucceeded && !isLoadingData && <Stack sx={{ position: 'relative' }}>
            <Typography variant={"caption"} sx={{ color: "black" }}>
              Sorry, there was a problem loading your social media accounts. Please try again.
            </Typography>
            <Button onClick={reloadSocialNetworkAccounts}>Retry</Button>
          </Stack>}
          {reloadSocialNetworkAccountSucceeded && !isLoadingData &&
           <Stack sx={{ position: "relative", width: "100%" }} spacing={5}>
             {getFacebookRow()}
             {getInstagramRow()}
             {getTiktokRow()}
             {getYoutubeRow()}
           </Stack>}

          {isShowingConnectButton && <SocialNetworkAccountsBadBrowserWall>
            <Box textAlign="left" sx={{ pl: 5 }}>
              <Typography variant={"caption"}
                          sx={{ color: "black", }}>{CONNECTS_WITH_RIPL_TEXT}</Typography>
            </Box>
          </SocialNetworkAccountsBadBrowserWall>}
        </Stack>}
        {connecting && <ExternalConnectionInfoSection loadingMessage={loadingMessage}
                                                      isConnectingFacebookOrInstagram={facebookInstagramConnectionInProgress}
                                                      isConnectingOAuthAccount={oauthConnectionInProgress}
                                                      handleCancelOAuthConnect={handleUserCancelConnectFromInfoSection}/>}
      </SocialNetworkAccountsContext.Provider>
    </>
  )
}

