import { SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import { merge } from "lodash";

interface PaywallBenefitTextProps
{
  children: ReactNode;
  sx?: SxProps;
}

export function PaywallBenefitText( props: PaywallBenefitTextProps )
{
  return (
    <Typography variant={"body1"}
                sx={merge( {
                  color: "primary.contrastText",
                  wordWrap: "break-word",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "16px"
                }, props.sx )}>{props.children}</Typography>
  )
}