import { Box, Typography } from "@mui/material";
import React from "react";

export function UnsupportedBrowserBanner()
{
  return (
    <Box sx={{
      color: 'primary.contrastText', m: 1, borderRadius: "5px",
      backgroundColor: "#3f51b5",
    }}>
      <Typography variant={"body2"} sx={{ my: 4, mx: 2, pb: "1px"}}>
        ⚠️ Unsupported browser - your experience may be degraded
      </Typography>
    </Box>
  );
}