import { Accordion, AccordionDetails, Box, Button, Stack } from "@mui/material";
import { format } from "date-fns";
import React, { useContext } from "react";
import { PlanItemIconLabel } from "./PlanItemIconLabel";
import { PlanAccordionSummary } from "./planAccordionSummary";
import { useDispatch, useSelector } from "react-redux";
import { PlanItemCtaFooterContainer } from "./planItemCTAFooterContainer";
import { getPlanLastPostIdeaEdited } from "../ui/uiSlice";
import { postIdeaServices, ShareBatchAPI } from "../../services/postIdeaServices";
import {
  ERROR_MESSAGE_REFRESH_AND_TRY_AGAIN,
  POST_VIEW_DATE_FORMAT_DAY_DIFFERENT_THAN_PLAN,
  POST_VIEW_DATE_FORMAT_DAY_SAME_AS_PLAN
} from "../constants";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { SharedVideoAndCaption } from "./sharedVideoAndCaption";
import { ShareBatchAccordionHeader } from "./shareBatchAccordionHeader";
import { PlanDateContext } from "../context/planDateContext";
import { DateUtils } from "../utils/dateUtils";
import { CalendarIcon } from "@mui/x-date-pickers";
import { ShareDrawer } from "./shareDrawer";
import { ShareNowTabContentSources } from "../editing/directShare";
import { getRescheduleDate, getScheduleForDate, getValidScheduledDate, isShareBatchCanceled } from "../postIdea/shareBatchHelper";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmDialog } from "../ui/confirmDialog";
import { PlanIdeaPreview } from "./planIdeaPreview";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { PlanIdeaTitleTypography } from "./planIdeaTitleTypography";
import { OutputFormatIconAndLabel } from "./outputFormatIconAndLabelSmall";
import { ContentGoalIconAndLabel } from "./contentGoalIconAndLabelSmall";
import { eventTracker } from "../../helpers/eventTracker";
import { UNAUTHORIZED_ERROR } from "../../services/requestManager";

interface ScheduledPostViewProps
{
  shareBatch: ShareBatchAPI;
  handleUpdatePlannerData: () => void;
}

export function ScheduledPostView( props: ScheduledPostViewProps )
{
  const dispatch = useDispatch();

  const lastPostIdeaEdited = useSelector( ( state ) => getPlanLastPostIdeaEdited( state ) );
  const [expanded, setExpanded] = React.useState<boolean>( lastPostIdeaEdited === props.shareBatch.post_idea.id );

  const planDateContext = useContext( PlanDateContext );
  const planDate = planDateContext.planDate;

  const [showShareDrawer, setShowShareDrawer] = React.useState( false );
  const [rescheduleButtonEnabled, setRescheduleButtonEnabled] = React.useState( true );

  const [unscheduleButtonEnabled, setUnscheduleButtonEnabled] = React.useState( true );
  const [showConfirmUnscheduleDialog, setShowConfirmUnscheduleDialog] = React.useState( false );

  const handleAccordionClicked = () =>
  {
    if ( !expanded )
    {
      setExpanded( true );
    }
    else
    {
      setExpanded( false );
    }
  }

  function handleRescheduleClicked()
  {
    eventTracker.logPlanPostShareClicked( props.shareBatch.post_idea );
    setShowShareDrawer( true );
    setUnscheduleButtonEnabled( false );
    setRescheduleButtonEnabled( false );
  }

  function handleUnscheduleClicked( event: React.MouseEvent )
  {
    event.stopPropagation();
    setUnscheduleButtonEnabled( false );
    setRescheduleButtonEnabled( false );
    setShowConfirmUnscheduleDialog( true );
  }

  function onDrawerClosed()
  {
    setShowShareDrawer( false );
    setRescheduleButtonEnabled( true );
    setUnscheduleButtonEnabled( true );
  }

  function getIcon()
  {
    return <PlanItemIconLabel label="Scheduled" color={getAccentColor()}>
      <AccessTimeIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
    </PlanItemIconLabel>;
  }

  function getAccentColor()
  {
    return "planScheduled.main";
  }

  function getHeaderText()
  {
    const dateToUse = props.shareBatch.scheduled_for;

    if ( !!dateToUse )
    {
      let dateFormat = POST_VIEW_DATE_FORMAT_DAY_SAME_AS_PLAN;
      if ( !DateUtils.isSameCalendarDay( planDate, new Date( dateToUse ) ) )
      {
        dateFormat = POST_VIEW_DATE_FORMAT_DAY_DIFFERENT_THAN_PLAN;
      }

      if ( props.shareBatch.download_only )
      {
        return "Reminder notification scheduled for " + format( dateToUse, dateFormat );
      }
      return "Auto-post scheduled for  " + format( dateToUse, dateFormat );
    }
    return "";
  }

  function planCTAFooter()
  {
    return <PlanItemCtaFooterContainer>
      <Button variant="outlined"
              color="negative"
              startIcon={<CloseIcon/>}
              onClick={handleUnscheduleClicked}
              disabled={!unscheduleButtonEnabled}>Unschedule</Button>
      <Button variant="contained"
              startIcon={<CalendarIcon/>}
              onClick={handleRescheduleClicked}
              disabled={!rescheduleButtonEnabled}>Reschedule</Button>
    </PlanItemCtaFooterContainer>;
  }

  function getAdditionalHeaderContent()
  {
    if ( !!props.shareBatch )
    {
      return (<ShareBatchAccordionHeader shareBatch={props.shareBatch}
                                         backgroundColor={getAccentColor()}/>);
    }
    return null;
  }

  function renableButtonsAndCloseConfirmUnscheduleDialog()
  {
    setUnscheduleButtonEnabled( true );
    setRescheduleButtonEnabled( true );
    setShowConfirmUnscheduleDialog( false );
  }

  async function handleUnscheduleConfirmationDialogConfirm()
  {
    try
    {
      await postIdeaServices.cancelShareBatch( props.shareBatch );
      refreshPlanData();
    }
    catch (error)
    {
      if ( error === UNAUTHORIZED_ERROR )
      {
        dispatch( setAlertMessage( errorAlert( "Unable to cancel share.  Please login and try again.", "top" ) ) );
      }
      else
      {
        dispatch( setAlertMessage( errorAlert( ERROR_MESSAGE_REFRESH_AND_TRY_AGAIN ) ) );
        refreshPlanData();
      }
    }
  }

  function refreshPlanData()
  {
    props.handleUpdatePlannerData();
    renableButtonsAndCloseConfirmUnscheduleDialog();
  }

  function handleUnscheduleConfirmationDialogCancel()
  {
    renableButtonsAndCloseConfirmUnscheduleDialog()
  }

  const postIdeaTitle = props.shareBatch.post_idea?.topic_name;
  if ( isShareBatchCanceled( props.shareBatch ) )
  {
    const scheduledForDate = getScheduleForDate( props.shareBatch ) || new Date();
    return <PlanIdeaPreview expanded={false}
                            initialScheduleTimeForDate={getValidScheduledDate( scheduledForDate )}
                            postIdeaId={props.shareBatch.post_idea.id}
                            postIdeaTitle={postIdeaTitle}
                            outputFormatSlug={props.shareBatch.post_idea.output_format_slug}
                            shareBatch={props.shareBatch}
    />
  }

  return <Box sx={{ mb: 5 }}> <Accordion
    expanded={expanded}
    sx={{
      backgroundColor: "planScheduled.light",
      borderRadius: "5px",
      p: 0,
    }}>
    <PlanAccordionSummary
      onClick={handleAccordionClicked}
      icon={getIcon()}
      savedPostIdeaTitle={<Stack>
        <PlanIdeaTitleTypography>{postIdeaTitle}</PlanIdeaTitleTypography>
        <OutputFormatIconAndLabel outputFormatSlug={props.shareBatch?.output_format_slug}/>
        <ContentGoalIconAndLabel contentGoal={props.shareBatch?.content_goal}/>
      </Stack>}
      accentColor={getAccentColor()}
      helperText={getHeaderText()}
      expanded={expanded}
      allowedToCollapse={true}
      planCTAFooter={planCTAFooter()}
      additionalHeaderContent={getAdditionalHeaderContent()}
    />
    <AccordionDetails sx={{ p: 0 }}>
      {expanded && <SharedVideoAndCaption videoUrl={props.shareBatch.video_url} caption={props.shareBatch.caption}
                                          imageUrl={props.shareBatch.image_url}/>}
      {planCTAFooter()}
    </AccordionDetails>
  </Accordion>
    {showShareDrawer && <ShareDrawer sharedFrom={ShareNowTabContentSources.PLAN}
                                     postIdea={props.shareBatch.post_idea}
                                     previousShareBatch={props.shareBatch}
                                     showShareDrawer={showShareDrawer}
                                     closeDrawer={onDrawerClosed}
                                     onCloseFromBackdropClick={onDrawerClosed}
                                     initialScheduleForDate={getRescheduleDate( props.shareBatch )}/>}
    {showConfirmUnscheduleDialog && <ConfirmDialog title={"Are you sure you want to unschedule this post?"}
                                                   open={showConfirmUnscheduleDialog}
                                                   onConfirm={handleUnscheduleConfirmationDialogConfirm}
                                                   onCancel={handleUnscheduleConfirmationDialogCancel}/>}
  </Box>
}
