import { ENABLE_AMPLITUDE_FEATURE_FLAGS, ENV_CONFIG } from "../../constants";
import { logToConsoleError } from "../utils/devLoggingHelper";
import { Experiment } from "@amplitude/experiment-js-client";
import { setAmplitudeFeatureFlagsInitialized } from "./amplitudeFeatureFlagsSlice";
import { store } from "../../app/store";
import { getUserSlug } from "../user/userSlice";

export const amplitudeFeatureFlagsWrapper = {
  setup,
  isFeatureFlagEnabled,
  takeOnline,
  takeOffline,
};

let onlineMode = true;
const DEFAULT_VARIANT_VALUE = 'on';

function takeOnline()
{
  onlineMode = true;
}

function takeOffline()
{
  onlineMode = false;
}

function amplitudeDeploymentKey()
{
  return ENV_CONFIG.amplitudeDeploymentKey || '';
}

function setup()
{
  if ( isEnabled() )
  {
    try
    {
      Experiment.initializeWithAmplitudeAnalytics( amplitudeDeploymentKey() );
      store.dispatch( setAmplitudeFeatureFlagsInitialized( true ) );
    }
    catch (e)
    {
      logToConsoleError( "amplitudeFeatureFlagsWrapper Error calling initializeWithAmplitudeAnalytics: ", e );
      store.dispatch( setAmplitudeFeatureFlagsInitialized( false ) );
    }
  }
  else
  {
    store.dispatch( setAmplitudeFeatureFlagsInitialized( false ) );
  }
}

function isFeatureFlagEnabled( name: string ): Promise<boolean>
{
  if ( isOnline() )
  {
    try
    {
      const experiment = Experiment.initializeWithAmplitudeAnalytics( amplitudeDeploymentKey() );
      const userSlug = getUserSlug( store.getState() );
      return experiment.fetch(
        {
          user_id: userSlug
        }
      ).then( () =>
      {
        const variant = experiment.variant( name );
        if ( variant.value === DEFAULT_VARIANT_VALUE )
        {
          return Promise.resolve( true );
        }
        else
        {
          return Promise.resolve( false );
        }
      } );
    }
    catch (e)
    {
      logToConsoleError( `amplitudeFeatureFlagsWrapper Error calling isFeatureFlagEnabled with name = ${name}: `, e );
      return Promise.resolve( false );
    }
  }

  return Promise.resolve( false );
}

function isOnline()
{
  return isEnabled() && onlineMode;
}

function isEnabled()
{
  return ENABLE_AMPLITUDE_FEATURE_FLAGS && Experiment && ENV_CONFIG.amplitudeDeploymentKey;
}
