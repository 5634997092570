import * as amplitude from '@amplitude/analytics-browser';
import {
  ALKAI_VERSION,
  ENABLE_ERROR_REPORTING,
  ENABLE_EVENT_CONSOLE_LOGGING,
  ENABLE_USAGE_TRACKING,
  ENV_CONFIG,
  getFacebookPixelId,
} from "../constants";
import {
  ACCEPT_CONNECT_FACEBOOK_PAGE_CLICKED_EVENT,
  ACCEPT_CONNECT_INSTAGRAM_CLICKED_EVENT,
  ADD_BUSINESS_UPSELL_SHOWN,
  ADD_COLOR_PALETTE_CLICKED,
  ADD_FONT_SET_CLICKED,
  ADD_NEW_UPLOADED_MUSIC_CLICKED_EVENT,
  ALKAI_APP_HIDDEN_EVENT,
  ALKAI_APP_LOADED_EVENT,
  ALKAI_APP_UNLOADED_EVENT,
  ALKAI_APP_VISIBLE_EVENT,
  ASSISTANT_MESSAGE_RECEIVED_EVENT,
  BRAND_CARD_LAYOUT_SWITCHED,
  BRAND_CARD_PREVIEW_CLICKED,
  BRAND_COLOR_PALETTE_SELECTED,
  BRAND_FONT_SET_SELECTED,
  BRAND_LOGO_CHANGED,
  BRAND_SETTINGS_BRAND_KIT_FOR_BRAND_SLIDE_TOGGLED,
  BRAND_SETTINGS_BRAND_KIT_FOR_POST_IDEAS_TOGGLED,
  BRAND_SETTINGS_BRAND_SLIDE_TOGGLED,
  BUSINESS_SWITCHED,
  CHAT_MENU_CLICKED_EVENT,
  CHAT_MESSAGE_SENT_EVENT,
  CREATE_ACCOUNT_SHOWN_EVENT,
  DOWNGRADE_BUSINESSES_SHOWN,
  EDIT_POST_BRAND_MENU_CLICKED_EVENT,
  EDIT_POST_CANCEL_CLICKED_EVENT,
  EDIT_POST_COLOR_PALETTE_MY_WEBSITE_PREFIX,
  EDIT_POST_COLOR_PALETTE_SWITCHED_EVENT,
  EDIT_POST_COLOR_PALETTE_SWITCHED_TYPE_CUSTOM,
  EDIT_POST_COLOR_PALETTE_SWITCHED_TYPE_DEFAULT,
  EDIT_POST_COLOR_PALETTE_SWITCHED_TYPE_WEBSITE_GENERATED,
  EDIT_POST_COLORS_MENU_CLICKED_EVENT,
  EDIT_POST_CURATED_MEDIA_SET_CLICKED_EVENT,
  EDIT_POST_FONT_SET_SWITCHED_EVENT,
  EDIT_POST_FONTS_MENU_CLICKED_EVENT,
  EDIT_POST_HOLIDAY_LAYOUT_MENU_CLICKED_EVENT,
  EDIT_POST_HOLIDAY_LAYOUT_SWITCHED_EVENT,
  EDIT_POST_LAYOUT_MENU_CLICKED_EVENT,
  EDIT_POST_LAYOUT_SWITCHED_EVENT,
  EDIT_POST_MEDIA_ADD_MEDIA_CLICKED_EVENT,
  EDIT_POST_MEDIA_ADDED_EVENT,
  EDIT_POST_MEDIA_AI_GENERATE_CLICKED_EVENT,
  EDIT_POST_MEDIA_AI_GENERATE_IMAGE_CREATED_EVENT,
  EDIT_POST_MEDIA_MENU_CLICKED_EVENT,
  EDIT_POST_MEDIA_REMOVE_CLICKED_EVENT,
  EDIT_POST_MEDIA_REPLACED_EVENT,
  EDIT_POST_MEDIA_STOCK_CLICKED_EVENT,
  EDIT_POST_MEDIA_UPLOAD_CLICKED_EVENT,
  EDIT_POST_MEDIA_UPLOAD_FAILED_EVENT,
  EDIT_POST_MEDIA_UPLOAD_STARTED_EVENT,
  EDIT_POST_MEDIA_UPLOADED_EVENT,
  EDIT_POST_MEDIA_WEBSITE_CLICKED_EVENT,
  EDIT_POST_MUSIC_APPLIED_EVENT,
  EDIT_POST_MUSIC_CANCELED_EVENT,
  EDIT_POST_MUSIC_CHANGED_EVENT,
  EDIT_POST_MUSIC_MELODIE_GENRE_GROUP_CLICKED_EVENT,
  EDIT_POST_MUSIC_MELODIE_PURPOSE_CLICKED_EVENT,
  EDIT_POST_MUSIC_MENU_CLICKED_EVENT,
  EDIT_POST_MUSIC_PICKER_SHOWN_EVENT,
  EDIT_POST_PRESET_SWITCHED_EVENT,
  EDIT_POST_PREVIEW_SHARE_CLICKED_EVENT,
  EDIT_POST_PREVIEW_SHOWN_EVENT,
  EDIT_POST_TEXT_MENU_CLICKED_EVENT,
  EDIT_POST_TEXT_MODIFIED_EVENT,
  EditColorPaletteSourceType,
  EditFontSourceType,
  EXISTING_UPLOADED_MUSIC_CLICKED_EVENT,
  EXISTING_USER_RESTORED,
  EXTEND_PLAN_CLICKED,
  EXTERNAL_FONT_LOAD_ERROR_EVENT,
  FACEBOOK_CUSTOM_EVENTS,
  FACEBOOK_PAGE_CONNECT_FAILED_EVENT,
  FACEBOOK_PAGE_CONNECTED_EVENT,
  FIRST_POST_PREVIEW_CLICKED,
  FIRST_POST_PREVIEW_SHOWN,
  FIRST_SOCIAL_NETWORK_CONNECTED_EVENT,
  GENERATE_ALL_POSTS_CLICKED,
  GENERATE_POST_CLICKED,
  GUEST_SIGN_IN_SUCCEEDED,
  HEIC_TO_JPEG_CONVERSION_COMPLETED,
  HEIC_TO_JPEG_CONVERSION_FAILED,
  HEIC_TO_JPEG_CONVERSION_STARTED,
  IN_APP_NOTICE_CLICKED,
  INITIAL_RUN_SHOWN_EVENT,
  INSTAGRAM_CONNECT_FAILED_EVENT,
  INSTAGRAM_CONNECTED_EVENT,
  KEEP_CURRENT_USER_CLICKED,
  MAKE_A_POST_UPLOAD_MEDIA_EVENT,
  MAKE_NEW_BUSINESS_CLICKED,
  MAKE_OR_RESTORE_BUSINESS_SHOWN,
  MANAGE_SUBSCRIPTION_CLICKED_EVENT,
  MEDIA_LIBRARY_AI_GENERATE_CLICKED_EVENT,
  MEDIA_LIBRARY_AI_GENERATE_IMAGE_CREATED_EVENT,
  MEDIA_LIBRARY_MEDIA_ADDED_EVENT,
  MEDIA_LIBRARY_MENU_CLICKED_EVENT,
  MEDIA_LIBRARY_STOCK_CLICKED_EVENT,
  MEDIA_LIBRARY_UPLOAD_CLICKED,
  MEDIA_LIBRARY_UPLOAD_FAILED_EVENT,
  MEDIA_LIBRARY_UPLOAD_STARTED_EVENT,
  MEDIA_LIBRARY_UPLOADED_EVENT,
  MEDIA_LIBRARY_WEBSITE_CLICKED_EVENT,
  MEDIA_STYLE_DISABLED_EVENT,
  MEDIA_STYLE_ENABLED_EVENT,
  MEDIA_STYLE_PREVIEWED_EVENT,
  MEDIA_STYLE_QUIZ_RESULTS_SHOWN_EVENT,
  MEDIA_STYLE_QUIZ_STARTED_EVENT,
  MUSIC_TRACK_FAVORITED_EVENT,
  MUSIC_TRACK_UNFAVORITED_EVENT,
  NEW_COLOR_PALETTE_ADDED,
  NEW_EXPIRY_LESS_THAN_CURRENT_EXPIRY_EVENT,
  NEW_FONT_SET_ADDED,
  ONBOARDING_QUICK_ACTION_CLICKED_EVENT,
  ONBOARDING_QUIZ_BACK_CLICKED_EVENT,
  ONBOARDING_QUIZ_CLOSED_EVENT,
  ONBOARDING_QUIZ_QUESTION_ONE_COMPLETE_EVENT,
  ONBOARDING_QUIZ_QUESTION_THREE_COMPLETE_EVENT,
  ONBOARDING_QUIZ_QUESTION_TWO_COMPLETE_EVENT,
  ONBOARDING_QUIZ_STARTED_EVENT,
  OUTPUT_FORMAT_SETTINGS_CHANGED_EVENT,
  OWNER_CHOOSE_MEDIA_COMPLETED_EVENT,
  OWNER_CHOOSE_MEDIA_SKIPPED_EVENT,
  OWNER_CHOOSE_MEDIA_UI_SHOWN_EVENT,
  PAYMENT_DIALOG_AUTO_CLOSE_USER_ALREADY_SUBSCRIBED_EVENT,
  PAYMENT_DIALOG_CANCEL_CLICKED_EVENT,
  PAYMENT_DIALOG_CLOSE_AFTER_COMPLETE_CLICKED_EVENT,
  PLAN_IDEA_ADD_CLICKED_EVENT,
  PLAN_IDEA_DELETE_CLICKED_EVENT,
  PLAN_IDEA_EDIT_CLICKED_EVENT,
  PLAN_IDEA_GENERATE_IDEA_CLICKED_EVENT,
  PLAN_IDEA_SAVE_CLICKED_EVENT,
  PLAN_MENU_CLICKED_EVENT,
  PLAN_POST_SHARE_CLICKED_EVENT,
  PLAN_SHOWN_EVENT,
  PLAN_WEEK_CHANGED,
  POST_DRAFT_FEEDBACK_SHOWN_EVENT,
  POST_DRAFT_FEEDBACK_SKIPPED_EVENT,
  POST_DRAFT_FEEDBACK_SUBMITTED_EVENT,
  POST_IDEA_BRAND_KIT_FOR_BRAND_SLIDE_TOGGLED,
  POST_IDEA_BRAND_SLIDE_TOGGLED,
  POST_IDEA_EDIT_BRAND_SETTINGS_CLICKED,
  POST_IDEA_NOTIFICATION_ERROR,
  POST_IDEA_NOTIFICATION_SHOWN,
  POST_PREVIEW_CLICKED,
  POST_PREVIEW_SHOWN,
  POST_SUCCESS_DIALOG_SHOWN_EVENT,
  QUICK_ACTION_CLICKED_EVENT,
  REMOVE_BUSINESS_BUTTON_CLICKED,
  RESEND_CODE_CLICKED_EVENT,
  RESTORE_BUSINESS_CLICKED,
  RESTORE_EXISTING_USER_CLICKED,
  RETRY_BUTTON_CLICKED_EVENT,
  SETTINGS_MENU_CLICKED_EVENT,
  SETTINGS_SIGN_OUT_CLICKED_EVENT,
  SETTINGS_TOPIC_ADDED_EVENT,
  SETTINGS_TOPIC_REMOVED_EVENT,
  SETTINGS_WEEKLY_PLAN_UPDATED_EVENT,
  SHARE_CHOICE_SCHEDULE_CLICKED,
  SHARE_CHOICE_SHARE_NOW_CLICKED,
  SHARE_OPTIONS_SHOWN_EVENT,
  SHARE_POST_CLICKED,
  SHARE_POST_COMPLETED_EVENT,
  SHARE_POST_DOWNLOAD_FAILED_EVENT,
  SHARE_POST_FIRST_DOWNLOAD_COMPLETED_EVENT,
  SHARE_POST_SAVE_CANCELED_EVENT,
  SHARE_POST_SAVE_COMPLETED_EVENT,
  SHARE_POST_SAVE_FAILED_EVENT,
  SIGN_IN_AS_GUEST_CLICKED_EVENT,
  SIGN_IN_CLICKED_EVENT,
  SIGN_OUT_AND_REJECT_AS_UNAUTHORIZED_EVENT,
  SocialNetworkConnectionSourcePageType,
  STRIPE_CHECKOUT_COMPLETE_EVENT,
  STRIPE_CHECKOUT_FAILED_EVENT,
  STRIPE_CHECKOUT_SESSION_CREATED_EVENT,
  STRIPE_CHECKOUT_STARTED_EVENT,
  STRIPE_DOWNGRADE_COMPLETE,
  STRIPE_UPGRADE_COMPLETE,
  STYLE_AND_BRANDING_CLICKED,
  STYLE_QUIZ_CLICKED,
  SUBMIT_A_HELP_TICKET_CLICKED,
  SURVEY_LINK_CLICKED,
  UNSUBSCRIBED_ALL_NOTIFICATIONS_CHANGED,
  UPLOAD_FONT_COMPLETED,
  UPLOAD_FONT_FAILED,
  UPLOAD_MUSIC_COMPLETED_EVENT,
  UPLOAD_MUSIC_FAILED_EVENT,
  UPSELL_CANCEL_CLICKED_EVENT,
  UPSELL_CONTINUE_TO_PAYMENT_CLICKED_EVENT,
  UPSELL_SHOWN_EVENT,
  USER_SIGN_IN_SUCCEEDED,
  VERIFY_CLICKED_EVENT,
  VIDEO_UPLOADED_EVENT,
  WEEKLY_POSTING_PLAN_REQUEST_COMPLETED,
  WEEKLY_POSTING_PLAN_REQUEST_SHOWN,
  WRONG_NUMBER_CLICKED_EVENT,
} from "./trackingConstants";
import { store } from "../app/store";
import {
  businessInstagramAccountID,
  BusinessState,
  facebookPageAccountID,
  getBrandStyleColorPalette,
  getBrandStyleFontSet,
  getBrandStyleLogo,
  getBusiness,
  getFirstPostDrafted,
  isSubscribedAllNotifications
} from "../features/business/businessSlice";
import {
  getIsInternalUser,
  getMaxNumberOfBusinesses,
  getNumberOfBusinesses,
  getUserAuthData,
  getUserEmail,
  getUserPhoneNumber,
  getUserSlug,
  isGuestUser,
  isSubscribedUser,
} from "../features/user/userSlice";
import {
  CurrentStreakAPI,
  HolidayLayoutPreviewAPI,
  SocialNetworkAccountAPI,
  SocialNetworkAccountType,
  SocialNetworkPostAPI,
  TIKTOK,
  WeeklyScheduleAPI,
  YOUTUBE
} from "../services/business.services";
import { getAssistantType, getMessages, MediaSelection, PostIdeaDataAPI } from "../features/assistantChat/assistantChatSlice";
import { each, includes, keys, reduce, size, startsWith } from "lodash";
import {
  BrandCardPreviewSource,
  MediaAssetSource,
  MELODIE_ENTITY_TYPE_GENRE_GROUPS_API,
  MELODIE_ENTITY_TYPE_PURPOSES_API,
  NavigateDirection
} from "../features/constants";
import ReactPixel, { AdvancedMatching } from "react-facebook-pixel";
import * as Sentry from "@sentry/react";
import { ColorPaletteAPI, ColorPaletteOwnerTypeAPI } from "../services/colorPalette.services";
import { LayoutAPI } from "../services/layout.services";
import {
  getEpidemicSoundIdFromPostJson,
  getFontSetTrackingData,
  getLayoutPresetName,
  getMusicDataFromPostJson,
  getOutputFormatDisplayNameFromSlug,
  hasBrandCardInPostJson,
  hasCompletedPostIdea,
  hasUseBrandInBrandSlideInPostJson,
  isHolidayPostIdea
} from "../features/postIdea/postIdeaHelper";
import { NoticeAPI } from "../features/inAppNotices/inAppNoticeSlice";
import { getGAClientId, setGAClientId } from "../features/tracking/trackingSlice";
import { stringUtils } from "../features/utils/stringUtils";
import { gtagWrapper } from "./gtagWrapper";
import { FontAPI } from "../services/font.services";
import { FontSetAPI } from "../services/fontSet.services";
import { ShareBatchAPI, TiktokSettingsAPI, YoutubeSettingsAPI } from "../services/postIdeaServices";
import { isShareBatchScheduled } from "../features/postIdea/shareBatchHelper";
import { DateUtils } from "../features/utils/dateUtils";
import { getPostForPostIdea } from "../features/postIdea/postsSlice";
import { logToConsole, logToConsoleError } from "../features/utils/devLoggingHelper";
import { containsCuratedMedia } from "../features/postIdea/postDataHelper";
import { socialNetworkAccountHelper } from "./socialNetworkAccountHelper";
import { OutputFormatAPI } from "../services/outputFormat.services";
import { VideoUploadMetadata } from "../services/mediaAssetServices";
import { browserUtils } from "../features/utils/browserUtils";
import { getPostIdeasCount } from "../features/postIdea/postIdeaSlice";
import { CommonTrackAPI, MelodieGenreGroupOrPurposeAPI } from "../features/music/musicSlice";

export const eventTracker = {
  setUserId,
  initializeFacebookPixel,
  initializeAmplitude,
  initializeGoogleAnalytics,
  getPropertiesForUser,
  setUserPropertiesForCurrentUser,
  logAlkaiAppLoaded,
  logAlkaiAppVisible,
  logAlkaiAppHidden,
  logAlkaiAppUnloaded,
  logInitialRunShown,
  logCreateAccountShown,
  logSignInAsGuestClicked,
  logSignInClicked,
  logVerifyClicked,
  logUserSignInSucceeded,
  logGuestSignInSucceeded,
  logWrongNumberClicked,
  logResendCodeClicked,
  logAcceptConnectInstagramClicked,
  logInstagramConnected,
  logInstagramConnectFailed,
  logAcceptConnectFacebookPageClicked,
  logFacebookPageConnected,
  logFacebookPageConnectFailed,
  sendFirstSocialNetworkAccountConnectedIfNecessary,
  logSocialNetworkAccountRefreshFailed,
  logSocialNetworkAccountRefreshSucceeded,
  logSocialNetworkAccountRefreshButtonClicked,
  logPlanShown,
  logPostSuccessDialogShown,
  logWeeklyPostingPlanRequestShown,
  logWeeklyPostingPlanCompleted,
  logFirstPostPreviewShown,
  logFirstPostPreviewClicked,
  logPostPreviewShown,
  logPostPreviewClicked,
  logEditPostShareClicked,
  logEditPostPreviewShown,
  logEditPostCancelClicked,
  logEditPostPresetSwitched,
  logEditPostLayoutSwitched,
  logEditPostHolidayLayoutSwitched,
  logEditPostColorPaletteSwitched,
  logEditPostFontSetSwitched,
  logEditPostLayoutMenuClicked,
  logEditPostHolidayLayoutMenuClicked,
  logEditPostTextMenuClicked,
  logEditPostMediaMenuClicked,
  logEditPostMusicMenuClicked,
  logEditPostBrandMenuClicked,
  logEditPostColorsMenuClicked,
  logEditPostFontsMenuClicked,
  logShareOptionsShown,
  logEditPostCuratedMediaSetClicked,
  logEditPostMediaAddMediaClicked,
  logEditPostMediaRemoveClicked,
  logEditPostMediaUploadClicked,
  logEditPostMediaWebsiteClicked,
  logEditPostMediaStockClicked,
  logEditPostMediaAiGenerateClicked,
  logEditPostMediaAiGenerateImageCreated,
  logEditPostTextModified,
  logEditPostMediaReplaced,
  logEditPostMediaUploadStarted,
  logEditPostMediaUploaded,
  logEditPostMediaUploadFailed,
  logEditPostMediaAdded,
  logEditPostMusicChanged,
  logEditPostMusicApplied,
  logEditPostMusicCanceled,
  logEditPostMusicPickerShown,
  logEditPostMusicMelodieGenreGroupOrPurposeClicked,
  logMusicTrackFavorited,
  logMusicTrackUnfavorited,
  logAddNewUploadedMusicClicked,
  logExistingUploadedMusicClicked,
  logUploadMusicCompleted,
  logUploadMusicFailed,
  logLoadExternalFontError,
  logMediaLibraryAiGenerateImageCreated,
  logMediaLibraryUploadClicked,
  logMediaLibraryWebsiteClicked,
  logMediaLibraryStockClicked,
  logMediaLibraryAiGenerateClicked,
  logMediaLibraryUploadStarted,
  logMediaLibraryUploaded,
  logMediaLibraryUploadFailed,
  logMediaLibraryMediaAdded,
  logVideoUploaded,
  logSharePostClicked,
  logSharePostCompleted,
  logSharePostFirstDownloadCompleted,
  logSharePostDownloadFailed,
  logSharePostSaveCompleted,
  logSharePostSaveCanceled,
  logSharePostSaveFailed,
  logRetryButtonClicked,
  logPostDraftFeedbackShown,
  logPostDraftFeedbackSubmitted,
  logPostDraftFeedbackSkipped,
  logOwnerChooseMediaUIShown,
  logOwnerChooseMediaCompleted,
  logOwnerChooseMediaSkipped,
  logMakeAPostUploadMedia,
  logChatMenuClicked,
  logPlanPostShareClicked,
  logPlanMenuClicked,
  logMediaLibraryMenuClicked,
  logSettingsMenuClicked,
  logSettingsTopicAdded,
  logSettingsTopicRemoved,
  logSettingsSignOutClicked,
  logSignOutAndRejectAsUnauthorized,
  logNewExpiryLessThanCurrentExpiry,
  logSettingsWeeklyPlanUpdated,
  logOutputFormatSettingsChanged,
  logOnboardingQuizStarted,
  logOnboardingQuizClosed,
  logOnboardingQuizBackClicked,
  logOnboardingQuizQuestionOneComplete,
  logOnboardingQuizQuestionTwoComplete,
  logOnboardingQuizQuestionThreeComplete,
  logMediaStyleQuizStarted,
  logMediaStyleQuizResultsShown,
  logMediaStylePreviewed,
  logMediaStyleEnabled,
  logMediaStyleDisabled,
  logChatMessageSent,
  logAssistantMessageReceived,
  logQuickActionClicked,
  logPlanIdeaAddClicked,
  logPlanIdeaDeleteClicked,
  logPlanIdeaSaveClicked,
  logPlanIdeaEditClicked,
  logPlanIdeaGenerateIdeaClicked,
  logUpsellShown,
  logUpsellContinueToPaymentClicked,
  logUpsellCancelClicked,
  logPaymentDialogCancelClicked,
  logStripeCheckoutStarted,
  logStripeCheckoutSessionCreated,
  logStripeCheckoutFailed,
  logStripeCheckoutComplete,
  logPaymentDialogCloseAfterCompleteClicked,
  logPaymentDialogClosedUserAlreadySubscribed,
  logManageSubscriptionClicked,
  logHeicToJpegConversionStarted,
  logHeicToJpegConversionCompleted,
  logHeicToJpegConversionFailed,
  logSurveyLinkClicked,
  logStyleQuizClicked,
  logStyleAndBrandingClicked,
  logPostIdeaNotificationShown,
  logPostIdeaNotificationError,
  logInAppBannerClicked,
  logPostIdeaEditBrandSettingsClicked,
  logPostIdeaBrandSlideToggled,
  logPostIdeaBrandKitForBrandSlideToggled,
  logBrandSettingsBrandSlideToggled,
  logBrandSettingsBrandKitForBrandSlideToggled,
  logBrandSettingsBrandKitForPostIdeasToggled,
  logBrandColorPaletteSelected,
  logBrandFontSetSelected,
  logBrandLogoChanged,
  logKeepCurrentUserClicked,
  logRestoreExistingUserClicked,
  logExistingUserRestored,
  setAmplitudeUserId,
  logOnboardingQuickActionClicked,
  logBrandCardPreviewClicked,
  logBrandCardLayoutSwitched,
  logUploadFontCompleted,
  logUploadFontFailed,
  logAddFontSetClicked,
  logNewFontSetAdded,
  logAddColorPaletteClicked,
  logNewColorPaletteAdded,
  logGenerateAllPostsClicked,
  logGeneratePostClicked,
  logExtendPlanClicked,
  logPlanWeekChanged,
  logUnsubscribedAllNotificationsChanged,
  logShareChoiceShareNowClicked,
  logShareChoiceScheduleClicked,
  logBusinessSwitched,
  logAddBusinessUpsellShown,
  logStripeUpgradeComplete,
  logStripeDowngradeComplete,
  logRemoveBusinessButtonClicked,
  logDowngradeBusinessesShown,
  logMakeOrRestoreBusinessShown,
  logRestoreBusinessClicked,
  logMakeNewBusinessClicked,
  logSubmitAHelpTicketClicked,
  logConnectSocialNetworkClicked,
  logSocialNetworkConnectFailed,
  logSocialNetworkConnected,
}

interface UserProperties
{
  businessId: string,
  businessName: string,
  numberOfBusinesses: number,
  alkaiUserId?: string,
  businessDescription: string,
  weeklySchedule?: WeeklyScheduleAPI,
  firstPostDrafted?: boolean,
  hasInstagramConnected?: boolean,
  hasFacebookPageConnected?: boolean,
  hasMultipleInstagramAccounts?: boolean,
  isInternalUser: boolean;
  messageCount: number;
  assistantType: string | undefined;
  isSubscribedUser: boolean;
  alkaiWebAppVersion: string;
  mediaStylesEnabled: number;
  unsubscribedAllNotifications: boolean;
  email?: string;
  phone?: string;
  isWebAppInStandaloneMode: boolean;
}

function logAlkaiAppLoaded( path: string, queryParams: {} )
{
  logEvent( ALKAI_APP_LOADED_EVENT, { ...queryParams, path } );
}

function logAlkaiAppUnloaded()
{
  logEvent( ALKAI_APP_UNLOADED_EVENT );
}

function logAlkaiAppVisible()
{
  logEvent( ALKAI_APP_VISIBLE_EVENT );
}

function logAlkaiAppHidden()
{
  logEvent( ALKAI_APP_HIDDEN_EVENT );
}

function initializeFacebookPixel()
{
  const facebookPixelId = getFacebookPixelId();
  if ( ENABLE_USAGE_TRACKING && facebookPixelId )
  {
    const userSlug = getUserSlug( store.getState() );
    const userPhoneNumber = getUserPhoneNumber( store.getState() );
    const userEmail = getUserEmail( store.getState() );

    // @ts-ignore
    const advancedMatching: AdvancedMatching = { external_id: userSlug };

    if ( !!userPhoneNumber )
    {
      advancedMatching.ph = userPhoneNumber.replace( /^\+/, '' );
    }
    else if ( !!userEmail )
    {
      advancedMatching.em = userEmail.toLowerCase();
    }
    ReactPixel.init( facebookPixelId, advancedMatching );
    ReactPixel.pageView();
  }
}

function initializeAmplitude( deviceId?: string )
{
  let amplitudeOptions: any = {
    defaultTracking: false,
    deviceId: deviceId
  }

  amplitude.init( ENV_CONFIG.amplitudeKey, amplitudeOptions );
}

function initializeGoogleAnalytics()
{
  if ( !ENABLE_USAGE_TRACKING )
  {
    return;
  }

  const userSlug = getUserSlug( store.getState() );
  const userPhoneNumber = getUserPhoneNumber( store.getState() );
  const userEmail = getUserEmail( store.getState() );

  if ( !!userSlug )
  {
    gtagWrapper.configUserId( userSlug );
  }

  if ( !!userPhoneNumber || !!userEmail )
  {
    let userData: Record<string, any> = {};

    if ( !!userEmail )
    {
      userData.email = stringUtils.normalizeEmailForGoogleAnalytics( userEmail );
    }

    if ( !!userPhoneNumber )
    {
      userData.phoneNumber = userPhoneNumber;
    }

    gtagWrapper.setUserData( userData );
  }

  const currentGoogleClientId = getGAClientId( store.getState() );
  if ( !currentGoogleClientId )
  {
    gtagWrapper.getClientId().then( ( googleClientId ) =>
    {
      store.dispatch( setGAClientId( googleClientId ) );
    } );
  }
}

function sendFacebookTrackingEvent( eventName: string )
{
  const facebookCustomEvent = getFacebookCustomEvent( eventName );

  if ( facebookCustomEvent )
  {
    if ( facebookCustomEvent === FIRST_POST_PREVIEW_CLICKED )
    {
      sendFirstPostPreviewClickedFacebookTrackingEvent();
    }
    else
    {
      const facebookEventAttributionProperties = getFacebookEventAttributionProperty();
      ReactPixel.trackCustom( facebookCustomEvent, facebookEventAttributionProperties );
    }
  }
}

function sendFirstPostPreviewClickedFacebookTrackingEvent()
{
  const state = store.getState();
  const isUserAGuest = isGuestUser( state )
  const userSlug = getUserSlug( state )
  if ( isUserAGuest )
  {
    return;
  }

  const facebookEventAttributionProperties = {
    eventId: userSlug,
    ...getFacebookEventAttributionProperty()
  };

  ReactPixel.trackCustom( FIRST_POST_PREVIEW_CLICKED, facebookEventAttributionProperties );
}

function getFacebookCustomEvent( eventName: string )
{
  if ( FACEBOOK_CUSTOM_EVENTS.includes( eventName ) )
  {
    return eventName;
  }

  return undefined;
}

function getFacebookEventAttributionProperty()
{
  return {
    external_id: getUserSlug( store.getState() )
  }
}

function logInitialRunShown( source?: string, website?: string )
{
  logEvent( INITIAL_RUN_SHOWN_EVENT, { source, website } );
}

function logSignInAsGuestClicked( source: string, shownVia: string )
{
  logEvent( SIGN_IN_AS_GUEST_CLICKED_EVENT, { source, shownVia } );
}

function logSignInClicked( source: string, shownVia: string )
{
  logEvent( SIGN_IN_CLICKED_EVENT, { source, shownVia } );
}

function logCreateAccountShown( source: string, shownVia: string )
{
  logEvent( CREATE_ACCOUNT_SHOWN_EVENT, { source, shownVia } );
}

function logVerifyClicked()
{
  logEvent( VERIFY_CLICKED_EVENT );
}

function logUserSignInSucceeded()
{
  logEvent( USER_SIGN_IN_SUCCEEDED );
}

function logGuestSignInSucceeded()
{
  logEvent( GUEST_SIGN_IN_SUCCEEDED );
}

function logWrongNumberClicked()
{
  logEvent( WRONG_NUMBER_CLICKED_EVENT );
}

function logResendCodeClicked()
{
  logEvent( RESEND_CODE_CLICKED_EVENT );
}

// Facebook Page Connecting Events
function logAcceptConnectFacebookPageClicked( source: SocialNetworkConnectionSourcePageType )
{
  const isFirstRun = !getFirstPostDrafted( store.getState() );
  const properties = {
    isFirstRun,
    source
  }
  logEvent( ACCEPT_CONNECT_FACEBOOK_PAGE_CLICKED_EVENT, properties );
}

function logFacebookPageConnected( source: SocialNetworkConnectionSourcePageType )
{
  logEvent( FACEBOOK_PAGE_CONNECTED_EVENT, { source } );
}

function logFacebookPageConnectFailed( source: SocialNetworkConnectionSourcePageType, errorText?: string )
{
  const properties = {
    error: errorText,
    isWebAppInStandaloneMode: browserUtils.isInStandaloneMode(),
    source
  }
  logEvent( FACEBOOK_PAGE_CONNECT_FAILED_EVENT, properties );
}

// Instagram Connecting Events
function logAcceptConnectInstagramClicked( source: SocialNetworkConnectionSourcePageType )
{
  const isFirstRun = !getFirstPostDrafted( store.getState() );
  const properties = {
    isFirstRun,
    source
  }
  logEvent( ACCEPT_CONNECT_INSTAGRAM_CLICKED_EVENT, properties );
}

function logInstagramConnected( source: SocialNetworkConnectionSourcePageType )
{
  logEvent( INSTAGRAM_CONNECTED_EVENT, { source } );
}

function logInstagramConnectFailed( source: SocialNetworkConnectionSourcePageType, errorText?: string )
{
  const properties = {
    error: errorText,
    isWebAppInStandaloneMode: browserUtils.isInStandaloneMode(),
    source
  }
  logEvent( INSTAGRAM_CONNECT_FAILED_EVENT, properties );
}

function logConnectSocialNetworkClicked( accountType: SocialNetworkAccountType, source: SocialNetworkConnectionSourcePageType )
{
  const isFirstRun = !getFirstPostDrafted( store.getState() );
  const properties = {
    isFirstRun,
    source
  }
  const accountEventPhase = getEventPhaseForSocialNetworkAccountType( accountType );
  const eventName = `Connect${accountEventPhase}Clicked`;

  logEvent( eventName, properties );
}

function logSocialNetworkConnectFailed( accountType: SocialNetworkAccountType, source: SocialNetworkConnectionSourcePageType, errorText?: string )
{
  const properties = {
    error: errorText,
    isWebAppInStandaloneMode: browserUtils.isInStandaloneMode(),
    source
  }
  const accountEventPhase = getEventPhaseForSocialNetworkAccountType( accountType );
  const eventName = `${accountEventPhase}ConnectFailed`;
  logEvent( eventName, properties );
}

function logSocialNetworkConnected( accountType: SocialNetworkAccountType, source?: SocialNetworkConnectionSourcePageType )
{
  const accountEventPhase = getEventPhaseForSocialNetworkAccountType( accountType );
  const eventName = `${accountEventPhase}Connected`;
  logEvent( eventName, { source } );
}

function sendFirstSocialNetworkAccountConnectedIfNecessary( context: SocialNetworkAccountAPI[],
                                                            accountType: SocialNetworkAccountType,
                                                            source: SocialNetworkConnectionSourcePageType )
{
  const hasAnyPreviouslyEnabledNetworks = socialNetworkAccountHelper.hasAnyConnectedSocialNetworks( context );
  if ( !hasAnyPreviouslyEnabledNetworks )
  {
    logFirstSocialNetworkConnected( accountType, source );
  }
}

function logFirstSocialNetworkConnected( accountType: SocialNetworkAccountType, source?: SocialNetworkConnectionSourcePageType )
{
  const properties = {
    network: accountType,
    source
  }
  logEvent( FIRST_SOCIAL_NETWORK_CONNECTED_EVENT, properties );
}

function getEventPhaseForSocialNetworkAccountType( accountType: SocialNetworkAccountType )
{
  if ( accountType === TIKTOK )
  {
    return 'Tiktok';
  }
  else if ( accountType === YOUTUBE )
  {
    return 'Youtube';
  }

  return accountType;
}

function logSocialNetworkAccountRefreshFailed( socialNetworkAccount: SocialNetworkAccountAPI, source: SocialNetworkConnectionSourcePageType,
                                               errorText?: string )
{
  const properties = {
    error: errorText,
    isWebAppInStandaloneMode: browserUtils.isInStandaloneMode(),
    source
  }
  const accountEventPhase = getEventPhaseForSocialNetworkAccountType( socialNetworkAccount.account_type );
  const eventName = `${accountEventPhase}RefreshFailed`;
  logEvent( eventName, properties );
}

function logSocialNetworkAccountRefreshSucceeded( socialNetworkAccount: SocialNetworkAccountAPI, source: SocialNetworkConnectionSourcePageType )
{
  const properties = {
    source
  }
  const accountEventPhase = getEventPhaseForSocialNetworkAccountType( socialNetworkAccount.account_type );
  const eventName = `${accountEventPhase}RefreshSucceeded`;
  logEvent( eventName, properties );
}

function logSocialNetworkAccountRefreshButtonClicked( socialNetworkAccount: SocialNetworkAccountAPI, source: SocialNetworkConnectionSourcePageType )
{
  const properties = {
    source
  }
  const accountEventPhase = getEventPhaseForSocialNetworkAccountType( socialNetworkAccount.account_type );
  const eventName = `${accountEventPhase}RefreshButtonClicked`;
  logEvent( eventName, properties );
}

function logPlanShown( prevPath?: string, search?: string )
{
  logEvent( PLAN_SHOWN_EVENT, { prevPath, search } );
}

function logPostSuccessDialogShown( streak?: CurrentStreakAPI )
{
  const streakFromPreviousWeeks = streak?.posting_streak?.streak_from_previous_weeks || 0;
  const properties = {
    posting_streak_message: streak?.posting_streak_message,
    current_streak: streakFromPreviousWeeks + 1,
    notification_status: streak?.posting_streak?.notification_status,
  }

  logEvent( POST_SUCCESS_DIALOG_SHOWN_EVENT, properties );
}

function logWeeklyPostingPlanRequestShown( suggestedWeeklySchedule: WeeklyScheduleAPI )
{
  const postsPerWeek = Object.keys( suggestedWeeklySchedule ).filter( ( key ) => suggestedWeeklySchedule[key] === true ).length;
  logEvent( WEEKLY_POSTING_PLAN_REQUEST_SHOWN, { suggestedWeeklySchedule, postsPerWeek } );
}

function logWeeklyPostingPlanCompleted( suggestedWeeklySchedule: WeeklyScheduleAPI, userSelectedWeeklySchedule: WeeklyScheduleAPI )
{
  const suggestedPostsPerWeek = Object.keys( suggestedWeeklySchedule ).filter( ( key ) => suggestedWeeklySchedule[key] === true ).length;
  const userSelectedPostsPerWeek = Object.keys( userSelectedWeeklySchedule ).filter( ( key ) => userSelectedWeeklySchedule[key] === true ).length;

  logEvent( WEEKLY_POSTING_PLAN_REQUEST_COMPLETED, {
    suggestedWeeklySchedule,
    suggestedPostsPerWeek,
    userSelectedWeeklySchedule,
    userSelectedPostsPerWeek
  } );
}

function logFirstPostPreviewShown( postIdeaId: string, topicName: string, source: string, outputFormat: string )
{
  logEvent( FIRST_POST_PREVIEW_SHOWN, {
    postIdeaId,
    topicName,
    source,
    outputFormat
  } );
}

function logPostPreviewShown( postIdeaId: string, topicName: string, source: string, outputFormat: string )
{
  logEvent( POST_PREVIEW_SHOWN, {
    postIdeaId,
    topicName,
    source,
    outputFormat
  } );
}

function logFirstPostPreviewClicked( postIdea: PostIdeaDataAPI )
{
  logPostPreviewClickedByEvent( FIRST_POST_PREVIEW_CLICKED, postIdea );
}

function logPostPreviewClicked( postIdea: PostIdeaDataAPI )
{
  logPostPreviewClickedByEvent( POST_PREVIEW_CLICKED, postIdea );
}

function logPostPreviewClickedByEvent( eventName: string, postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( eventName, postIdeaEventProperties );
}

function logEditPostShareClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_PREVIEW_SHARE_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostPreviewShown( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_PREVIEW_SHOWN_EVENT, postIdeaEventProperties );
}

function logEditPostCancelClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_CANCEL_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostPresetSwitched( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_PRESET_SWITCHED_EVENT, postIdeaEventProperties );
}

function logEditPostLayoutSwitched( postIdea: PostIdeaDataAPI, layout: LayoutAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  const eventProperties = {
    ...postIdeaEventProperties,
    presetName: layout.preset_name,
  }
  logEvent( EDIT_POST_LAYOUT_SWITCHED_EVENT, eventProperties );
}

function logEditPostHolidayLayoutSwitched( postIdea: PostIdeaDataAPI, layout: HolidayLayoutPreviewAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  const eventProperties = {
    ...postIdeaEventProperties,
    holidayLayoutSlug: layout.slug,
  }
  logEvent( EDIT_POST_HOLIDAY_LAYOUT_SWITCHED_EVENT, eventProperties );
}

function logEditPostColorPaletteSwitched( postIdea: PostIdeaDataAPI, colorPalette: ColorPaletteAPI )
{
  let colorPaletteType = EDIT_POST_COLOR_PALETTE_SWITCHED_TYPE_DEFAULT;

  const scrapedFromWebsite = startsWith( colorPalette.name, EDIT_POST_COLOR_PALETTE_MY_WEBSITE_PREFIX );
  if ( scrapedFromWebsite )
  {
    colorPaletteType = EDIT_POST_COLOR_PALETTE_SWITCHED_TYPE_WEBSITE_GENERATED;
  }
  else if ( colorPalette.owner_type === ColorPaletteOwnerTypeAPI.Business )
  {
    colorPaletteType = EDIT_POST_COLOR_PALETTE_SWITCHED_TYPE_CUSTOM;
  }

  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  const eventProperties = {
    ...postIdeaEventProperties,
    colorPaletteType,
  }
  logEvent( EDIT_POST_COLOR_PALETTE_SWITCHED_EVENT, eventProperties );
}

function logEditPostFontSetSwitched( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_FONT_SET_SWITCHED_EVENT, postIdeaEventProperties );
}

function logEditPostLayoutMenuClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_LAYOUT_MENU_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostHolidayLayoutMenuClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_HOLIDAY_LAYOUT_MENU_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostTextMenuClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_TEXT_MENU_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaMenuClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_MEDIA_MENU_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostMusicMenuClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_MUSIC_MENU_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostBrandMenuClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_BRAND_MENU_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostColorsMenuClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_COLORS_MENU_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostFontsMenuClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_FONTS_MENU_CLICKED_EVENT, postIdeaEventProperties );
}

function logShareOptionsShown( postIdea: PostIdeaDataAPI, sharedFrom: string )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  const properties = {
    sharedFrom,
    ...postIdeaEventProperties,
  }

  logEvent( SHARE_OPTIONS_SHOWN_EVENT, properties );
}

function logEditPostCuratedMediaSetClicked( curatedMediaSetSlug: string )
{
  logEvent( EDIT_POST_CURATED_MEDIA_SET_CLICKED_EVENT, { curatedMediaSetSlug } );
}

function logEditPostMediaAddMediaClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_MEDIA_ADD_MEDIA_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaRemoveClicked( postIdea: PostIdeaDataAPI, selectedIndex: number )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  postIdeaEventProperties["selectedIndex"] = selectedIndex;
  logEvent( EDIT_POST_MEDIA_REMOVE_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaUploadClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_MEDIA_UPLOAD_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaWebsiteClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_MEDIA_WEBSITE_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaStockClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_MEDIA_STOCK_CLICKED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaAiGenerateClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  const generateMenuProperties = {
    ...postIdeaEventProperties,
  }
  logEvent( EDIT_POST_MEDIA_AI_GENERATE_CLICKED_EVENT, generateMenuProperties );
}

function logEditPostMediaAiGenerateImageCreated( id: string, textPrompt: string, stylePreset: string )
{
  logEvent( EDIT_POST_MEDIA_AI_GENERATE_IMAGE_CREATED_EVENT, { id, textPrompt, stylePreset } );
}

function logMediaLibraryAiGenerateImageCreated( id: string, textPrompt: string, stylePreset: string )
{
  logEvent( MEDIA_LIBRARY_AI_GENERATE_IMAGE_CREATED_EVENT, { id, textPrompt, stylePreset } );
}

function logMediaLibraryUploadClicked()
{
  logEvent( MEDIA_LIBRARY_UPLOAD_CLICKED );
}

function logMediaLibraryWebsiteClicked()
{
  logEvent( MEDIA_LIBRARY_WEBSITE_CLICKED_EVENT );
}

function logMediaLibraryStockClicked()
{
  logEvent( MEDIA_LIBRARY_STOCK_CLICKED_EVENT );
}

function logMediaLibraryAiGenerateClicked()
{
  logEvent( MEDIA_LIBRARY_AI_GENERATE_CLICKED_EVENT );
}

function logMediaLibraryUploadStarted( mediaInfo: object )
{
  const eventProperties = {
    ...mediaInfo
  };
  logEvent( MEDIA_LIBRARY_UPLOAD_STARTED_EVENT, eventProperties );
}

function logMediaLibraryUploaded( mediaInfo: object )
{
  const eventProperties = {
    ...mediaInfo
  };
  logEvent( MEDIA_LIBRARY_UPLOADED_EVENT, eventProperties );
}

function logMediaLibraryUploadFailed( mediaInfo: object )
{
  const eventProperties = {
    ...mediaInfo
  };
  logEvent( MEDIA_LIBRARY_UPLOAD_FAILED_EVENT, eventProperties );
}

function logVideoUploaded( videoUploadMetadata: VideoUploadMetadata )
{
  logEvent( VIDEO_UPLOADED_EVENT, videoUploadMetadata );
}

function logMediaLibraryMediaAdded( mediaSource: MediaAssetSource )
{
  logEvent( MEDIA_LIBRARY_MEDIA_ADDED_EVENT, { source: mediaSource } );
}

function logEditPostTextModified( postIdea: PostIdeaDataAPI, isPostTextModified: boolean, isPostCaptionModified: boolean )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  postIdeaEventProperties["isPostTextModified"] = isPostTextModified;
  postIdeaEventProperties["isPostCaptionModified"] = isPostCaptionModified;
  logEvent( EDIT_POST_TEXT_MODIFIED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaReplaced( postIdea: PostIdeaDataAPI, source: MediaAssetSource, curatedCollectionSlug?: string )
{
  const postIdeaEventProperties = {
    ...getPostIdeaEventProperties( postIdea ),
    source,
    curatedCollectionSlug
  };
  logEvent( EDIT_POST_MEDIA_REPLACED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaUploadStarted( postIdea: PostIdeaDataAPI, mediaInfo: object )
{
  const postIdeaEventProperties = {
    postIdeaId: postIdea.id,
    ...mediaInfo
  };

  logEvent( EDIT_POST_MEDIA_UPLOAD_STARTED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaUploaded( postIdea: PostIdeaDataAPI, mediaInfo: object )
{
  const postIdeaEventProperties = {
    postIdeaId: postIdea.id,
    ...mediaInfo
  };

  logEvent( EDIT_POST_MEDIA_UPLOADED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaUploadFailed( postIdea: PostIdeaDataAPI, mediaInfo: object )
{
  const postIdeaEventProperties = {
    postIdeaId: postIdea.id,
    ...mediaInfo
  };

  logEvent( EDIT_POST_MEDIA_UPLOAD_FAILED_EVENT, postIdeaEventProperties );
}

function logEditPostMediaAdded( postIdea: PostIdeaDataAPI, source: MediaAssetSource, numberOfMedia: number, hasVideo: boolean, hasPhoto: boolean,
                                curatedCollectionSlug?: string )
{
  const postIdeaEventProperties = {
    ...getPostIdeaEventProperties( postIdea ),
    source,
    numberOfMedia,
    hasVideo,
    hasPhoto,
    curatedCollectionSlug
  };
  logEvent( EDIT_POST_MEDIA_ADDED_EVENT, postIdeaEventProperties );
}

function logEditPostMusicChanged( postIdea: PostIdeaDataAPI, fromTrack: string, toTrack: string )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  postIdeaEventProperties["fromTrack"] = fromTrack;
  postIdeaEventProperties["toTrack"] = toTrack;
  logEvent( EDIT_POST_MUSIC_CHANGED_EVENT, postIdeaEventProperties );
}

function logEditPostMusicApplied( postIdea: PostIdeaDataAPI, fromTrack: string, toTrack: string )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  postIdeaEventProperties["fromTrack"] = fromTrack;
  postIdeaEventProperties["toTrack"] = toTrack;
  logEvent( EDIT_POST_MUSIC_APPLIED_EVENT, postIdeaEventProperties );
}

function logEditPostMusicCanceled( postIdea: PostIdeaDataAPI, backToTrack: string, pendingTrack: string )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  postIdeaEventProperties["backToTrack"] = backToTrack;
  postIdeaEventProperties["pendingTrack"] = pendingTrack;
  logEvent( EDIT_POST_MUSIC_CANCELED_EVENT, postIdeaEventProperties );
}

function logMusicTrackFavorited( track: CommonTrackAPI )
{
  const properties = {
    trackId: track.id,
    trackName: track.display_name,
    trackSource: track.type,
  };

  logEvent( MUSIC_TRACK_FAVORITED_EVENT, properties );
}

function logMusicTrackUnfavorited( track: CommonTrackAPI )
{
  const properties = {
    trackId: track.id,
    trackName: track.display_name,
    trackSource: track.type,
  };

  logEvent( MUSIC_TRACK_UNFAVORITED_EVENT, properties );
}

function logEditPostMusicPickerShown( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( EDIT_POST_MUSIC_PICKER_SHOWN_EVENT, postIdeaEventProperties );
}

function logEditPostMusicMelodieGenreGroupOrPurposeClicked( postIdea: PostIdeaDataAPI, genreGroupOrPurpose: MelodieGenreGroupOrPurposeAPI )
{
  const properties = {
    ...getPostIdeaEventProperties( postIdea ),
    melodieTracksSearchType: genreGroupOrPurpose.type,
    melodieTracksSearchQuery: genreGroupOrPurpose.name,
  };

  if ( genreGroupOrPurpose.type === MELODIE_ENTITY_TYPE_GENRE_GROUPS_API )
  {
    logEvent( EDIT_POST_MUSIC_MELODIE_GENRE_GROUP_CLICKED_EVENT, properties );
  }
  else if ( genreGroupOrPurpose.type === MELODIE_ENTITY_TYPE_PURPOSES_API )
  {
    logEvent( EDIT_POST_MUSIC_MELODIE_PURPOSE_CLICKED_EVENT, properties );
  }
}

function logAddNewUploadedMusicClicked( postIdea: PostIdeaDataAPI )
{
  logEvent( ADD_NEW_UPLOADED_MUSIC_CLICKED_EVENT, getPostIdeaEventProperties( postIdea ) );
}

function logExistingUploadedMusicClicked( postIdea: PostIdeaDataAPI )
{
  logEvent( EXISTING_UPLOADED_MUSIC_CLICKED_EVENT, getPostIdeaEventProperties( postIdea ) );
}

function logUploadMusicCompleted( track: CommonTrackAPI )
{
  logEvent( UPLOAD_MUSIC_COMPLETED_EVENT, track );
}

function logUploadMusicFailed( reason )
{
  logEvent( UPLOAD_MUSIC_FAILED_EVENT, { reason } );
}

function logLoadExternalFontError( font: FontAPI, error?: any )
{
  const properties = {
    fontFamily: font.font_family,
    url: font.custom_font_url,
    error: error?.toString()
  }
  logEvent( EXTERNAL_FONT_LOAD_ERROR_EVENT, properties );
}

function logSharePostClicked( downloadSelected: boolean,
                              facebookSelected: boolean,
                              instagramSelected: boolean,
                              tiktokSelected: boolean,
                              tiktokSettings: TiktokSettingsAPI,
                              youtubeSelected: boolean,
                              youtubeSettings: YoutubeSettingsAPI,
                              postIdea: PostIdeaDataAPI,
                              sharedFrom: string,
                              shareBatch: ShareBatchAPI, )
{
  const postIdeaProperties = getPostIdeaEventProperties( postIdea );
  const tiktokProps = getTiktokShareEventProperties( tiktokSelected, tiktokSettings );
  const youtubeProps = getYoutubeShareEventProperties( youtubeSelected, youtubeSettings );

  const properties = {
    downloadSelected,
    facebookSelected,
    instagramSelected,
    sharedFrom,
    shareBatchId: shareBatch?.id,
    ...postIdeaProperties,
    ...tiktokProps,
    ...youtubeProps,
  }
  logEvent( SHARE_POST_CLICKED, properties );
}

function logSharePostCompleted( downloadSelected: boolean,
                                facebookSelected: boolean,
                                instagramSelected: boolean,
                                tiktokSelected: boolean,
                                tiktokSettings: TiktokSettingsAPI,
                                youtubeSelected: boolean,
                                youtubeSettings: YoutubeSettingsAPI,
                                postIdea: PostIdeaDataAPI,
                                sharedFrom: string,
                                shareBatch?: ShareBatchAPI,
                                previousShareBatch?: ShareBatchAPI, )
{
  const postIdeaProperties = getPostIdeaEventProperties( postIdea );
  const shareBatchProperties = !!shareBatch && getShareBatchProperties( shareBatch, previousShareBatch );
  const tiktokProps = getTiktokShareEventProperties( tiktokSelected, tiktokSettings );
  const youtubeProps = getYoutubeShareEventProperties( youtubeSelected, youtubeSettings );

  const properties = {
    downloadSelected,
    facebookSelected,
    instagramSelected,
    tiktokSelected,
    sharedFrom,
    ...shareBatchProperties,
    ...postIdeaProperties,
    ...tiktokProps,
    ...youtubeProps,
  }
  logEvent( SHARE_POST_COMPLETED_EVENT, properties );
}

/**
 * NOTE: this event has a misleading name, but we are keeping it the same for reporting purposes
 */
function logSharePostFirstDownloadCompleted( downloadSelected: boolean,
                                             facebookSelected: boolean,
                                             instagramSelected: boolean,
                                             tiktokSelected: boolean,
                                             tiktokSettings: TiktokSettingsAPI,
                                             youtubeSelected: boolean,
                                             youtubeSettings: YoutubeSettingsAPI,
                                             postIdea: PostIdeaDataAPI,
                                             sharedFrom: string,
                                             shareBatch?: ShareBatchAPI, )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  const tiktokProps = getTiktokShareEventProperties( tiktokSelected, tiktokSettings );
  const youtubeProps = getYoutubeShareEventProperties( youtubeSelected, youtubeSettings );

  const properties = {
    downloadSelected,
    facebookSelected,
    instagramSelected,
    tiktokSelected,
    sharedFrom,
    shareBatchId: shareBatch?.id,
    ...postIdeaEventProperties,
    ...tiktokProps,
    ...youtubeProps,
  }

  logEvent( SHARE_POST_FIRST_DOWNLOAD_COMPLETED_EVENT, properties );
}

function logSharePostDownloadFailed( error: any, isAppVisible: boolean, postIdea: PostIdeaDataAPI, shareBatch: ShareBatchAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  const eventProps = {
    error,
    isAppVisible,
    shareBatchId: shareBatch?.id,
    ...postIdeaEventProperties
  }
  logEvent( SHARE_POST_DOWNLOAD_FAILED_EVENT, eventProps );
}

function logSharePostSaveCompleted()
{
  logEvent( SHARE_POST_SAVE_COMPLETED_EVENT );
}

function logSharePostSaveCanceled()
{
  logEvent( SHARE_POST_SAVE_CANCELED_EVENT );
}

function getErrorAsString( error: any )
{
  return error ? error.toString() : "alkai unknown error";
}

function logSharePostSaveFailed( shownToUser: boolean, failedNativeShareCount: number, error: any )
{
  const eventProps = {
    shownToUser,
    failedNativeShareCount,
    error: getErrorAsString( error )
  }

  logEvent( SHARE_POST_SAVE_FAILED_EVENT, eventProps );
}

function logRetryButtonClicked( failedSocialNetworks: SocialNetworkPostAPI[], shareBatch: ShareBatchAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( shareBatch.post_idea );
  const failedSocialNetworkTypes = failedSocialNetworks.map( ( failedSocialNetwork ) => failedSocialNetwork.account_type );
  const failedSocialNetworkTypesHash = failedSocialNetworkTypes.reduce( ( hash, type ) => ({ ...hash, [type]: true }), {} );
  const properties = {
    shareBatchId: shareBatch?.id,
    ...failedSocialNetworkTypesHash,
    ...postIdeaEventProperties,
  }
  logEvent( RETRY_BUTTON_CLICKED_EVENT, properties );
}

function logPostDraftFeedbackShown( postIdea: PostIdeaDataAPI )
{
  const postIdeaProperties = getPostIdeaEventProperties( postIdea );
  logEvent( POST_DRAFT_FEEDBACK_SHOWN_EVENT, postIdeaProperties );
}

function logPostDraftFeedbackSubmitted( postIdea: PostIdeaDataAPI, overallRating: number )
{
  const postIdeaProperties = getPostIdeaEventProperties( postIdea );
  const userFeedbackProperties = {
    ...postIdeaProperties,
    overallRating
  }
  logEvent( POST_DRAFT_FEEDBACK_SUBMITTED_EVENT, userFeedbackProperties );
}

function logPostDraftFeedbackSkipped( postIdea: PostIdeaDataAPI )
{
  const postIdeaProperties = getPostIdeaEventProperties( postIdea );
  logEvent( POST_DRAFT_FEEDBACK_SKIPPED_EVENT, postIdeaProperties );
}

function logOwnerChooseMediaUIShown()
{
  logEvent( OWNER_CHOOSE_MEDIA_UI_SHOWN_EVENT );
}

function logOwnerChooseMediaCompleted( selectedMedia?: MediaSelection[] )
{
  const { mediaCount, mediaDetails } = simplifyMediaSelectionInfo( selectedMedia || [] );
  logEvent( OWNER_CHOOSE_MEDIA_COMPLETED_EVENT, { mediaCount, mediaDetails } )
}

function logOwnerChooseMediaSkipped()
{
  logEvent( OWNER_CHOOSE_MEDIA_SKIPPED_EVENT );
}

function logMakeAPostUploadMedia( mediaSelections: MediaSelection[] )
{
  const { mediaCount, mediaDetails } = simplifyMediaSelectionInfo( mediaSelections );
  logEvent( MAKE_A_POST_UPLOAD_MEDIA_EVENT, { mediaCount, mediaDetails } );
}

function simplifyMediaSelectionInfo( mediaSelections: MediaSelection[] )
{
  const mediaCount = mediaSelections.length;
  const mediaDetails = reduce( mediaSelections, ( sourceMap, mediaSelection ) =>
  {
    if ( !!sourceMap[mediaSelection.source] )
    {
      const entry = sourceMap[mediaSelection.source];
      const key = mediaSelection.isVideo ? "videoCount" : "photoCount";
      entry[key] = entry[key] + 1;
    }
    else
    {
      const videoCount = mediaSelection.isVideo ? 1 : 0;
      const photoCount = mediaSelection.isVideo ? 0 : 1;

      sourceMap[mediaSelection.source] = {
        videoCount,
        photoCount
      }
    }

    return sourceMap;
  }, {} );
  return { mediaCount, mediaDetails };
}

function logChatMenuClicked()
{
  logEvent( CHAT_MENU_CLICKED_EVENT );
}

function logPlanPostShareClicked( postIdea?: PostIdeaDataAPI, )
{
  const postIdeaEventProperties = !!postIdea ? getPostIdeaEventProperties( postIdea ) : {};
  logEvent( PLAN_POST_SHARE_CLICKED_EVENT, postIdeaEventProperties );
}

function logPlanMenuClicked()
{
  logEvent( PLAN_MENU_CLICKED_EVENT );
}

function logMediaLibraryMenuClicked()
{
  logEvent( MEDIA_LIBRARY_MENU_CLICKED_EVENT );
}

function logSettingsMenuClicked()
{
  logEvent( SETTINGS_MENU_CLICKED_EVENT );
}

function logSettingsTopicAdded( topic: string )
{
  logEvent( SETTINGS_TOPIC_ADDED_EVENT, { topic } );
}

function logSettingsTopicRemoved( topic: string )
{
  logEvent( SETTINGS_TOPIC_REMOVED_EVENT, { topic } );
}

function logSettingsSignOutClicked()
{
  logEvent( SETTINGS_SIGN_OUT_CLICKED_EVENT );
}

function logNewExpiryLessThanCurrentExpiry( currentExpiryInt, newExpiryInt )
{
  logEvent( NEW_EXPIRY_LESS_THAN_CURRENT_EXPIRY_EVENT, { currentExpiryInt, newExpiryInt } );
}

function logSignOutAndRejectAsUnauthorized()
{
  const storeState = store.getState();
  const eventProps = {
    postIdeasCount: getPostIdeasCount( storeState ),
    storeAuthExpiry: storeState.user.auth?.expiry,
  }
  const directLocalStorageDataString = window.localStorage.getItem( "persist:root" );
  if ( directLocalStorageDataString )
  {
    eventProps["directLocalStorageLength"] = directLocalStorageDataString.length;
    try
    {
      const parsedStore = JSON.parse( directLocalStorageDataString );
      const directLocalStorageAuthExpiry = getUserAuthData( storeState )?.expiry;
      eventProps["directLocalStorageAuthExpiry"] = directLocalStorageAuthExpiry;
      const directLocalStoragePostIdeaStoreData = JSON.parse( parsedStore.postIdea );
      const directLocalStoragePostIdeasCount = keys( directLocalStoragePostIdeaStoreData.postIdeas ).length;
      eventProps["directLocalStoragePostIdeasCount"] = directLocalStoragePostIdeasCount;

      eventProps["matchingPostIdeaCount"] = directLocalStoragePostIdeasCount === eventProps.postIdeasCount;
      eventProps["matchingAuthExpiry"] = directLocalStorageAuthExpiry === eventProps.storeAuthExpiry;
    }
    catch (e)
    {
      logToConsoleError( `Error parsing directLocalStorageData ${e}` );
    }
  }
  return logEvent( SIGN_OUT_AND_REJECT_AS_UNAUTHORIZED_EVENT, eventProps );
}

function logSettingsWeeklyPlanUpdated()
{
  logEvent( SETTINGS_WEEKLY_PLAN_UPDATED_EVENT );
}

function logOutputFormatSettingsChanged( selectedOutputFormatSlugs: string[], outputFormatChoices?: OutputFormatAPI[] )
{
  const eventProperties = reduce( outputFormatChoices, ( properties, outputFormat ) =>
  {
    return { ...properties, [outputFormat.extend_plan_value]: includes( selectedOutputFormatSlugs, outputFormat.slug ) }
  }, {} );
  logEvent( OUTPUT_FORMAT_SETTINGS_CHANGED_EVENT, eventProperties );
}

function logOnboardingQuizStarted()
{
  logEvent( ONBOARDING_QUIZ_STARTED_EVENT );
}

function logOnboardingQuizClosed( page: string )
{
  logEvent( ONBOARDING_QUIZ_CLOSED_EVENT, { page } );
}

function logOnboardingQuizBackClicked( page: string )
{
  logEvent( ONBOARDING_QUIZ_BACK_CLICKED_EVENT, { page } );
}

function logOnboardingQuizQuestionOneComplete( value: number )
{
  logEvent( ONBOARDING_QUIZ_QUESTION_ONE_COMPLETE_EVENT, { value } );
}

function logOnboardingQuizQuestionTwoComplete( value: string[] )
{
  logEvent( ONBOARDING_QUIZ_QUESTION_TWO_COMPLETE_EVENT, { value } );
}

function logOnboardingQuizQuestionThreeComplete( value: string[] )
{
  logEvent( ONBOARDING_QUIZ_QUESTION_THREE_COMPLETE_EVENT, { value } );
}

function logMediaStyleQuizStarted( isRetake: boolean )
{
  logEvent( MEDIA_STYLE_QUIZ_STARTED_EVENT, { isRetake } );
}

function logMediaStyleQuizResultsShown( mediaStyles: string[], top5Styles: string[] )
{
  logEvent( MEDIA_STYLE_QUIZ_RESULTS_SHOWN_EVENT, { mediaStyles, top5Styles } );
}

function logMediaStylePreviewed( mediaStyle: string )
{
  logEvent( MEDIA_STYLE_PREVIEWED_EVENT, { mediaStyle } );
}

function logMediaStyleEnabled( mediaStyle: string )
{
  logEvent( MEDIA_STYLE_ENABLED_EVENT, { mediaStyle } );
}

function logMediaStyleDisabled( mediaStyle: string )
{
  logEvent( MEDIA_STYLE_DISABLED_EVENT, { mediaStyle } );
}

function logChatMessageSent( message: string )
{
  const storeState = store.getState();
  const messageCount = size( getMessages( storeState ) );
  const assistantType = getAssistantType( storeState );

  const eventProps = {
    message,
    messageCount,
    assistantType
  }

  logEvent( CHAT_MESSAGE_SENT_EVENT, eventProps );
}

function logAssistantMessageReceived()
{
  const storeState = store.getState();
  const messageCount = size( getMessages( storeState ) );
  const assistantType = getAssistantType( storeState );

  const eventProps = {
    messageCount,
    assistantType
  }

  logEvent( ASSISTANT_MESSAGE_RECEIVED_EVENT, eventProps );
}

function logQuickActionClicked( message: string )
{
  const eventProps = {
    message,
  }

  logEvent( QUICK_ACTION_CLICKED_EVENT, eventProps );
}

function logOnboardingQuickActionClicked( actionName: string )
{
  const actionNameWithoutPrefix = actionName.replace( 'onboarding_quick_action_', '' )
  const eventProps = {
    actionName: actionNameWithoutPrefix,
  }

  logEvent( ONBOARDING_QUICK_ACTION_CLICKED_EVENT, eventProps )
}

function logPlanIdeaAddClicked( planIdeaDate: Date )
{
  const currentDate = new Date();
  const eventProps = {
    planIdeaDate,
    currentDate,
    daysFromToday: DateUtils.getDaysFromToday( planIdeaDate, currentDate ),
  }
  logEvent( PLAN_IDEA_ADD_CLICKED_EVENT, eventProps );
}

function logPlanIdeaDeleteClicked( planIdeaDate: Date, planIdeaTitle: string | undefined, existing: boolean )
{
  const currentDate = new Date();
  const eventProps = {
    planIdeaDate,
    planIdeaTitle,
    existing,
    currentDate,
    daysFromToday: DateUtils.getDaysFromToday( planIdeaDate, currentDate ),
  }

  logEvent( PLAN_IDEA_DELETE_CLICKED_EVENT, eventProps );
}

function logPlanIdeaSaveClicked( planIdeaDate: Date, planIdeaTitle: string | undefined, existing: boolean )
{
  const currentDate = new Date();
  const eventProps = {
    planIdeaDate,
    planIdeaTitle,
    existing,
    currentDate,
    daysFromToday: DateUtils.getDaysFromToday( planIdeaDate, currentDate ),
  }

  logEvent( PLAN_IDEA_SAVE_CLICKED_EVENT, eventProps );
}

function logPlanIdeaEditClicked( planIdeaDate: Date, planIdeaTitle: string | undefined, postIdeaId: string | undefined )
{
  const currentDate = new Date();
  const eventProps = {
    planIdeaDate,
    planIdeaTitle,
    currentDate,
    postIdeaId,
  }

  logEvent( PLAN_IDEA_EDIT_CLICKED_EVENT, eventProps );
}

function logPlanIdeaGenerateIdeaClicked( planIdeaDate: Date, currentPlanIdeaTitle: string | undefined )
{
  const currentDate = new Date();
  const eventProps = {
    planIdeaDate,
    currentPlanIdeaTitle,
    currentDate,
  }

  logEvent( PLAN_IDEA_GENERATE_IDEA_CLICKED_EVENT, eventProps );
}

function logUpsellShown( upsellSource: string )
{
  logEvent( UPSELL_SHOWN_EVENT, { upsellSource } );
}

function logUpsellContinueToPaymentClicked( isGuest: boolean )
{
  logEvent( UPSELL_CONTINUE_TO_PAYMENT_CLICKED_EVENT, { isGuest } );
}

function logUpsellCancelClicked( isGuest: boolean )
{
  logEvent( UPSELL_CANCEL_CLICKED_EVENT, { isGuest } );
}

function logStripeCheckoutStarted()
{
  logEvent( STRIPE_CHECKOUT_STARTED_EVENT );
}

function logStripeCheckoutSessionCreated( sessionId: string )
{
  const eventProps = {
    stripeSessionId: sessionId,
  }

  logEvent( STRIPE_CHECKOUT_SESSION_CREATED_EVENT, eventProps );
}

function logStripeCheckoutFailed( error )
{
  const eventProps = {
    error: getErrorAsString( error )
  }

  logEvent( STRIPE_CHECKOUT_FAILED_EVENT, eventProps );
}

function logStripeCheckoutComplete( sessionId: string, status: string )
{
  const eventProps = {
    stripeSessionId: sessionId,
    stripeSessionStatus: status
  }

  logEvent( STRIPE_CHECKOUT_COMPLETE_EVENT, eventProps );
}

function logPaymentDialogCancelClicked()
{
  logEvent( PAYMENT_DIALOG_CANCEL_CLICKED_EVENT );
}

function logPaymentDialogCloseAfterCompleteClicked()
{
  logEvent( PAYMENT_DIALOG_CLOSE_AFTER_COMPLETE_CLICKED_EVENT );
}

function logPaymentDialogClosedUserAlreadySubscribed()
{
  logEvent( PAYMENT_DIALOG_AUTO_CLOSE_USER_ALREADY_SUBSCRIBED_EVENT );
}

function logManageSubscriptionClicked()
{
  logEvent( MANAGE_SUBSCRIPTION_CLICKED_EVENT );
}

function logHeicToJpegConversionStarted( fileName: string )
{
  const eventProps = {
    fileName
  }

  logEvent( HEIC_TO_JPEG_CONVERSION_STARTED, eventProps );
}

function logHeicToJpegConversionCompleted( fileName: string )
{
  const eventProps = {
    fileName
  }

  logEvent( HEIC_TO_JPEG_CONVERSION_COMPLETED, eventProps );
}

function logHeicToJpegConversionFailed( fileName: string, error: string )
{
  const eventProps = {
    fileName,
    error
  }

  logEvent( HEIC_TO_JPEG_CONVERSION_FAILED, eventProps );
}

function logStyleQuizClicked( businessId: string )
{
  const eventProps = {
    businessId
  }

  logEvent( STYLE_QUIZ_CLICKED, eventProps );
}

function logStyleAndBrandingClicked( businessId: string )
{
  const eventProps = {
    businessId
  }

  logEvent( STYLE_AND_BRANDING_CLICKED, eventProps );
}

function logPostIdeaNotificationShown( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( POST_IDEA_NOTIFICATION_SHOWN, postIdeaEventProperties );
}

function logPostIdeaNotificationError( message: string )
{
  logEvent( POST_IDEA_NOTIFICATION_ERROR, { message } );
}

function logSurveyLinkClicked( businessId: string, surveyId: string )
{
  const eventProps = {
    businessId,
    surveyId
  }

  logEvent( SURVEY_LINK_CLICKED, eventProps );
}

function logInAppBannerClicked( notice: NoticeAPI, path: string )
{
  logEvent( IN_APP_NOTICE_CLICKED, { id: notice.id, path } );
}

function logPostIdeaEditBrandSettingsClicked( postIdea: PostIdeaDataAPI )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( POST_IDEA_EDIT_BRAND_SETTINGS_CLICKED, postIdeaEventProperties );
}

function logPostIdeaBrandSlideToggled( postIdea: PostIdeaDataAPI, value: boolean )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( POST_IDEA_BRAND_SLIDE_TOGGLED, { ...postIdeaEventProperties, value } );
}

function logPostIdeaBrandKitForBrandSlideToggled( postIdea: PostIdeaDataAPI, value: boolean )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  logEvent( POST_IDEA_BRAND_KIT_FOR_BRAND_SLIDE_TOGGLED, { ...postIdeaEventProperties, value } );
}

function logBrandSettingsBrandSlideToggled( value: boolean )
{
  logEvent( BRAND_SETTINGS_BRAND_SLIDE_TOGGLED, { value } );
}

function logBrandSettingsBrandKitForBrandSlideToggled( value: boolean )
{
  logEvent( BRAND_SETTINGS_BRAND_KIT_FOR_BRAND_SLIDE_TOGGLED, { value } );
}

function logBrandSettingsBrandKitForPostIdeasToggled( value: boolean )
{
  logEvent( BRAND_SETTINGS_BRAND_KIT_FOR_POST_IDEAS_TOGGLED, { value } );
}

function logBrandColorPaletteSelected( colorPaletteSlug: string )
{
  logEvent( BRAND_COLOR_PALETTE_SELECTED, { slug: colorPaletteSlug } );
}

function logBrandFontSetSelected( fontSetSlug: string )
{
  logEvent( BRAND_FONT_SET_SELECTED, { slug: fontSetSlug } );
}

function logBrandLogoChanged( logoMediaAssetId?: string )
{
  logEvent( BRAND_LOGO_CHANGED, { logoMediaAssetId } );
}

function logKeepCurrentUserClicked()
{
  logEvent( KEEP_CURRENT_USER_CLICKED );
}

function logRestoreExistingUserClicked()
{
  logEvent( RESTORE_EXISTING_USER_CLICKED );
}

function logExistingUserRestored( guestUserId?: string )
{
  logEvent( EXISTING_USER_RESTORED, { guestUserId } );
}

function logBrandCardPreviewClicked( source: BrandCardPreviewSource )
{
  logEvent( BRAND_CARD_PREVIEW_CLICKED, { source } );
}

function logBrandCardLayoutSwitched( source: BrandCardPreviewSource, brand_slide_layout: string | undefined )
{
  logEvent( BRAND_CARD_LAYOUT_SWITCHED, { source, brand_slide_layout } );
}

function logUploadFontCompleted( source: EditFontSourceType, font: FontAPI )
{
  const eventParams = {
    source,
    fontSlug: font.slug,
    fontName: font.display_name
  }

  logEvent( UPLOAD_FONT_COMPLETED, eventParams );
}

function logUploadFontFailed( source: EditFontSourceType, reason: string )
{
  logEvent( UPLOAD_FONT_FAILED, { source, reason } );
}

function logAddFontSetClicked( source: EditFontSourceType )
{
  logEvent( ADD_FONT_SET_CLICKED, { source } );
}

function logNewFontSetAdded( source: EditFontSourceType, newFontSet: FontSetAPI )
{
  const eventParams = {
    source,
    fontSetSlug: newFontSet.slug
  }
  logEvent( NEW_FONT_SET_ADDED, { eventParams } );
}

function logAddColorPaletteClicked( source: EditColorPaletteSourceType )
{
  logEvent( ADD_COLOR_PALETTE_CLICKED, { source } );
}

function logNewColorPaletteAdded( source: EditColorPaletteSourceType, newColorPalette: ColorPaletteAPI )
{
  const eventParams = {
    source,
    paletteSlug: newColorPalette.slug,
    paletteName: newColorPalette.name
  }

  logEvent( NEW_COLOR_PALETTE_ADDED, eventParams );
}

function logGenerateAllPostsClicked( postIdeaCount: number, postIdeaTitles: (string | undefined)[] )
{
  const eventProperties = {
    postIdeaCount,
    postIdeaTitles,
  }
  logEvent( GENERATE_ALL_POSTS_CLICKED, eventProperties );
}

function logGeneratePostClicked( planSuggestionDate: Date, planIdeaTitle: string | undefined )
{
  const currentDate = new Date();
  const eventProps = {
    planSuggestionDate,
    currentDate,
    planIdeaTitle,
    daysFromToday: DateUtils.getDaysFromToday( planSuggestionDate, currentDate ),
  }

  logEvent( GENERATE_POST_CLICKED, eventProps );
}

function logExtendPlanClicked( expectedSuggestionCount: number )
{
  const eventProps = {
    expectedSuggestionCount
  }

  logEvent( EXTEND_PLAN_CLICKED, eventProps );
}

function logPlanWeekChanged( direction: NavigateDirection, requestedWeekStartDate: string )
{
  const eventProps = {
    direction: direction,
    requestedWeekStartDate
  }

  logEvent( PLAN_WEEK_CHANGED, eventProps );
}

function logUnsubscribedAllNotificationsChanged( unsubscribedAllNotifications: boolean )
{
  logEvent( UNSUBSCRIBED_ALL_NOTIFICATIONS_CHANGED, { unsubscribedAllNotifications } );
}

function logShareChoiceShareNowClicked( postIdea: PostIdeaDataAPI, sharedFrom: string )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  const properties = {
    sharedFrom,
    ...postIdeaEventProperties,
  }
  logEvent( SHARE_CHOICE_SHARE_NOW_CLICKED, properties );
}

function logShareChoiceScheduleClicked( postIdea: PostIdeaDataAPI, sharedFrom: string )
{
  const postIdeaEventProperties = getPostIdeaEventProperties( postIdea );
  const properties = {
    sharedFrom,
    ...postIdeaEventProperties,
  }
  logEvent( SHARE_CHOICE_SCHEDULE_CLICKED, properties );
}

function logBusinessSwitched()
{
  logEvent( BUSINESS_SWITCHED );
}

function logAddBusinessUpsellShown()
{
  logEvent( ADD_BUSINESS_UPSELL_SHOWN );
}

function logStripeDowngradeComplete()
{
  const eventProperties = {
    maxNumberOfBusinesses: getMaxNumberOfBusinesses( store.getState() ),
  }
  logEvent( STRIPE_DOWNGRADE_COMPLETE, eventProperties );
}

function logStripeUpgradeComplete()
{
  const eventProperties = {
    maxNumberOfBusinesses: getMaxNumberOfBusinesses( store.getState() ),
  }
  logEvent( STRIPE_UPGRADE_COMPLETE, eventProperties );
}

function logRemoveBusinessButtonClicked()
{
  const eventProperties = {
    maxNumberOfBusinesses: getMaxNumberOfBusinesses( store.getState() ),
  }
  logEvent( REMOVE_BUSINESS_BUTTON_CLICKED, eventProperties );
}

function logDowngradeBusinessesShown()
{
  const eventProperties = {
    maxNumberOfBusinesses: getMaxNumberOfBusinesses( store.getState() ),
  }
  logEvent( DOWNGRADE_BUSINESSES_SHOWN, eventProperties );
}

function logMakeOrRestoreBusinessShown()
{
  const eventProperties = {
    maxNumberOfBusinesses: getMaxNumberOfBusinesses( store.getState() ),
  }
  logEvent( MAKE_OR_RESTORE_BUSINESS_SHOWN, eventProperties );
}

function logMakeNewBusinessClicked()
{
  const eventProperties = {
    maxNumberOfBusinesses: getMaxNumberOfBusinesses( store.getState() ),
  }
  logEvent( MAKE_NEW_BUSINESS_CLICKED, eventProperties );
}

function logRestoreBusinessClicked()
{
  const eventProperties = {
    maxNumberOfBusinesses: getMaxNumberOfBusinesses( store.getState() ),
  }
  logEvent( RESTORE_BUSINESS_CLICKED, eventProperties );
}

function logSubmitAHelpTicketClicked()
{
  logEvent( SUBMIT_A_HELP_TICKET_CLICKED );
}

function setUserPropertiesForCurrentUser()
{
  const userProperties = getPropertiesForUser();
  setUserProperties( userProperties );
  setUserPropertiesForSentry( userProperties );
}

function setUserProperties( userProperties: UserProperties )
{
  setAmplitudeUserProperties( userProperties );
}

function setUserPropertiesForSentry( userProperties: UserProperties )
{
  if ( ENABLE_ERROR_REPORTING )
  {
    // set similar properties to ripl?
    // const {
    //   businessId,
    //   businessName,
    // } = userProperties;
    //
    // Sentry.setUser()
    //
    // Raven.setUserContext( {
    //   id: userProperties.alkaiUserId,
    //   businessId,
    //   businessName,
    // } );
    //
    // Raven.setTagsContext( {
    //   userId: userProperties.alkaiUserId,
    //   businessId,
    // } );
  }
}

function logEvent( eventName: string, eventProperties?: object )
{
  const allEventProperties = eventProperties;
  // const allEventProperties = mergeAllEventProperties( eventProperties );
  // const filteredEventProperties = omitBy( allEventProperties, isNil );

  try
  {
    if ( ENABLE_USAGE_TRACKING )
    {
      sendFacebookTrackingEvent( eventName );
      const promiseInReturn = amplitude.track( eventName, allEventProperties )?.promise;
      return promiseInReturn || Promise.resolve();
    }
    else if ( ENABLE_EVENT_CONSOLE_LOGGING )
    {
      logToConsole( `trackingEvent ${eventName} withProperties`, allEventProperties );
      return Promise.resolve();
    }
    else
    {
      return Promise.resolve();
    }
  }
  catch (e)
  {
    // errorReporting.reportErrorToSentry( e );
    logToConsoleError( "Error occurred while logging an even", e );
    return Promise.resolve();
  }
}

function setUserId( userSlug: string )
{
  setAmplitudeUserId( userSlug );
  Sentry.setUser( { id: userSlug } );
  initializeFacebookPixel();
  initializeGoogleAnalytics();
}

function setAmplitudeUserId( userSlug: string )
{
  if ( ENABLE_USAGE_TRACKING )
  {
    amplitude.setUserId( userSlug );
  }
}

function setAmplitudeUserProperties( userProperties: UserProperties )
{
  if ( ENABLE_USAGE_TRACKING )
  {
    const identifyEvent = new amplitude.Identify();

    each( userProperties, ( value, key ) =>
    {
      if ( value !== undefined )
      {
        if ( typeof value === 'object' )
        {
          identifyEvent.set( key, JSON.stringify( value ) );
        }
        else
        {
          identifyEvent.set( key, value );
        }
      }
    } );

    amplitude.identify( identifyEvent );
  }
  else if ( ENABLE_EVENT_CONSOLE_LOGGING )
  {
    const userPropertiesArgs: any[] = [];
    userPropertiesArgs.push( "userProperties\n" )

    each( userProperties, ( value, key ) =>
    {
      let userPropertyValue = value;
      if ( typeof value === 'object' )
      {
        userPropertyValue = JSON.stringify( value );
      }
      userPropertiesArgs.push( `${key}: ${userPropertyValue}\n` );
    } );

    logToConsole( "user properties", ...userPropertiesArgs );
  }
}

function getPropertiesForUser(): UserProperties
{
  const storeState = store.getState();
  const userSlug = getUserSlug( storeState );
  const isInternalUser = getIsInternalUser( storeState );
  const business = getBusiness( storeState ) as BusinessState;
  const messageCount = size( getMessages( storeState ) );
  const assistantType = getAssistantType( storeState ) || "";
  const facebookPageConnected = !!facebookPageAccountID( storeState );
  const instagramConnected = !!businessInstagramAccountID( storeState );

  return {
    businessId: business.business_id,
    businessName: business.name,
    numberOfBusinesses: getNumberOfBusinesses( storeState ) || 0,
    alkaiUserId: userSlug || "",
    businessDescription: business.description,
    weeklySchedule: business.weekly_schedule,
    firstPostDrafted: business.first_post_drafted || false,
    hasInstagramConnected: instagramConnected,
    hasFacebookPageConnected: facebookPageConnected,
    hasMultipleInstagramAccounts: business.has_multiple_instagram_accounts || false,
    isInternalUser,
    messageCount,
    assistantType,
    isSubscribedUser: isSubscribedUser( storeState ),
    alkaiWebAppVersion: ALKAI_VERSION,
    mediaStylesEnabled: business.active_business_media_styles_count || 0,
    unsubscribedAllNotifications: !isSubscribedAllNotifications( storeState ),
    email: getUserEmail( storeState ),
    phone: getUserPhoneNumber( storeState ),
    isWebAppInStandaloneMode: browserUtils.isInStandaloneMode()
  };
}

function getPostIdeaEventProperties( postIdea: PostIdeaDataAPI )
{
  const storeState = store.getState();
  const post = getPostForPostIdea( storeState, postIdea.id );
  const hasBrandSlide = hasBrandCardInPostJson( postIdea );
  const brandSlideUsingBrandKit = hasUseBrandInBrandSlideInPostJson( postIdea );
  const brandKitHasColorPalette = !!getBrandStyleColorPalette( storeState )
  const brandKitHasFontSet = !!getBrandStyleFontSet( storeState )
  const brandKitHasLogo = !!getBrandStyleLogo( storeState )
  const presetName = getLayoutPresetName( postIdea );
  const fontSetTrackingData = getFontSetTrackingData( postIdea );
  const usesCuratedMedia = !!post && containsCuratedMedia( post );
  const outputFormat = getOutputFormatDisplayNameFromSlug( postIdea.output_format_slug );
  const epidemicSoundId = getEpidemicSoundIdFromPostJson( postIdea );
  const musicData = getMusicDataFromPostJson( postIdea );
  const musicType = musicData?.type;
  const musicId = musicData?.id;

  let eventProperties: any = {
    postIdeaId: postIdea.id,
    postIdeaFormula: postIdea.post_idea_formula?.name,
    isShared: hasCompletedPostIdea( postIdea ),
    isEdited: !!postIdea.edited_at,
    customMediaStyle: postIdea.custom_media_style,
    isFromRecipe: !!postIdea.post_idea_recipe,
    postDataLoaded: !!post,
    hasBrandSlide,
    brandSlideUsingBrandKit,
    brandKitHasColorPalette,
    brandKitHasFontSet,
    brandKitHasLogo,
    presetName,
    usesCuratedMedia,
    outputFormat,
    epidemicSoundId,
    musicType,
    musicId,
  };

  if ( !!fontSetTrackingData )
  {
    eventProperties = {
      ...eventProperties,
      fontSetOwnedByBusiness: fontSetTrackingData.owned_by_business,
      fontSetCustomFontCount: fontSetTrackingData.custom_font_count,
    }
  }

  if ( isHolidayPostIdea( postIdea ) )
  {
    eventProperties = {
      ...eventProperties,
      holidayName: postIdea.configuration.config_bundle.holiday?.holiday_name,
      holidayLayoutId: postIdea.configuration.config_bundle.holiday?.holiday_layout_slug
    }

  }

  return eventProperties;
}

function getShareBatchProperties( shareBatch: ShareBatchAPI, previousShareBatch?: ShareBatchAPI )
{
  let daysAway = 0;
  if ( isShareBatchScheduled( shareBatch ) && !!shareBatch.scheduled_for )
  {
    daysAway = DateUtils.getNumberOfCalendarDaysAway( new Date( shareBatch.scheduled_for ), new Date() );
  }
  return {
    shareBatchId: shareBatch?.id,
    isScheduled: !!shareBatch && isShareBatchScheduled( shareBatch ),
    daysAway: daysAway,
    isReschedule: !!previousShareBatch && isShareBatchScheduled( previousShareBatch ),
  }
}

function getTiktokShareEventProperties( tiktokSelected: boolean,
                                        tiktokSettings: TiktokSettingsAPI )
{
  let tiktokProps: any = {
    tiktokSelected
  };
  if ( tiktokSelected )
  {
    tiktokProps = {
      ...tiktokProps,
      tiktokPrivacySelection: tiktokSettings.privacy_level,
      tiktokCommentDisabled: tiktokSettings.comment_disabled,
      tiktokDuetDisabled: tiktokSettings.duet_disabled,
      tiktokStitchDisabled: tiktokSettings.stitch_disabled,
      tiktokBrandOrganicToggle: tiktokSettings.brand_organic_toggle,
      tiktokBrandContentToggle: tiktokSettings.brand_content_toggle,
    }
  }
  return {
    ...tiktokProps,
  }
}

function getYoutubeShareEventProperties( youtubeSelected: boolean, youtubeSettings: YoutubeSettingsAPI )
{
  let youtubeProps: any = {
    youtubeSelected
  }
  if ( youtubeSelected )
  {
    youtubeProps = {
      ...youtubeProps,
      youtubePrivacySelection: youtubeSettings.privacy,
      youtubeTitle: youtubeSettings.video_title,
    }
  }
  return {
    ...youtubeProps,
  }
}
