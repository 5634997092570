import { combineReducers, configureStore } from '@reduxjs/toolkit'
import featureFlagReducer from '../features/featureFlags/featureFlagsSlice'
import businessReducer from '../features/business/businessSlice'
import postsReducer from '../features/postIdea/postsSlice'
import stockMediaReducer from '../features/stock_media/stockMediaSlice'
import loadingReducer from '../features/loadingIndicator/loadingSlice'
import alertReducer from '../features/alert/alertSlice'
import userReducer from '../features/user/userSlice'
import musicReducer from '../features/music/musicSlice'
import uiReducer from '../features/ui/uiSlice'
import amplitudeFeatureFlagsReducer from '../features/amplitude/amplitudeFeatureFlagsSlice'
import assistantChatReducer from '../features/assistantChat/assistantChatSlice'
import trackingReducer from '../features/tracking/trackingSlice'
import inAppNoticeReducer from '../features/inAppNotices/inAppNoticeSlice'
import postIdeaReducer from '../features/postIdea/postIdeaSlice'

import storage from "redux-persist/lib/storage";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { CURRENT_ROOT_STORE_VERSION, CURRENT_UI_STORE_VERSION, rootMigrations, uiMigrations } from "./migrations";
import { logToConsoleError } from "../features/utils/devLoggingHelper";

const persistConfig = {
  key: 'root',
  version: CURRENT_ROOT_STORE_VERSION,
  storage,
  blacklist: ['ui'],
  migrate: createMigrate( rootMigrations, { debug: false } ),
}

const uiPersistConfig = {
  key: 'ui',
  version: CURRENT_UI_STORE_VERSION,
  storage,
  blacklist: ['audioPlayerPlaying',
              'audioPlayerTrack',
              'requestEditorReloadWithAudioTimestamp',
              'requestEditorPauseAudioTimestamp',
              'editorAnimationCompleteTimestamp',
              'editorAudioStartTimeOverrideMs',
              'editorDesignAudioNotAllowed'],
  migrate: createMigrate( uiMigrations, { debug: false } ),
}

const rootReducer = combineReducers( {
  assistantChat: assistantChatReducer,
  business: businessReducer,
  posts: postsReducer,
  featureFlags: featureFlagReducer,
  stockMedia: stockMediaReducer,
  loading: loadingReducer,
  alert: alertReducer,
  user: userReducer,
  music: musicReducer,
  ui: persistReducer( uiPersistConfig, uiReducer ),
  amplitudeFeatureFlags: amplitudeFeatureFlagsReducer,
  tracking: trackingReducer,
  inAppNotices: inAppNoticeReducer,
  postIdea: postIdeaReducer,
} )

const persistedReducer = persistReducer( persistConfig, rootReducer )

let shouldShowReduxLogger = process.env.NODE_ENV !== 'production'
try
{
  shouldShowReduxLogger = shouldShowReduxLogger || new URL( window.location.href ).searchParams.get( 'devMode' ) === 'true'
}
catch (error)
{
  logToConsoleError( "Error parsing url" );
}

export const store = configureStore( {
  reducer: persistedReducer,
  devTools: shouldShowReduxLogger,
  middleware: shouldShowReduxLogger ? [thunk, logger] : [thunk],
} )

export const persistor = persistStore( store )

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
