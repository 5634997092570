import { Box, SxProps, Typography } from "@mui/material";
import { merge } from "lodash";
import LibraryMusicOutlinedIcon from "@mui/icons-material/LibraryMusicOutlined";
import { useDispatch } from "react-redux";
import { clearAndStopAudioPlayerTrack } from "../ui/uiSlice";
import { ReactNode } from "react";

interface BaseMusicItemViewProps
{
  handleOnClick: () => void;
  icon?: ReactNode;
  text: string;
  sx?: SxProps;
}

export function BaseMusicItemView( props: BaseMusicItemViewProps )
{
  const dispatch = useDispatch();

  function handleOnClick()
  {
    stopPlayingMusic();
    props.handleOnClick();
  }

  const stopPlayingMusic = () =>
  {
    dispatch( clearAndStopAudioPlayerTrack() );
  };
  return (
    <Box sx={merge( { display: "flex", m: 5, borderRadius: "5px", alignItems: "center" }, props.sx )} onClick={handleOnClick}>
      {props.icon || <LibraryMusicOutlinedIcon sx={{ fontSize: 40 }}/>}
      <Typography variant={"subtitle1"} sx={{ ml: 5, color: "black", fontSize: "17px" }}>{props.text}</Typography>
    </Box>
  );
}