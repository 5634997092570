import { SxProps } from "@mui/material";
import { VolumeOff } from "@mui/icons-material";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { NO_MUSIC_TRACK_DISPLAY_NAME } from "../constants";
import { SelectableMusicItemView } from "./selectableMusicItemView";

interface NoMusicItemProps
{
  postIdea: PostIdeaDataAPI;
  selected: boolean;
  handleTrackSelected: () => void;
  iconSize?: number;
  sx?: SxProps;
}

export function NoMusicItem( props: NoMusicItemProps )
{
  const iconSize = props.iconSize || 56;
  const iconSizePx = `${iconSize}px`;

  return (
    <SelectableMusicItemView
      sx={props.sx}
      selected={props.selected}
      handleOnClick={props.handleTrackSelected}
      icon={<VolumeOff sx={{ height: iconSizePx, width: iconSizePx, color: props.selected ? "primary.main" : "#000000" }}/>}
      text={NO_MUSIC_TRACK_DISPLAY_NAME}
    />
  );
}
