import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { musicServices } from "../../services/music.services";
import { useDispatch } from "react-redux";
import { map } from "lodash";
import { NoMusicItem } from "../music/NoMusicItem";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { eventTracker } from "../../helpers/eventTracker";
import {
  NO_MUSIC_TRACK_DISPLAY_NAME,
  NO_MUSIC_TRACK_ID,
  TRACK_TYPE_MELODIE,
  TRACK_TYPE_NO_MUSIC,
  TRACK_TYPE_UPLOADED,
  TRACK_TYPE_VIDEO_AUDIO,
  VIDEO_AUDIO_MUSIC_TRACK
} from "../constants";
import { PostIdeaContext } from "../context/postIdeaContext";
import { CommonTrackAPI, MelodieGenreGroupOrPurposeAPI, MusicSelection } from "../music/musicSlice";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { MelodieGenreGroupOrPurposeItem } from "../music/melodieGenreGroupOrPurposeItem";
import { MelodieTracksFullScreenDialog } from "../music/melodieTracksFullScreenDialog";
import { UploadMusicItem } from "../music/UploadMusicItem";
import { UploadedTracksFullScreenDialog } from "../music/uploadedTracksFullScreenDialog";
import { AudioDrawer } from "../ui/audioDrawer";
import { musicItemHelper } from "../music/musicItemHelper";
import { clearAndStopAudioPlayerTrack, MusicTrack, setAudioPlayerPlaying, setAudioPlayerTrack } from "../ui/uiSlice";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { getSelectedTrackIdFromPostJson, getSelectedTrackTypeFromPostJson } from "../postIdea/postIdeaHelper";
import { EditMelodieMusicHeader } from "./editMelodieMusicHeader";
import { Divider, Grid, Typography } from "@mui/material";
import { FullScreenModalInnerWrap } from "./fullScreenModalInnerWrap";
import { VideoAudioMusicItem } from "../music/VideoAudioMusicItem";
import { amplitudeFeatureFlagsVariables } from "../amplitude/amplitudeFeatureFlagsVariables";
import { MyFavoritesFullScreenDialog } from "../music/myFavoritesFullScreenDialog";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { FavoriteTrackIdsContext } from "../context/favoriteTrackIdsContext";
import { MusicPlayer } from "../music/MusicPlayer";
import { BaseMusicItemView } from "../music/BaseMusicItemView";

export interface EditMelodieMusicTabContentProps extends WithFullScreenDialogProps
{
  handleCloseAfterApplyingMusic: () => void;
}

export function EditMelodieMusicTabContent( props: EditMelodieMusicTabContentProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const [favoriteTrackIdsMap, setFavoriteTrackIdsMap] = useState( { [TRACK_TYPE_MELODIE]: {}, [TRACK_TYPE_UPLOADED]: {} } );
  const [genreGroups, setGenreGroups] = useState<MelodieGenreGroupOrPurposeAPI[]>( [] );
  const [purposes, setPurposes] = useState<MelodieGenreGroupOrPurposeAPI[]>( [] );

  const [selectedGenreGroupOrPurpose, setSelectedGenreGroupOrPurpose] = useState<MelodieGenreGroupOrPurposeAPI>();

  const selectedTrackId = getSelectedTrackIdFromPostJson( postIdea );
  const selectedTrackType = getSelectedTrackTypeFromPostJson( postIdea );

  const [loading, setLoading] = useState( false );
  const [pendingSelection, setPendingSelection] = useState<MusicSelection | undefined>( undefined );

  const dispatch = useDispatch();
  const [showUploadedMusicDialog, setShowUploadedMusicDialog] = useState( false );
  const [showMyFavoriteTracksDialog, setShowMyFavoritesDialog] = useState( false );
  const shouldShowMusicFavorites = amplitudeFeatureFlagsVariables.shouldShowMusicFavorites()
  const userUploadedMusicFeatureOn = amplitudeFeatureFlagsVariables.shouldAllowUserUploadedMusic();
  const videoAudioMusicFeatureOn = amplitudeFeatureFlagsVariables.shouldAllowVideoAudioMusic();

  const commonSxProps = { mx: 5, my: 5, cursor: "pointer" };

  useEffect( () =>
  {
    eventTracker.logEditPostMusicPickerShown( postIdea );
    componentDidMount();
  }, [] );

  async function componentDidMount()
  {
    setLoading( true )

    await fetchMelodieGenreGroupsAndPurposes();
    if ( shouldShowMusicFavorites )
    {
      await refreshFavoriteTrackIdsMap();
    }

    setLoading( false );
  }

  async function fetchMelodieGenreGroupsAndPurposes()
  {
    try
    {
      const melodieGenreGroupsAndPurposesAPI = await musicServices.getMelodieGenresGroupsAndPurposes();
      setGenreGroups( melodieGenreGroupsAndPurposesAPI.genre_groups )
      setPurposes( melodieGenreGroupsAndPurposesAPI.purposes )
    }
    catch (e)
    {
      dispatch( setAlertMessage( errorAlert( "Hmm, something failed on our end. Please try again." ) ) );
    }
  }

  function logMusicChanged( toTrackId: string )
  {
    const fromTrackType = pendingSelection ? pendingSelection.type : selectedTrackType;
    const fromTrackId = pendingSelection ? pendingSelection.id : selectedTrackId;
    const fromTrackIdToReport = musicItemHelper.getTrackIdForReporting( fromTrackType, fromTrackId );

    eventTracker.logEditPostMusicChanged( postIdea, fromTrackIdToReport, toTrackId );
  }

  function handleNoMusicSelected()
  {
    logMusicChanged( NO_MUSIC_TRACK_DISPLAY_NAME );
    setPendingSelection( musicItemHelper.getNoMusicTrack() );
  }

  function handleVideoAudioMusicSelected()
  {
    logMusicChanged( VIDEO_AUDIO_MUSIC_TRACK );
    setPendingSelection( musicItemHelper.getVideoAudioMusicTrack() );
  }

  function handleGenreGroupOrPurposeSelected( genreGroupOrPurpose: MelodieGenreGroupOrPurposeAPI )
  {
    eventTracker.logEditPostMusicMelodieGenreGroupOrPurposeClicked( postIdea, genreGroupOrPurpose );
    setSelectedGenreGroupOrPurpose( genreGroupOrPurpose );
    setPendingSelection( undefined );
  }

  function closeMelodieTracksFullScreenDialog()
  {
    setSelectedGenreGroupOrPurpose( undefined );
    dispatch( clearAndStopAudioPlayerTrack() );
  }

  function closeMyFavoritesFullScreenDialog()
  {
    setShowMyFavoritesDialog( false );
    dispatch( clearAndStopAudioPlayerTrack() );
  }

  function closeUploadedTracksFullScreenDialog()
  {
    setShowUploadedMusicDialog( false );
    dispatch( clearAndStopAudioPlayerTrack() );
  }

  function handleShowMyFavoritesMusicDialog()
  {
    setShowMyFavoritesDialog( true );
    setPendingSelection( undefined );
    dispatch( clearAndStopAudioPlayerTrack() );
    // eventTracker.logMyFavoritesClicked( postIdea );
  }

  function handleShowUploadedMusicDialog()
  {
    setShowUploadedMusicDialog( true );
    setPendingSelection( undefined );
    dispatch( clearAndStopAudioPlayerTrack() );
    eventTracker.logExistingUploadedMusicClicked( postIdea );
  }

  function handleOpenUploadedMusicPicker()
  {
    setPendingSelection( undefined );
    dispatch( clearAndStopAudioPlayerTrack() );
  }

  function handleUploadedMusicSelected( track: CommonTrackAPI )
  {
    logMusicChanged( track.id );
    setPendingSelection( track );
    const musicTrack: MusicTrack = { url: track.url, startTimeInSeconds: track.start_time_in_seconds }
    dispatch( setAudioPlayerTrack( musicTrack ) );
    dispatch( setAudioPlayerPlaying( true ) );
    setShowUploadedMusicDialog( true );
  }

  function updateFavoriteTrackIdsContext( track: CommonTrackAPI, favorited: boolean )
  {
    const favoriteTrackIdsByType = {
      ...favoriteTrackIdsMap
    };

    if ( track.type === TRACK_TYPE_MELODIE )
    {
      favoriteTrackIdsByType[TRACK_TYPE_MELODIE][track.id] = favorited;
    }
    else if ( track.type === TRACK_TYPE_UPLOADED )
    {
      favoriteTrackIdsByType[TRACK_TYPE_UPLOADED][track.id] = favorited;
    }

    setFavoriteTrackIdsMap( favoriteTrackIdsByType );
  }

  async function refreshFavoriteTrackIdsMap()
  {
    try
    {
      const favoriteTrackIdsMappedContextType = await musicServices.getFavoritedIdsMappedByType();
      setFavoriteTrackIdsMap( favoriteTrackIdsMappedContextType );
    }
    catch (e)
    {
      dispatch( setAlertMessage( errorAlert( "An error occurred while refreshing favorites.  Please try again later." ) ) );
    }
  }

  const headerTypographyStyling = { my: 8, textAlign: "center", fontWeight: 500, fontSize: "17px" };

  return (
    <>
      <FavoriteTrackIdsContext.Provider
        value={{
          [TRACK_TYPE_MELODIE]: favoriteTrackIdsMap[TRACK_TYPE_MELODIE],
          [TRACK_TYPE_UPLOADED]: favoriteTrackIdsMap[TRACK_TYPE_UPLOADED],
          updateFavoriteTrackIds: updateFavoriteTrackIdsContext,
          refreshFavoriteTrackIdsMap: refreshFavoriteTrackIdsMap,
        }}>
        <EditMelodieMusicHeader title="Add music" handleClose={props.handleClose}/>
        <MusicPlayer/>
        <FullScreenModalInnerWrap>
          <NoMusicItem postIdea={postIdea}
                       selected={musicItemHelper.isTrackIdSelected( NO_MUSIC_TRACK_ID, TRACK_TYPE_NO_MUSIC, selectedTrackId, selectedTrackType,
                         pendingSelection )}
                       handleTrackSelected={handleNoMusicSelected}
                       iconSize={40}
                       sx={commonSxProps}/>
          {
            videoAudioMusicFeatureOn &&
            <VideoAudioMusicItem postIdea={postIdea}
                                 selected={musicItemHelper.isTrackIdSelected( NO_MUSIC_TRACK_ID, TRACK_TYPE_VIDEO_AUDIO, selectedTrackId,
                                   selectedTrackType,
                                   pendingSelection )}
                                 handleTrackSelected={handleVideoAudioMusicSelected}
                                 iconSize={40}
                                 sx={commonSxProps}/>
          }
          {userUploadedMusicFeatureOn && <UploadMusicItem handleUploadedMusicSelected={handleUploadedMusicSelected}
                                                          setLoading={setLoading}
                                                          sx={commonSxProps}
                                                          onOpenUploadedMusicPicker={handleOpenUploadedMusicPicker}/>}

          {userUploadedMusicFeatureOn && <Divider sx={{ my: 8 }}/>}

          {userUploadedMusicFeatureOn && <Typography variant="h6" sx={headerTypographyStyling}>My Music</Typography>}

          {shouldShowMusicFavorites && <BaseMusicItemView handleOnClick={handleShowMyFavoritesMusicDialog}
                                                          text={"My Favorites"}
                                                          sx={commonSxProps}
                                                          icon={<FavoriteBorderOutlinedIcon sx={{ fontSize: 40 }}/>}/>}
          {userUploadedMusicFeatureOn && <BaseMusicItemView handleOnClick={handleShowUploadedMusicDialog}
                                                            text={"Uploaded Music"}
                                                            sx={commonSxProps}/>}


          <Divider sx={{ my: 8 }}/>

          <Typography variant="h6" sx={headerTypographyStyling}>By Genre</Typography>

          <Grid container spacing={2}>
            {map( genreGroups, ( genre ) => <MelodieGenreGroupOrPurposeItem key={genre.id} melodieGenreGroupOrPurpose={genre}
                                                                            sx={commonSxProps}
                                                                            onClick={handleGenreGroupOrPurposeSelected}/> )}
          </Grid>

          <Divider sx={{ my: 8 }}/>
          <Typography variant="h6" sx={headerTypographyStyling}>By Purpose</Typography>

          <Grid container spacing={2}>
            {map( purposes, ( purpose ) => <MelodieGenreGroupOrPurposeItem key={purpose.id} melodieGenreGroupOrPurpose={purpose}
                                                                           sx={commonSxProps}
                                                                           onClick={handleGenreGroupOrPurposeSelected}/> )}
          </Grid>

          {selectedGenreGroupOrPurpose && <MelodieTracksFullScreenDialog externalStateOpen={!!selectedGenreGroupOrPurpose}
                                                                         handleClose={closeMelodieTracksFullScreenDialog}
                                                                         melodieGenreGroupOrPurpose={selectedGenreGroupOrPurpose}
                                                                         handleCloseAfterApplyingMusic={props.handleCloseAfterApplyingMusic}/>}

          {shouldShowMusicFavorites && showMyFavoriteTracksDialog && <MyFavoritesFullScreenDialog externalStateOpen={showMyFavoriteTracksDialog}
                                                                                                  pendingSelection={pendingSelection}
                                                                                                  setPendingSelection={setPendingSelection}
                                                                                                  handleCloseAfterApplyingMusic={props.handleCloseAfterApplyingMusic}
                                                                                                  handleClose={closeMyFavoritesFullScreenDialog}/>}

          {userUploadedMusicFeatureOn && showUploadedMusicDialog && <UploadedTracksFullScreenDialog externalStateOpen={showUploadedMusicDialog}
                                                                                                    pendingSelection={pendingSelection}
                                                                                                    setPendingSelection={setPendingSelection}
                                                                                                    handleCloseAfterApplyingMusic={props.handleCloseAfterApplyingMusic}
                                                                                                    handleClose={closeUploadedTracksFullScreenDialog}/>}

        </FullScreenModalInnerWrap>

        <AudioDrawer pendingSelection={pendingSelection} setPendingSelection={setPendingSelection}
                     closeAfterApplying={props.handleCloseAfterApplyingMusic}/>
        {loading && <ProgressOverlay/>}
      </FavoriteTrackIdsContext.Provider>
    </>
  )
}

export const EditMelodieMusicFullScreenDialog = withFullScreenDialog( EditMelodieMusicTabContent )
