import { Stack } from "@mui/material";
import CheckCircleTaskIcon from '@mui/icons-material/TaskAlt';
import React, { ReactNode } from "react";
import { PaywallBenefitText } from "../payment/paywallBenefitText";

interface PaywallBenefitRowProps
{
  children: ReactNode;
}

export function PaywallBenefitRow( props: PaywallBenefitRowProps )
{

  return (
    <Stack direction={"row"} alignItems={"center"} sx={{ my: 2 }}>
      <CheckCircleTaskIcon color={"secondary"} sx={{ mr: 7, fontSize: '20px' }}/>
      <PaywallBenefitText>{props.children}</PaywallBenefitText>
    </Stack>
  )

}