import { Box, Stack } from "@mui/material";
import { PaywallBenefitRow } from "./paywallBenefitRow";
import { PAYWALL_GRADIENT } from "../constants";

export function PaywallBenefitsSection()
{
  return <Box sx={{ background: PAYWALL_GRADIENT, borderRadius: "10px", width: "100%" }}>
    <Stack sx={{ mx: "auto", py: "20px", width: "85%" }} spacing={5}>
      <PaywallBenefitRow>
        Unlimited, eye-catching posts
      </PaywallBenefitRow>
      <PaywallBenefitRow>
        Custom weekly plan
      </PaywallBenefitRow>
      <PaywallBenefitRow>
        Post to multiple platforms
      </PaywallBenefitRow>
      <PaywallBenefitRow>
        Royalty-free music, fonts & media
      </PaywallBenefitRow>
    </Stack>
  </Box>;
}