import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { FullPageMobileHeader } from "../layout/fullPageMobileHeader";
import { BottomNavigation, Box, Button, Paper, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { FullPageMobileContent } from "../layout/fullPageMobileContent";
import { CustomTabPanel } from "./customTabPanel";
import { FullPostPreview } from "../postIdea/FullPostPreview";
import { EditTextTabContent } from "./editTextTabContent";
import { EditMediaTabContent } from "./editMediaTabContent";
import { FullPageMobileFooter } from "../layout/fullPageMobileFooter";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import StarIcon from '@mui/icons-material/Star';
import TextFieldsIcon from "@mui/icons-material/TextFields";
import FilterIcon from "@mui/icons-material/Filter";
import MusicIcon from "@mui/icons-material/MusicNote";
import PaletteIcon from "@mui/icons-material/Palette";
import FontIcon from "@mui/icons-material/FontDownload";
import {
  BOTTOM_NAV_ID_BRAND_TAB,
  BOTTOM_NAV_ID_COLORS_TAB,
  BOTTOM_NAV_ID_FONTS_TAB,
  BOTTOM_NAV_ID_MEDIA_TAB,
  BOTTOM_NAV_ID_MUSIC_TAB,
  BOTTOM_NAV_ID_PREVIEW_TAB,
  BOTTOM_NAV_ID_TEXT_TAB,
  EditTab,
  TAB_MINIMUM_WIDTH
} from "../constants";
import { BottomNavigateActionWithTooltipWhenDisabled } from "../ui/bottomNavigateActionWithTooltipWhenDisabled";
import { eventTracker } from "../../helpers/eventTracker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShareIcon from "@mui/icons-material/Reply";
import { refreshExpiredEpidemicMusicTrack } from "../postIdea/postDataHelper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getPostForPostIdea } from "../postIdea/postsSlice";
import { CustomDrawerPanel } from "./customDrawerPanel";
import { includes, isEqual, map } from "lodash";
import { PostIdeaContext } from "../context/postIdeaContext";
import { getHasFinishedPosts, shouldBlockForUpsell } from "../user/userSlice";
import {
  clearEditorAnimationCompleteTimestamp,
  clearRequestEditorPauseAudioTimestamp,
  clearRequestEditorReloadWithAudioTimestamp,
  setRequestEditorStopAnimationTimestamp,
  setUpsellShown
} from "../ui/uiSlice";
import { UPSELL_SOURCE_SHARE_POST } from "../../helpers/trackingConstants";
import { authenticationServices } from "../../services/authentication.services";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { postIdeaServices } from "../../services/postIdeaServices";
import { hasBrandStyle } from "../business/businessSlice";
import { isHolidayPostIdea, isStaticPostIdea, needsRecording, postIdeaHelper } from "../postIdea/postIdeaHelper";
import { ConfirmDialog } from "../ui/confirmDialog";
import { getHasClickedOnPostPreview, setHasClickedOnPostPreview } from "../tracking/trackingSlice";

export interface EditPostViewProps
{
  editable?: boolean;
  switchToShareView: () => void;
  handleClose?: () => void;
}

export function EditPostView( props: EditPostViewProps )
{
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = React.useState( EditTab.None );
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const post = useSelector( ( state: RootState ) => getPostForPostIdea( state, postIdea.id ), isEqual );
  const shouldShowUpsellOnShare = useSelector( ( state: RootState ) => shouldBlockForUpsell( state ) );
  const [hasPendingTextChanges, setHasPendingTextChanges] = useState<boolean>( false );
  const allowPresetVariationChanging = props.editable && selectedTab === EditTab.None && !isHolidayPostIdea( postIdea );
  const [hasVariants, setHasVariants] = useState( false );
  const shouldShowBrandStyle = useSelector( ( state: RootState ) => hasBrandStyle( state ) );
  const showLayouts = !isHolidayPostIdea( postIdea );
  const isAnimatedPost = !isStaticPostIdea( postIdea );
  const showHolidayLayouts = isHolidayPostIdea( postIdea );
  let drawerTabs = [EditTab.Layout, EditTab.HolidayLayouts, EditTab.Colors, EditTab.Fonts, EditTab.Brand]
  const showNextAndPreviousButtons = hasVariants && allowPresetVariationChanging;

  const scrollContentClass = useRef( "scrollHintAnimation" );

  const scrollContainerDivRef = useRef<HTMLDivElement>();
  const scrollContentDivRef = useRef<HTMLDivElement>();
  const [showShareWarningDialog, setShowShareWarningDialog] = useState<boolean>( false );
  const hasUserFinishedPost = useSelector( ( state: RootState ) => getHasFinishedPosts( state ) );
  const hasUserClickedOnPostPreview = useSelector( ( state: RootState ) => getHasClickedOnPostPreview( state ) );

  if ( isAnimatedPost )
  {
    drawerTabs = [EditTab.Music, ...drawerTabs];
  }

  useEffect( () =>
  {
    const current = scrollContainerDivRef.current;

    const toolbarRequiresScrolling = current && current.scrollWidth > current.clientWidth;
    if ( toolbarRequiresScrolling )
    {
      current.addEventListener( "scroll", removeScrollHintAnimations, false )
    }
    else
    {
      removeScrollHintAnimations();
    }
    return () =>
    {
      dispatch( clearRequestEditorReloadWithAudioTimestamp() );
      dispatch( clearRequestEditorPauseAudioTimestamp() );
      dispatch( clearEditorAnimationCompleteTimestamp() );
      dispatch( setRequestEditorStopAnimationTimestamp( undefined ) );
    }
  }, [] );

  useEffect( () =>
  {
    if ( selectedTab !== EditTab.None )
    {
      removeScrollHintAnimations();
    }
  }, [selectedTab] );

  const removeScrollHintAnimations = () =>
  {
    if ( scrollContentClass.current )
    {
      scrollContentClass.current = "";
    }

    scrollContentDivRef.current?.classList.remove( "scrollHintAnimation" );

    const scrollContainer = scrollContainerDivRef.current;
    if ( scrollContainer )
    {
      scrollContainer.removeEventListener( "scroll", removeScrollHintAnimations )
    }
  }

  async function onMount()
  {
    if ( selectedTab === EditTab.None )
    {
      if ( hasUserFinishedPost || hasUserClickedOnPostPreview )
      {
        eventTracker.logPostPreviewClicked( postIdea );
      }
      else
      {
        eventTracker.logFirstPostPreviewClicked( postIdea );
        dispatch( setHasClickedOnPostPreview( true ) );
      }

      eventTracker.logEditPostPreviewShown( postIdea );
      authenticationServices.periodicallyUpdateUserLastSeen();

      const response = await postIdeaServices.getEditPrecheck( postIdea.id )
      setHasVariants( response.has_variants );
    }
    try
    {
      await refreshExpiredEpidemicMusicTrack( post );
    }
    catch (error)
    {
    }
  }

  useEffect( () =>
  {
    onMount();
  }, [] );

  const handleChange = ( event: React.SyntheticEvent, newValue: number ) =>
  {
    if ( newValue === EditTab.None )
    {
      eventTracker.logEditPostPreviewShown( postIdea );
    }
    setSelectedTab( newValue );
  };

  const valueToTitleMap = ( value: EditTab ) =>
  {
    switch ( value )
    {
      case EditTab.None:
        return "Edit post";
      case EditTab.Layout:
        return "Edit layout";
      case EditTab.Text:
        return "Edit text";
      case EditTab.Media:
        return "Edit media";
      case EditTab.Music:
        return "Edit music";
      case EditTab.Colors:
        return "Edit colors";
      case EditTab.Fonts:
        return "Edit fonts";
      default:
        return "";
    }
  }

  const handleCloseButtonClicked = () =>
  {
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  const handleBackToPreviewClicked = () =>
  {
    setSelectedTab( EditTab.None );
  }

  function handleLayoutTabClicked()
  {
    eventTracker.logEditPostLayoutMenuClicked( postIdea )
  }

  function handleTemplateTabClicked()
  {
    eventTracker.logEditPostHolidayLayoutMenuClicked( postIdea )
  }

  function handleTextTabClicked()
  {
    eventTracker.logEditPostTextMenuClicked( postIdea )
  }

  function handleMediaTabClicked()
  {
    eventTracker.logEditPostMediaMenuClicked( postIdea )
  }

  function handleMusicTabClicked()
  {
    eventTracker.logEditPostMusicMenuClicked( postIdea )
  }

  function handleBrandTabClicked()
  {
    eventTracker.logEditPostBrandMenuClicked( postIdea )
  }

  function handleColorsTabClicked()
  {
    eventTracker.logEditPostColorsMenuClicked( postIdea )
  }

  function handleFontsTabClicked()
  {
    eventTracker.logEditPostFontsMenuClicked( postIdea )
  }

  function handleShareTabClicked()
  {
    const results = postIdeaHelper.checkForShareWarnings( postIdea );
    if ( !results.isValid )
    {
      setShowShareWarningDialog( true );
    }
    else
    {
      setShowShareWarningDialog( false );
      actuallyExecuteShareButtonStuff();
    }
  }

  function handlePendingTextChanges( pendingChanges: boolean )
  {
    setHasPendingTextChanges( pendingChanges );
  }

  function actuallyExecuteShareButtonStuff()
  {
    eventTracker.logEditPostShareClicked( postIdea );

    if ( shouldShowUpsellOnShare && needsRecording( postIdea ) )
    {
      dispatch( setUpsellShown( true ) );
      eventTracker.logUpsellShown( UPSELL_SOURCE_SHARE_POST )
    }
    else
    {
      props.switchToShareView();
    }
  }

  function handleModalTabClosed()
  {
    setSelectedTab( EditTab.None );
  }

  function isDrawerTabSelected()
  {
    return includes( drawerTabs, selectedTab );
  }

  function getVisibility()
  {
    return isDrawerTabSelected() ? "hidden" : "visible";
  }

  function getLeftHeaderButton( value: number )
  {
    if ( value === EditTab.None )
    {
      return <IconButton onClick={handleCloseButtonClicked}>
        <ClearIcon/>
      </IconButton>;
    }
    else
    {
      return <IconButton onClick={handleBackToPreviewClicked} sx={{ visibility: getVisibility() }}>
        <ArrowBackIcon/>
      </IconButton>;
    }
  }

  function getShareButtonText()
  {
    return hasPendingTextChanges ? "Saving..." : "Share";
  }

  function getShareWarningsDialogContent()
  {
    const results = postIdeaHelper.checkForShareWarnings( postIdea );
    return <Stack>
      {map( results.warnings, ( message ) => <Typography key={message} sx={{ px: "24px", pb: "16px" }}>{message}</Typography> )}
    </Stack>;
  }

  function handleForceShareWithWarnings()
  {
    actuallyExecuteShareButtonStuff();
  }

  function handleCancelShareWarningsDialog()
  {
    setShowShareWarningDialog( false );
  }

  return (
    <>
      <FullPageMobileHeader>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="flex-start" spacing={2}>
            {getLeftHeaderButton( selectedTab )}
            {props.editable && <Typography variant="h6" alignSelf="center" component="div">{valueToTitleMap( selectedTab )}</Typography>}
          </Stack>
          <Button endIcon={<ShareIcon sx={{ transform: "scaleX(-1)" }}/>} onClick={handleShareTabClicked} variant="contained"
                  disabled={hasPendingTextChanges}
                  sx={{ marginBottom: 2, visibility: getVisibility() }}>{getShareButtonText()}</Button>

        </Stack>
      </FullPageMobileHeader>

      <FullPageMobileContent>
        <CustomTabPanel value={selectedTab} index={EditTab.None} sx={{ px: 0, py: 0, display: "flex" }}
                        additionalValidIndices={drawerTabs}>
          <FullPostPreview showVariantSwitching={showNextAndPreviousButtons}/>
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={EditTab.Text}>
          <EditTextTabContent notifyPendingTextChanges={handlePendingTextChanges}/>
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={EditTab.Media}>
          <EditMediaTabContent/>
        </CustomTabPanel>

        {isAnimatedPost && <CustomDrawerPanel selectedTab={selectedTab}
                                              tabId={EditTab.Music}
                                              handleDrawerClose={handleModalTabClosed}/>}
        {showLayouts && <CustomDrawerPanel selectedTab={selectedTab}
                                           tabId={EditTab.Layout}
                                           handleDrawerClose={handleModalTabClosed}/>}
        {showHolidayLayouts && <CustomDrawerPanel selectedTab={selectedTab}
                                                  tabId={EditTab.HolidayLayouts}
                                                  handleDrawerClose={handleModalTabClosed}/>}
        {showLayouts && <CustomDrawerPanel selectedTab={selectedTab}
                                           tabId={EditTab.Colors}
                                           handleDrawerClose={handleModalTabClosed}/>}
        {showLayouts && <CustomDrawerPanel selectedTab={selectedTab}
                                           tabId={EditTab.Fonts}
                                           handleDrawerClose={handleModalTabClosed}/>}
        {showLayouts && isAnimatedPost && <CustomDrawerPanel selectedTab={selectedTab}
                                                             tabId={EditTab.Brand}
                                                             handleDrawerClose={handleModalTabClosed}/>}
      </FullPageMobileContent>

      <FullPageMobileFooter>
        <Paper elevation={3}>
          <Box ref={scrollContainerDivRef} sx={{ overflow: "auto" }}>
            {/*@ts-ignore*/
              <BottomNavigation
                ref={scrollContentDivRef}
                showLabels
                value={selectedTab}
                onChange={handleChange}
                className={scrollContentClass.current}
                sx={{
                  "& .MuiBottomNavigationAction-root": { minWidth: TAB_MINIMUM_WIDTH },
                  justifyContent: { xs: "space-between", md: "center" }
                }}
              >
                {showLayouts && <BottomNavigateActionWithTooltipWhenDisabled id={BOTTOM_NAV_ID_PREVIEW_TAB}
                                                                             disabled={!props.editable}
                                                                             label="Layout"
                                                                             icon={<SmartphoneIcon/>}
                                                                             value={EditTab.Layout}
                                                                             onClick={handleLayoutTabClicked}/>}
                {showHolidayLayouts && <BottomNavigateActionWithTooltipWhenDisabled id={BOTTOM_NAV_ID_PREVIEW_TAB}
                                                                                    disabled={!props.editable}
                                                                                    label="Template"
                                                                                    icon={<StarIcon/>}
                                                                                    value={EditTab.HolidayLayouts}
                                                                                    onClick={handleTemplateTabClicked}/>}
                {showLayouts && <BottomNavigateActionWithTooltipWhenDisabled id={BOTTOM_NAV_ID_COLORS_TAB}
                                                                             disabled={!props.editable}
                                                                             label="Colors"
                                                                             icon={<PaletteIcon/>}
                                                                             value={EditTab.Colors}
                                                                             onClick={handleColorsTabClicked}/>}
                {showLayouts && <BottomNavigateActionWithTooltipWhenDisabled id={BOTTOM_NAV_ID_FONTS_TAB}
                                                                             disabled={!props.editable}
                                                                             label="Fonts"
                                                                             icon={<FontIcon/>}
                                                                             value={EditTab.Fonts}
                                                                             onClick={handleFontsTabClicked}/>}
                <BottomNavigateActionWithTooltipWhenDisabled id={BOTTOM_NAV_ID_TEXT_TAB}
                                                             disabled={!props.editable}
                                                             label="Text"
                                                             icon={<TextFieldsIcon/>}
                                                             value={EditTab.Text}
                                                             onClick={handleTextTabClicked}/>
                <BottomNavigateActionWithTooltipWhenDisabled id={BOTTOM_NAV_ID_MEDIA_TAB}
                                                             disabled={!props.editable}
                                                             label="Media"
                                                             icon={<FilterIcon/>}
                                                             value={EditTab.Media}
                                                             onClick={handleMediaTabClicked}/>
                {showLayouts && isAnimatedPost && <BottomNavigateActionWithTooltipWhenDisabled id={BOTTOM_NAV_ID_MUSIC_TAB}
                                                                                               disabled={!props.editable}
                                                                                               label="Music"
                                                                                               icon={<MusicIcon/>}
                                                                                               value={EditTab.Music}
                                                                                               onClick={handleMusicTabClicked}/>}
                {showLayouts && shouldShowBrandStyle && isAnimatedPost && <BottomNavigateActionWithTooltipWhenDisabled id={BOTTOM_NAV_ID_BRAND_TAB}
                                                                                                                       disabled={!props.editable}
                                                                                                                       label="Brand"
                                                                                                                       icon={<ContactPageIcon/>}
                                                                                                                       value={EditTab.Brand}
                                                                                                                       onClick={handleBrandTabClicked}/>}

              </BottomNavigation>
            }
          </Box>
        </Paper>
      </FullPageMobileFooter>
      <ConfirmDialog title={"Are you sure?"}
                     dialogContent={getShareWarningsDialogContent()}
                     open={showShareWarningDialog}
                     confirmText={"Share Anyway"}
                     cancelText={"Go Back"}
                     onConfirm={handleForceShareWithWarnings}
                     onCancel={handleCancelShareWarningsDialog}/>

    </>
  );
}

