import * as React from 'react';
import { useEffect, useState } from 'react';
import "./editTextTabContent.scss";
import { PostIdeaContext } from "../context/postIdeaContext";
import { FullScreenEditor } from "./editAndSharePostFlow";
import { useSelector } from "react-redux";
import { RootState, store } from "../../app/store";
import { getPostIdea } from "../postIdea/postIdeaSlice";
import { ShareBatchAPI } from "../../services/postIdeaServices";
import { postIdeaHelper } from "../postIdea/postIdeaHelper";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { errorReporter } from "../error/errorReporter";

export interface PostIdeaEditorProps
{
  postIdeaId: string;
  shareBatch?: ShareBatchAPI;
  initialScheduleForDate: Date;
  handleClose: () => void;
  handlePostIdeaReadyToEdit: () => void;
  handleErrorLoadingPostIdeaData: () => void;
}

export function PostIdeaEditor( props: PostIdeaEditorProps )
{
  const [loading, setLoading] = useState<boolean>( false );
  const postIdeaFromStore = useSelector( ( state: RootState ) => getPostIdea( state, props.postIdeaId ) );
  const [loadedPostIdeaDataFromServer, setLoadedPostIdeaDataFromServer] = React.useState( false );
  const isAbleToBeginEditing = !!postIdeaFromStore && !loading && loadedPostIdeaDataFromServer;
  const [showEditDialog, setShowEditDialog] = React.useState( isAbleToBeginEditing );

  useEffect( () =>
  {
    onMount()
  }, [] );

  async function onMount()
  {
    if ( !isAbleToBeginEditing && !loadedPostIdeaDataFromServer )
    {
      setLoading( true );
      try
      {
        await postIdeaHelper.updateStoreWithLatestPostIdeaAndPost( props.postIdeaId );
        setLoadedPostIdeaDataFromServer( true );
        setShowEditDialog( true );
        props.handlePostIdeaReadyToEdit();
      }
      catch (error)
      {
        store.dispatch( setAlertMessage( errorAlert( "Could not load post. Please try again later.", 'top' ) ) );
        errorReporter.reportErrorToSentry( error );

        setLoadedPostIdeaDataFromServer( false );
        props.handleErrorLoadingPostIdeaData();
      }
      finally
      {
        setLoading( false );
      }
    }
  }

  function handleClose()
  {
    props.handleClose();
  }

  if ( isAbleToBeginEditing )
  {
    return (
      <PostIdeaContext.Provider value={{ postIdea: postIdeaFromStore }}>
        <FullScreenEditor
          editable={true}
          externalStateOpen={showEditDialog}
          handleClose={handleClose}
          shouldSuppressFeedback={true}
          initialScheduleForDate={props.initialScheduleForDate}
          shareBatch={props.shareBatch}/>
      </PostIdeaContext.Provider>
    );
  }
  return null;
}

