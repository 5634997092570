import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { MediaItem } from "../mediaList/mediaItem";
import { MediaAssetSource, STOCK_MEDIA_SOURCE_MEDIA_ASSET } from "../constants";
import CircularProgress from "@mui/material/CircularProgress";
import { ExternalMediaData } from "../stock_media/stockMediaSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import { ModalProps } from "@mui/material/Modal";

export interface StockMediaConfirmationDialogProps
{
  id: string;
  keepMounted: boolean;
  value: ExternalMediaData | undefined;
  open: boolean;
  onClose: ( value?: ExternalMediaData, mediaSource?: MediaAssetSource ) => void;
  mediaUrl: string;
  stockMediaLoading: boolean;
  onBackdropClick: ModalProps['onBackdropClick']
}

function StockMediaConfirmationDialog( props: StockMediaConfirmationDialogProps )
{
  const { onClose, value: valueProp, open, mediaUrl, stockMediaLoading, ...other } = props;
  const radioGroupRef = React.useRef<HTMLElement>( null );

  const handleEntering = () =>
  {
    if ( radioGroupRef.current != null )
    {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () =>
  {
    onClose();
  };

  const handleOk = () =>
  {
    onClose( valueProp, STOCK_MEDIA_SOURCE_MEDIA_ASSET );
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': { width: '90%', maxHeight: '100vh' },
        textAlign: "center",
        'img, video': {
          margin: "0 auto",
          maxWidth: "300px",
          maxHeight: "calc(100svh - 200px)",
        }
      }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Add this media?</DialogTitle>
      <DialogContent sx={{ minHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {mediaUrl && <MediaItem url={mediaUrl} showControls={true}/>}
        {!mediaUrl && <CircularProgress/>}
      </DialogContent>
      <DialogActions>
        <Button autoFocus disabled={stockMediaLoading} onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton loading={stockMediaLoading}
                       variant="contained"
                       color="negative"
                       onClick={handleOk}>Ok</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default StockMediaConfirmationDialog;
