import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, SxProps, Typography } from "@mui/material";
import waveform from "../../../assets/waveform.jpg";
import { merge, round } from "lodash";
import { useDraggable } from "react-use-draggable-scroll";
import { logToConsole } from "../../utils/devLoggingHelper";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export interface WaveformSliderProps
{
  trackLengthMs: number;
  designDurationMs: number;
  initialStartTimeMs: number;
  handleStartTimeChange( newStartTime: number ): void;
  sx?: SxProps;
}

export function WaveformSlider( props: WaveformSliderProps )
{
  const designDurationWindowHeight = 100;
  const waveformHeight = 0.6 * designDurationWindowHeight;
  const designDurationWindowWidth = 200;

  const pxPerMs = designDurationWindowWidth / props.designDurationMs;
  const backgroundSize = calculateWaveformBackgroundSizeCssProperty( props, pxPerMs, waveformHeight );

  const [musicDataLeftPaddingWidth, setMusicDataLeftPaddingWidth] = useState<number>( 0 );
  const [musicDataRightPaddingWidth, setMusicDataRightPaddingWidth] = useState<number>( 0 );
  const [musicDataBarWidth, setMusicDataBarWidth] = useState<number>( 0 );
  const [newStartTimeMsRepresentedByScrollLeft, setNewStartTimeMsRepresentedByScrollLeft] = useState<number>( props.initialStartTimeMs );
  const [initialScrollLeft, setInitialScrollLeft] = useState<number>( 0 );

  const scrollContainerRef = React.useRef<HTMLDivElement>( null ) as React.MutableRefObject<HTMLDivElement>;
  const { events } = useDraggable( scrollContainerRef );
  const browserWindowDimensions = useWindowDimensions();

  const windowHeightPx = designDurationWindowHeight + "px";
  const waveformHeightPx = waveformHeight + "px";
  const windowWidthPx = designDurationWindowWidth + "px";

  useEffect( () =>
  {
    setupScalingAndUiElements( newStartTimeMsRepresentedByScrollLeft )
  }, [browserWindowDimensions, newStartTimeMsRepresentedByScrollLeft] );

  useEffect( () =>
  {
    const scrollContainer = scrollContainerRef.current;
    if ( scrollContainer )
    {
      scrollContainer.scrollLeft = initialScrollLeft;
    }
  }, [initialScrollLeft] );

  function setupScalingAndUiElements( startTimeMs: number )
  {
    const scrollContainer = scrollContainerRef.current;
    if ( scrollContainer )
    {
      const halfWindowWidth = designDurationWindowWidth / 2;
      const halfScrollContainerWidth = scrollContainer.clientWidth / 2;
      const newLeftPaddingWidth = halfScrollContainerWidth - halfWindowWidth;
      const newRightPaddingWidth = halfScrollContainerWidth + halfWindowWidth;
      const scrollLeft = startTimeMs * pxPerMs;
      setMusicDataLeftPaddingWidth( newLeftPaddingWidth );
      setMusicDataRightPaddingWidth( newRightPaddingWidth );
      setInitialScrollLeft( scrollLeft );

      setMusicDataBarWidth( props.trackLengthMs * pxPerMs );
    }
  }

  function handleScroll( event )
  {
    const pixelScrollLeft = event.target.scrollLeft;
    const newStartTimeMs = round( pixelScrollLeft / pxPerMs );

    if ( isScrollLeftLessThanPixelFromInitialValue( pixelScrollLeft ) )
    {
      logToConsole( "WaveformSlider handleScroll: isScrollLeftLessThanPixelFromInitialValue() true, keeping initial value" );
      return;
    }
    setNewStartTimeMsRepresentedByScrollLeft( newStartTimeMs );
    props.handleStartTimeChange( newStartTimeMs );
  }

  function isScrollLeftLessThanPixelFromInitialValue( pixelScrollLeft: number )
  {
    const preciseScrollLeft = props.initialStartTimeMs * pxPerMs;
    const isWithinPixelRange = preciseScrollLeft > pixelScrollLeft && preciseScrollLeft < pixelScrollLeft + 1;
    return isWithinPixelRange;
  }

  function calculateWaveformBackgroundSizeCssProperty( props: WaveformSliderProps, pxPerMs: number, waveformHeight: number )
  {
    const trackLengthInPixels = props.trackLengthMs * pxPerMs;
    const waveformBackgroundImageAspectRatio = 2884 / 389.0;
    const renderedWaveformAspectRatio = trackLengthInPixels / waveformHeight;
    return renderedWaveformAspectRatio >= waveformBackgroundImageAspectRatio ? "contain" : "cover";
  }

  return (
    <Box sx={merge( { position: "relative", mb: 4 }, props.sx )}>
      <Box ref={scrollContainerRef} {...events} sx={{
        mx: "auto",
        position: "relative",
        width: "100vw",
        overflowX: "scroll",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
        overflowY: "hidden",
        height: windowHeightPx
      }}
           onScroll={handleScroll}>
        <Box sx={{
          display: "inline-block",
          position: "absolute",
          left: "0px",
          top: (designDurationWindowHeight - waveformHeight) / 2,
          bottom: 0,
          width: musicDataLeftPaddingWidth + musicDataBarWidth + musicDataRightPaddingWidth
        }}>
          <Box sx={{ display: "inline-block", width: musicDataLeftPaddingWidth + "px", height: waveformHeightPx }}/>
          <Box sx={{
            display: "inline-block",
            width: musicDataBarWidth + "px",
            height: waveformHeightPx,
            background: "url(" + waveform + ")",
            backgroundRepeat: "repeat-x",
            backgroundSize: backgroundSize,
            backgroundPosition: "left"
          }}></Box>
          <Box sx={{ display: "inline-block", width: musicDataRightPaddingWidth + "px", height: waveformHeightPx }}/>
        </Box>
      </Box>
      <Box sx={{
        position: "absolute",
        border: "4px solid",
        borderColor: ( theme ) => theme.palette.primary.main,
        borderRadius: "10px",
        backgroundColor: "rgba(137,137,137,.3)",
        width: windowWidthPx,
        height: windowHeightPx,
        left: `calc(50% - ${designDurationWindowWidth / 2}px)`,
        top: 0,
        pointerEvents: "none"
      }}>
        <Box sx={{width: "100px", position: "absolute", left: 0, top: -25, textAlign: "left", color: "gray"}}><Typography variant="caption">start {round(newStartTimeMsRepresentedByScrollLeft / 1000.0, 2)}s</Typography></Box>
        <Box sx={{width: "100px", position: "absolute", left: "calc(50% - 50px)", bottom: -25, color: "gray"}}><Typography variant="caption">length {round(props.designDurationMs / 1000.0, 2)}s</Typography></Box>
      </Box>
    </Box>
  );
}

