import { Stack, Typography } from "@mui/material";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { ReactNode } from "react";

interface PaywallBenefitPillProps
{
  children: ReactNode;
}

export function PaywallBenefitPill( props: PaywallBenefitPillProps )
{

  return (
    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{ py: "4px", px: "7px", borderRadius: "18px", backgroundColor: "#E6F4EB", width: "170px"}}>
      <LocalOfferOutlinedIcon sx={{ mr: 2, color: "#0D743A", width: "12px", height: "12px" }}/>
      <Typography color={"#0D743A"} sx={{fontSize: "12px", fontWeight: 500}}>{props.children}</Typography>
    </Stack>
  )

}