import { ShareBatchAPI } from "../../services/postIdeaServices";
import { DateUtils } from "../utils/dateUtils";
import { addMinutes, roundToNearestMinutes } from "date-fns";
import { SHARING_STATUS_CANCELED, SHARING_STATUS_DRAFT, SHARING_STATUS_SCHEDULED, SHARING_STATUS_SENDING } from "../../services/business.services";

export function isShareBatchSending( shareBatch: ShareBatchAPI )
{
  return shareBatch.status === SHARING_STATUS_SENDING;
}

export function isShareBatchCanceled( shareBatch: ShareBatchAPI )
{
  return shareBatch.status === SHARING_STATUS_CANCELED;
}

export function isShareBatchDraft( shareBatch: ShareBatchAPI )
{
  return shareBatch.status === SHARING_STATUS_DRAFT;
}

export function isShareBatchScheduled( shareBatch: ShareBatchAPI )
{
  return shareBatch.status === SHARING_STATUS_SCHEDULED;
}

export function getScheduleForDate( shareBatch: ShareBatchAPI )
{
  return !!shareBatch.scheduled_for ? new Date( shareBatch.scheduled_for ) : undefined;
}


export function getDraftedAtDate( shareBatch: ShareBatchAPI )
{
  return !!shareBatch.drafted_at ? new Date( shareBatch.drafted_at ) : undefined;
}

export function getEffectiveCompletedDisplayDate( shareBatch: ShareBatchAPI )
{
  return new Date( shareBatch.completed_at ||
                   shareBatch.direct_shared_at ||
                   shareBatch.scheduled_for ||
                   shareBatch.first_downloaded_at ||
                   shareBatch.created_at );
}

export function getInitialScheduleTimeForDraft( date?: Date ): Date
{
  let dateToUse = date || new Date();
  const dateAt9AM = new Date( dateToUse ).setHours( 9, 0, 0, 0 );

  return getValidScheduledDate( new Date( dateAt9AM ) );
}

export function getInitialScheduledTimeForCompletedPost( shareBatch: ShareBatchAPI )
{
  const scheduledForDate = !!shareBatch.completed_at ? new Date( shareBatch.completed_at ) : new Date();
  return getValidScheduledDate( scheduledForDate );
}

export function roundToNextFifteenMinuteIncrement( time: Date )
{
  let scheduledTime = roundToNearestMinutes( time, { nearestTo: 15, roundingMethod: "ceil" } );

  if ( DateUtils.isWithinFiveMinutes( scheduledTime, time ) )
  {
    scheduledTime = addMinutes( scheduledTime, 15 );
  }
  return scheduledTime;
}

export function getValidScheduledDate( date: Date )
{
  const roundedDate = roundToNearestMinutes( date, { nearestTo: 15 } );
  const closestScheduleDateTime = roundToNextFifteenMinuteIncrement( new Date() );

  if ( closestScheduleDateTime > roundedDate )
  {
    return closestScheduleDateTime;
  }
  else
  {
    return roundedDate;
  }
}

export function getRescheduleDate( shareBatch: ShareBatchAPI )
{
  const scheduledForDate = !!shareBatch.scheduled_for ? new Date( shareBatch.scheduled_for ) : new Date();
  return getValidScheduledDate( scheduledForDate );
}
