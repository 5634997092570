import { getToApi, makeAuthenticatedRequest, postJsonToApi } from "./requestManager";
import { ENV_CONFIG } from "../constants";
import {
  CommonTrackAPI,
  MelodieEntityType,
  MelodieGenreGroupsAndPurposesAPI,
  MelodieTracksAPI,
  MusicAPI,
  setTracks
} from "../features/music/musicSlice";
import { store } from "../app/store";
import { clearProgressState, setProgressState } from "../features/ui/uiSlice";
import { RequestPresignedUploadAPIResponse, s3UploadServices } from "./s3Upload.services";
import { businessServices } from "./business.services";
import * as mmb from 'music-metadata';
import { getBusinessSlug } from "../features/business/businessSlice";
import { reduce } from "lodash";
import { TRACK_TYPE_MELODIE, TRACK_TYPE_UPLOADED } from "../features/constants";
import { FavoriteTrackIdsMappedContextType } from "../features/context/favoriteTrackIdsContext";

const REQUEST_PRESIGNED_UPLOAD_ENDPOINT = "request_presigned_upload";
const MUSIC_BASE_PATH = "uploaded_musics";
const MUSIC_FAVORITES_PATH = "music_favorites"
const FAVORITE_MUSIC_TRACK_PATH = "favorite_track";
const FAVORITE_MUSIC_LIST_PATH = "list";
const FAVORITE_MUSIC_TRACKS_BY_TYPE_PATH = "fetch_all_favorites_by_type";

export const musicServices = {
  favoriteMusicTrack,
  getEpidemicSoundCollection,
  getEpidemicSoundTrack,
  getMelodieGenresGroupsAndPurposes,
  getMelodieTracks,
  uploadMusic,
  getMyFavorites,
  getFavoritedIdsMappedByType,
}

export interface EpidemicSoundTrackDownloadAPI
{
  epidemic_id: string;
  url: string;
  expires: string;
  start_time_in_seconds: number;
}

export interface MusicFileData
{
  file: File;
}

export interface CommonTracksCollectionAPI
{
  tracks: CommonTrackAPI[]
  next_page?: number
}

export interface FavoritedTrackAPI
{
  track: CommonTrackAPI,
  favorited: boolean,
}

export interface FavoriteTrackIdsByTypeAPI
{
  melodie_track_ids: string[],
  uploaded_track_ids: string[],
}

async function getMelodieGenresGroupsAndPurposes(): Promise<MelodieGenreGroupsAndPurposesAPI>
{

  const endPoint = "music/melodie_genre_groups_and_purposes";

  return getToApi<MelodieGenreGroupsAndPurposesAPI>( endPoint, {} );
}

async function getMelodieTracks( type: MelodieEntityType, name: string, page?: number ): Promise<MelodieTracksAPI>
{
  const endPoint = "music/melodie_tracks";

  const params = {
    name,
    type,
    page
  }

  return postJsonToApi<MelodieTracksAPI>( endPoint, {}, params );
}

async function getEpidemicSoundCollection(): Promise<MusicAPI>
{
  const baseUrl = ENV_CONFIG.baseUrl;
  const endPoint = "music/epidemic_sound_collection";

  return makeAuthenticatedRequest<MusicAPI>( { baseUrl, endPoint, methodType: "GET" } ).then(
    ( data ) =>
    {
      store.dispatch( setTracks( data ) );
      return data;
    }
  );
}

async function getEpidemicSoundTrack( epidemic_id: string ): Promise<EpidemicSoundTrackDownloadAPI>
{
  const endPoint = "music/epidemic_sound_track_download?";

  return getToApi<EpidemicSoundTrackDownloadAPI>( endPoint, { epidemic_track_id: epidemic_id } ).then(
    ( data ) =>
    {
      return data;
    }
  );
}

async function uploadMusic( musicFile: MusicFileData ): Promise<CommonTrackAPI>
{
  store.dispatch( clearProgressState() );
  store.dispatch( setProgressState( { message: "Uploading music..." } ) );
  const endPointUrl = buildUrl( [MUSIC_BASE_PATH, REQUEST_PRESIGNED_UPLOAD_ENDPOINT] );

  const fileName = musicFile.file.name;

  const presignParams = {
    file_name: fileName
  }

  const buffer = await musicFile.file.arrayBuffer();
  const uint8Array = new Uint8Array( buffer );
  const metadata = await mmb.parseBuffer( uint8Array, { mimeType: musicFile.file.type }, { duration: true } );

  const presignedUpload: RequestPresignedUploadAPIResponse = await postJsonToApi( endPointUrl, {}, presignParams );
  await s3UploadServices.uploadFileToS3( presignedUpload.url, presignedUpload.fields, musicFile.file );

  const displayName = metadata?.common?.title || fileName;
  const artistName = metadata?.common?.artist || "Unknown";
  const durationMs = Math.round( (metadata?.format?.duration || 0) * 1000 );
  return await businessServices.addMusicToLibrary( presignedUpload.s3_direct_url, displayName, artistName, durationMs );
}

async function favoriteMusicTrack( track: CommonTrackAPI, shouldFavorite: boolean ): Promise<FavoritedTrackAPI>
{
  const endPoint = buildUrl( [MUSIC_FAVORITES_PATH, FAVORITE_MUSIC_TRACK_PATH] );
  const state = store.getState();
  const params = {
    business_slug: getBusinessSlug( state ),
    track_data: track,
    should_favorite: shouldFavorite,
  }

  return postJsonToApi<FavoritedTrackAPI>( endPoint, {}, params );
}

async function getMyFavorites( page: number ): Promise<CommonTracksCollectionAPI>
{
  const endPoint = buildUrl( [MUSIC_FAVORITES_PATH, FAVORITE_MUSIC_LIST_PATH] );
  const state = store.getState();
  const params = {
    business_slug: getBusinessSlug( state ),
    page,
  }

  return getToApi<CommonTracksCollectionAPI>( endPoint, params ).then(
    ( data ) =>
    {
      return data;
    }
  );
}

async function getFavoritedIdsMappedByType(): Promise<FavoriteTrackIdsMappedContextType>
{
  const endPoint = buildUrl( [MUSIC_FAVORITES_PATH, FAVORITE_MUSIC_TRACKS_BY_TYPE_PATH] );
  const state = store.getState();
  const params = {
    business_slug: getBusinessSlug( state ),
  }

  const favoritedMusicIdsByType = await getToApi<FavoriteTrackIdsByTypeAPI>( endPoint, params );
  const favoriteMeloldieTrackIdMap = reduce( favoritedMusicIdsByType.melodie_track_ids, ( acc, id ) =>
  {
    acc[id] = true;
    return acc;
  }, {} );

  const favoriteUploadedTrackIdMap = reduce( favoritedMusicIdsByType.uploaded_track_ids, ( acc, id ) =>
  {
    acc[id] = true;
    return acc;
  }, {} );

  return { [TRACK_TYPE_MELODIE]: favoriteMeloldieTrackIdMap, [TRACK_TYPE_UPLOADED]: favoriteUploadedTrackIdMap };
}

function buildUrl( pathPieces: string[] )
{
  return pathPieces.join( "/" );
}
