import { getCurrentMusicTrack, getCurrentMusicTrackPlaying, MusicTrack, setAudioPlayerPlaying } from "../ui/uiSlice";
import { RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { IS_DEVELOPMENT } from "../../constants";
import { logToConsoleError } from "../utils/devLoggingHelper";
import { useEffect, useRef } from "react";

export function MusicPlayer()
{
  const dispatch = useDispatch();
  const currentMusic = useSelector( ( state: RootState ) => getCurrentMusicTrack( state ) );
  const currentMusicPlaying = useSelector( ( state: RootState ) => getCurrentMusicTrackPlaying( state ) );
  let audioElementRef = useRef<HTMLAudioElement>( null );
  let playPromiseRef = useRef<Promise<void>>();

  function pausePlayingAudio()
  {
    const audioElement = audioElementRef.current;
    const playPromise = playPromiseRef.current;

    if ( playPromise )
    {
      playPromise.then( () => audioElement && audioElement.pause() ).catch( ( error ) => logToConsoleError( "pause after play failed " + error ) );
    }
    else if ( audioElement )
    {
      audioElement.pause();
    }
  }

  function clearAudio()
  {
    pausePlayingAudio();

    playPromiseRef.current = undefined;
  }

  useEffect( () =>
  {
    return () =>
    {
      clearAudio();
      dispatch( setAudioPlayerPlaying( false ) );
    }
  }, [] );

  useEffect( () =>
  {
    if ( currentMusic )
    {
      if ( currentMusicPlaying )
      {
        startPlayingAudio( currentMusic );
      }
      else
      {
        pausePlayingAudio();
      }
    }
    else
    {
      pausePlayingAudio();
    }
  }, [currentMusic, currentMusicPlaying] );

  function startPlayingAudio( musicTrack: MusicTrack )
  {
    if ( audioElementRef === null || audioElementRef.current === null )
    {
      return;
    }

    audioElementRef.current.src = musicTrack.url;

    const audioElement = audioElementRef.current;

    if ( IS_DEVELOPMENT )
    {
      audioElement.volume = 0.1;
    }
    const startTimeInSeconds = musicTrack.startTimeInSeconds ? musicTrack.startTimeInSeconds : 0;
    audioElement.currentTime = startTimeInSeconds;

    if ( audioElement )
    {
      audioElement.addEventListener( "ended", () =>
      {
        if ( audioElement )
        {
          audioElement.currentTime = startTimeInSeconds;
        }
      } );
    }

    playPromiseRef.current = audioElement && audioElement.play();
    const playPromise = playPromiseRef.current;

    if ( playPromise )
    {
      playPromise.catch( ( error: any ) =>
      {
        logToConsoleError( "play failed " + error );
        dispatch( setAudioPlayerPlaying( false ) );

      } ).finally( () =>
      {
        playPromiseRef.current = undefined;
      } );
    }
  }

  return (<audio ref={audioElementRef}></audio>);
}