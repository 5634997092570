import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { MediaList } from "../mediaList/mediaList";
import { MediaPickerFullScreenDialog } from "../mediaPicker/mediaPicker";
import { postIdeaServices } from "../../services/postIdeaServices";
import { chain, first, flatten, forEach, isEmpty, isEqual, map, size } from "lodash";
import { MAX_NUMBER_OF_MEDIA_ERROR, MediaAssetSource, MediaPickingMode, POST_MEDIA_PICKER_TITLE } from "../constants";
import { MediaAssetAPI } from "../../services/business.services";
import { allowAddMedia, allowRemoveMedia, canAddUrls, getOrderedMediaUrls, isStatic } from "../postIdea/postDataHelper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getPostForPostIdea } from "../postIdea/postsSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { ConfigurationSearchTermAPI, PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { PostIdeaContext } from "../context/postIdeaContext";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { ManageSingleMediaDialog } from "./manageSingleMediaDialog";
import { UrlUtils } from "../utils/urlUtils";
import { CuratedMediaSetsCarousel } from "../mediaPicker/curatedMediaSetsCarousel";
import { CuratedMediaSetAPI, curatedMediaSetServices } from "../../services/curatedMediaSet.services";
import { CuratedMediaPickerFullScreenDialog } from "../mediaPicker/curatedMediaPicker";
import { Divider } from "@mui/material";
import { getLayoutPresetName } from "../postIdea/postIdeaHelper";
import { layoutServices } from "../../services/layout.services";
import { errorReporter } from "../error/errorReporter";

function getSearchTermsFromPostIdea( postIdea: PostIdeaDataAPI )
{
  let finalSearchTerms: string[] = [];
  forEach( postIdea.configuration.search_terms, ( searchTermObj: ConfigurationSearchTermAPI ) =>
  {
    let searchOptions = searchTermObj.search_options;
    let terms = flatten( searchOptions.terms );
    if ( isEmpty( terms ) )
    {
      return
    }
    finalSearchTerms.concat( terms );
    finalSearchTerms.push( searchOptions.descriptionOfScene );
    finalSearchTerms.push( searchOptions.fallback );
    finalSearchTerms.push( searchOptions.background );
  } )
  return chain( finalSearchTerms ).flatten().uniq().sort( ( stringA, stringB ) =>
  {
    return stringA.length - stringB.length;
  } ).value();
}

function getDescriptionOfSceneFromPostIdea( postIdea: PostIdeaDataAPI )
{
  let finalSearchTerms: string[] = [];
  forEach( postIdea.configuration.search_terms, ( searchTermObj: ConfigurationSearchTermAPI ) =>
  {
    let searchOptions = searchTermObj.search_options;
    finalSearchTerms.push( searchOptions.descriptionOfScene );
  } )
  return finalSearchTerms
}

export function EditMediaTabContent()
{
  const dispatch = useDispatch();
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const [currentMediaUrlToReplace, setCurrentMediaUrlToReplace] = useState<string>();
  const [currentMediaUrlToManage, setCurrentMediaUrlToManage] = useState<string>();
  const [currentMediaIndexToManage, setCurrentMediaIndexToManage] = useState<number>();
  const [showAddMedia, setShowAddMedia] = useState<boolean>();
  const [showAddCuratedMedia, setShowAddCuratedMedia] = useState<boolean>();
  const [selectedUrl, setSelectedUrl] = useState<string>( "" );
  const [loading, setLoading] = useState( false );
  const stockMediaSearchTerms = getSearchTermsFromPostIdea( postIdea );
  const descriptionOfScene = getDescriptionOfSceneFromPostIdea( postIdea );
  const mediaPickerTitle = showAddMedia ? POST_MEDIA_PICKER_TITLE : "Replace Media";
  const post = useSelector( ( state: RootState ) => getPostForPostIdea( state, postIdea.id ), isEqual );
  const [showManageMediaDialog, setShowManageMediaDialog] = useState( false );
  const [curatedMediaSets, setCuratedMediaSets] = useState<CuratedMediaSetAPI[]>( [] );
  const [selectedCuratedMediaSetSlug, setSelectedCuratedMediaSetSlug] = useState<string>();
  const showCuratedMediaSets = curatedMediaSets && size( curatedMediaSets ) > 0;
  const currentLayoutPresetName = getLayoutPresetName( postIdea );
  const [mediaUsageHint, setMediaUsageHint] = useState<string>();

  async function onMount()
  {
    await loadMediaUsageHint();
    await loadCuratedMediaSets();
  }

  useEffect( () =>
  {
    onMount();
  }, [] );

  async function loadMediaUsageHint()
  {
    try
    {
      const hintResponse = await layoutServices.getLayoutUsageHint( currentLayoutPresetName );
      if ( hintResponse.enable_hint_for_media && !isEmpty( hintResponse.usage_hint ) )
      {
        setMediaUsageHint( hintResponse.usage_hint );
      }
    }
    catch (error)
    {
      errorReporter.reportErrorToSentry( error );
    }
  }

  async function loadCuratedMediaSets()
  {
    if ( allowAddMedia( post ) )
    {
      try
      {
        const response = await curatedMediaSetServices.getCuratedMediaSets()
        setCuratedMediaSets( response.curated_media_sets );
      }
      catch
      {
        dispatch( setAlertMessage( errorAlert( "Failed to load seasonal media collections", 'top' ) ) );
      }
    }
  }

  function clearMediaPickerStates()
  {
    setCurrentMediaUrlToReplace( undefined );
    setShowAddMedia( false );
    setShowAddCuratedMedia( false );
  }

  function handleAddMediaClicked()
  {
    eventTracker.logEditPostMediaAddMediaClicked( postIdea );
    setShowAddMedia( true );
  }

  async function handleRemoveMediaClicked( urlToRemove: string, removedIndex: number )
  {
    if ( loading )
    {
      return;
    }
    setLoading( true );
    eventTracker.logEditPostMediaRemoveClicked( postIdea, removedIndex );
    const removedResult = await postIdeaServices.removeSingleMedia( postIdea.id, urlToRemove );
    setLoading( false );
    if ( !!removedResult && !!currentMediaUrlToManage )
    {
      handleCloseManageMediaDialog();
    }
  }

  async function handleSelectedMediaUrl( selectedUrl: string,
                                         mediaSource: MediaAssetSource,
                                         onAddMediaSuccess?: ( postIdea: PostIdeaDataAPI | undefined,
                                                               selectedUrl: string,
                                                               mediaSource: MediaAssetSource ) => void )
  {
    try
    {
      if ( showAddMedia )
      {
        if ( canAddUrls( post, [selectedUrl] ) )
        {
          await postIdeaServices.addSingleMedia( postIdea.id, selectedUrl, mediaSource );

          if ( onAddMediaSuccess )
          {
            onAddMediaSuccess( postIdea, selectedUrl, mediaSource );
          }
        }
        else
        {
          dispatch( setAlertMessage( errorAlert( MAX_NUMBER_OF_MEDIA_ERROR, "top" ) ) );
        }
      }
      else if ( !!currentMediaUrlToReplace )
      {
        eventTracker.logEditPostMediaReplaced( postIdea, mediaSource, selectedCuratedMediaSetSlug );
        await postIdeaServices.replaceSingleMedia( postIdea.id, currentMediaUrlToReplace, selectedUrl, mediaSource );
      }
      setSelectedUrl( selectedUrl );
    }
    catch (error)
    {
      dispatch( setAlertMessage( errorAlert( "An error occurred. Please try again.", "top" ) ) );
    }
  }

  async function handleSelectedMediaAssets( mediaAssets: MediaAssetAPI[],
                                            mediaSource: MediaAssetSource,
                                            closeAddAfterHandlingSelection: boolean,
                                            onAddMediaSuccess?: ( postIdea: PostIdeaDataAPI | undefined, mediaAssets: MediaAssetAPI[],
                                                                  mediaSource: MediaAssetSource ) => void,
                                            curatedCollectionSlug?: string )
  {
    try
    {
      if ( showAddMedia || showAddCuratedMedia )
      {
        if ( canAddUrls( post, map( mediaAssets, ( mediaAsset ) => mediaAsset.url ) ) )
        {
          await postIdeaServices.addMultipleMedia( postIdea.id, mediaAssets );

          const firstMediaAsset = first( mediaAssets );
          if ( firstMediaAsset )
          {
            setSelectedUrl( selectedUrl );
          }
          if ( onAddMediaSuccess )
          {
            onAddMediaSuccess( postIdea, mediaAssets, mediaSource );
          }
        }
        else
        {
          dispatch( setAlertMessage( errorAlert( MAX_NUMBER_OF_MEDIA_ERROR, "top" ) ) );
        }
      }
      else if ( !!currentMediaUrlToReplace )
      {
        const mediaAsset = first( mediaAssets );
        if ( mediaAsset )
        {
          const selectedUrl = mediaAsset.url;
          eventTracker.logEditPostMediaReplaced( postIdea, mediaSource, curatedCollectionSlug );
          await postIdeaServices.replaceSingleMedia( postIdea.id, currentMediaUrlToReplace, selectedUrl, mediaAsset.source );
          const urlUtil = new UrlUtils();
          if ( urlUtil.isVideoUrl( selectedUrl ) && isStatic( post ) )
          {
            dispatch( setAlertMessage( errorAlert( "This is a static post. Only the first frame of the video will be displayed.", "top" ) ) );
          }
          setSelectedUrl( selectedUrl );
        }
      }

      if ( closeAddAfterHandlingSelection )
      {
        clearMediaPickerStates();
      }
    }
    catch (error)
    {
      dispatch( setAlertMessage( errorAlert( "An error occurred. Please try again.", "top" ) ) );
    }
  }

  function handleManageMediaClicked( url: string, mediaIndex: number )
  {
    setCurrentMediaUrlToManage( url );
    setCurrentMediaIndexToManage( mediaIndex );
    setShowManageMediaDialog( true );
  }

  function handleCloseManageMediaDialog()
  {
    setShowManageMediaDialog( false );
    setCurrentMediaUrlToManage( undefined );
    setCurrentMediaIndexToManage( undefined );
  }

  function handleManageMediaReplaceClicked()
  {
    setCurrentMediaUrlToReplace( currentMediaUrlToManage );
    handleCloseManageMediaDialog();
  }

  function getMediaPickingMode()
  {
    if ( currentMediaUrlToReplace )
    {
      return MediaPickingMode.ReplaceSingle;
    }
    else
    {
      return MediaPickingMode.Add;
    }
  }

  function handleCuratedSetClicked( curatedMediaSetSlug: string )
  {
    eventTracker.logEditPostCuratedMediaSetClicked( curatedMediaSetSlug );
    setShowAddCuratedMedia( true );
    setSelectedCuratedMediaSetSlug( curatedMediaSetSlug );
  }

  function handleCloseCuratedMediaSetGrid()
  {
    setShowAddCuratedMedia( false );
    setSelectedCuratedMediaSetSlug( undefined );
  }

  return (
    <>
      <MediaList handleAddMediaClicked={handleAddMediaClicked}
                 handleManageMediaClicked={handleManageMediaClicked}
                 loading={loading}
                 mediaUsageHint={mediaUsageHint}/>
      {showCuratedMediaSets &&
       <>
         <Divider sx={{ mt: 9, mb: 4, maxWidth: "350px", mx: "auto" }}/>
         <CuratedMediaSetsCarousel curatedMediaSets={curatedMediaSets} handleCuratedMediaSetClicked={handleCuratedSetClicked}/>
       </>
      }

      <MediaPickerFullScreenDialog title={mediaPickerTitle}
                                   postIdea={postIdea}
                                   defaultStockMediaSearchTerms={stockMediaSearchTerms}
                                   defaultDescriptionOfScene={descriptionOfScene}
                                   externalStateOpen={!!(!!currentMediaUrlToReplace || showAddMedia)}
                                   handleClose={clearMediaPickerStates}
                                   processSelectedMediaUrl={handleSelectedMediaUrl}
                                   processSelectedMediaAssets={handleSelectedMediaAssets}
                                   currentMediaUrls={map( getOrderedMediaUrls( post ) )}
                                   mediaPickingMode={getMediaPickingMode()}
      />
      {currentMediaUrlToManage && <ManageSingleMediaDialog open={showManageMediaDialog}
                                                           handleClose={handleCloseManageMediaDialog}
                                                           mediaUrl={currentMediaUrlToManage}
                                                           mediaIndex={currentMediaIndexToManage}
                                                           allowDeleteMedia={allowRemoveMedia( post )}
                                                           handleReplaceMediaClicked={handleManageMediaReplaceClicked}
                                                           handleDeleteMediaClicked={handleRemoveMediaClicked}/>}
      {selectedCuratedMediaSetSlug && <CuratedMediaPickerFullScreenDialog curatedMediaSetSlug={selectedCuratedMediaSetSlug}
                                                                          handleClose={handleCloseCuratedMediaSetGrid}
                                                                          externalStateOpen={!!selectedCuratedMediaSetSlug}
                                                                          processSelectedMediaAssets={handleSelectedMediaAssets}/>}

    </>
  )
}
