import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import React from "react";
import { getCurrentGlobalErrorDialogType, hideGlobalErrorDialog, } from "../ui/uiSlice";
import PostIdeaNotificationNotFoundDialog from "./postIdeaNotificationNotFoundDialog";
import { GlobalErrorDialogType, POST_IDEA_NOTIFICATION_NOT_FOUND_DIALOG, YOUTUBE_OAUTH_ERROR_DIALOG_NO_LINKED_CHANNEL } from "../constants";
import YoutubeOauthErrorNoLinkedChannelDialog from "./youtubeOauthErrorNoLinkedChannelDialog";

export interface GlobalErrorDialogCallbackProps
{
  open: boolean;
  onConfirm: () => void;
}

export default function GlobalErrorDialog()
{
  const dispatch = useDispatch();
  const dialogType = useSelector( ( state: RootState ) => getCurrentGlobalErrorDialogType( state ) );
  const shouldShow = !!dialogType;

  function handleClose()
  {
    dispatch( hideGlobalErrorDialog() );
  }

  function getDialog( dialogType: GlobalErrorDialogType )
  {
    if ( dialogType === POST_IDEA_NOTIFICATION_NOT_FOUND_DIALOG )
    {
      return <PostIdeaNotificationNotFoundDialog open={shouldShow} onConfirm={handleClose}/>
    }
    if ( dialogType === YOUTUBE_OAUTH_ERROR_DIALOG_NO_LINKED_CHANNEL )
    {
      return <YoutubeOauthErrorNoLinkedChannelDialog open={shouldShow} onConfirm={handleClose}/>
    }
  }

  return (getDialog( dialogType ) || null)
}