import React, { useEffect } from "react";
import { iOSMessageSender } from "../payment/iOSMessageSender";

export function IOSInterfaceInitializationSetup()
{

  useEffect( () =>
  {
    setupIosClientInterface();
  }, [] );


  function setupIosClientInterface()
  {
    iOSMessageSender.setupCommandsAppCanInvoke();
  }

  return <></>
}

