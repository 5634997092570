import React, { useEffect, useRef, useState } from 'react'
import "./postIdea.scss";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isEqual, join, replace } from "lodash";
import { getPostForPostIdea } from "./postsSlice";
import { PostPreviewPlaceholder } from "./postPreviewPlacholder";
import { FullScreenEditor } from "../editing/editAndSharePostFlow";
import { PostPreviewThumbnail } from "./postPreviewThumbnail";
import { clearPendingDraftPostIdeaId, getPendingDraftPostIdeaId } from "../ui/uiSlice";
import { useIsVisibleInViewport } from "../hooks/useIsVisibleInViewport";
import "./postPreviewThumbnail.scss";
import { PostIdeaContext } from '../context/postIdeaContext';
import { isGuestUser } from "../user/userSlice";
import { CREATE_ACCOUNT_SHOWN_VIA_POST_PREVIEW } from "../constants";
import { CreateAccountOrSignIn } from "../registration/createAccountOrSignIn";
import { chatServices } from "../../services/chat.services";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { PlayCircleInContainer } from "../ui/playCircleInContainer";
import { clsx } from "clsx";
import { hasCompletedPostIdea } from "./postIdeaHelper";
import { outputFormatHelper } from "../planner/outputFormatHelper";
import { getPostIdea } from "./postIdeaSlice";

export interface PostIdeaMessageProps
{
  postIdeaId: string;
  postIdeaGroupIndex: number;
  postIdeaGroupSize: number;
}

export function PostIdeaMessage( props: PostIdeaMessageProps )
{
  const postIdeaRef = useRef<HTMLDivElement | null>( null );
  const isVisibleInViewport = useIsVisibleInViewport( postIdeaRef );
  const postIdea = useSelector( ( state: RootState ) => getPostIdea( state, props.postIdeaId ) );
  const post = useSelector( ( state: RootState ) => getPostForPostIdea( state, props.postIdeaId ), isEqual );
  const pendingDraftId = useSelector( ( state: RootState ) => getPendingDraftPostIdeaId( state ) );
  const [showEditDialog, setShowEditDialog] = useState( false );

  const isGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const [showForcedRegistration, setShowForcedRegistration] = useState( false );

  const dispatch = useDispatch();

  useEffect( () =>
  {
    if ( showEditDialog && pendingDraftId === props.postIdeaId )
    {
      dispatch( clearPendingDraftPostIdeaId() );
    }
  }, [showEditDialog] );

  useEffect( () =>
  {
    if ( !post && isVisibleInViewport && !!postIdea )
    {
      const postUrlsToFetch = postIdea.url
      chatServices.fetchAllPostUrls( [postUrlsToFetch] )
    }
  }, [isVisibleInViewport] );

  useEffect( () =>
  {
    if ( !showEditDialog && pendingDraftId === props.postIdeaId )
    {
      setShowEditDialog( true );
    }
  }, [pendingDraftId] )

  function buildIframeId()
  {
    return replace(
      "preview" + props.postIdeaId,
      /[ ,.']/g,
      "-" );
  }

  function handleOnClickForPreview()
  {
    if ( isGuest )
    {
      setShowForcedRegistration( true );
    }
    else
    {
      setShowEditDialog( true );
    }
  }

  function handleOnClickWhenNoPost()
  {
    if ( !!postIdea && !post )
    {
      const postUrlsToFetch = postIdea.url
      chatServices.fetchAllPostUrls( [postUrlsToFetch] ).then( () =>
      {
        handleOnClickForPreview();
      } ).catch( ( error ) =>
      {
        dispatch( setAlertMessage( errorAlert( "Error fetching data." ) ) )
      } );
    }
  }

  function handleCloseFullScreenEditor()
  {
    setShowEditDialog( false );
  }

  function getPostIdeaStatus()
  {
    if ( !!postIdea && hasCompletedPostIdea( postIdea ) )
    {
      return "Completed";
    }
    else if ( !!postIdea?.edited_at )
    {
      return "Edited";
    }
    return "";
  }

  function handleSignInCloseCallback()
  {
    setShowForcedRegistration( false );
    setShowEditDialog( true );
  }

  function getBestCssAspectRatio()
  {
    let additionalClass = "";
    if ( !!post )
    {
      additionalClass = post.aspect_ratio
    }
    else if ( !!postIdea && postIdea.output_format_slug )
    {
      additionalClass = outputFormatHelper.getPlaceholderPreviewAspect( postIdea.output_format_slug );
    }
    return join( ["previewThumbnail", additionalClass], " " );
  }

  return (
    <div ref={postIdeaRef} className={clsx( "postIdea" )}>
      {
        postIdea &&
        <div className="postIdeaContentWrapper">
          <div className="postIdeaContent">
            {(!post || showEditDialog) &&
             <div className={getBestCssAspectRatio()} onClick={handleOnClickWhenNoPost}>
               <PostPreviewPlaceholder/>
               <div className="clickTarget">
                 <PlayCircleInContainer/>
               </div>
             </div>}

            {!!post &&
             <>
               <div onClick={handleOnClickForPreview} className={getBestCssAspectRatio()}>
                 {(!showEditDialog && isVisibleInViewport) && <PostPreviewThumbnail post={post} iframeId={buildIframeId()}/>}
               </div>
               <PostIdeaContext.Provider value={{ postIdea }}>
                 <FullScreenEditor editable={true}
                                   externalStateOpen={showEditDialog}
                                   handleClose={handleCloseFullScreenEditor}/>
               </PostIdeaContext.Provider>
             </>
            }
          </div>
          <Typography sx={{ display: "flex", justifyContent: "end", color: "#707070" }} variant="caption">{getPostIdeaStatus()}</Typography>
        </div>
      }
      {showForcedRegistration && <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_POST_PREVIEW}
                                                        shouldShowSignIn={showForcedRegistration}
                                                        onClose={handleSignInCloseCallback}/>}
    </div>
  )
}
