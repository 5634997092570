import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PostEditingDrawerContainer } from "../edtingDrawer/postEditingDrawerContainer";
import { PostEditingDrawerCancelApply } from "../edtingDrawer/postEditingDrawerCancelApply";
import { DrawerTitle } from "../../typography/DrawerTitle";
import { Stack, Typography } from "@mui/material";
import { EditMelodieMusicFullScreenDialog } from "../editMelodieMusicTabContent";
import { PostIdeaContext } from "../../context/postIdeaContext";
import {
  doesPostHaveNoMusicSelected,
  getMusicDataFromPostJson,
  getOutputDurationMsFromPostJson,
  getTrackDurationMsFromPostJson,
  getTrackStartTimeMsFromPostJson
} from "../../postIdea/postIdeaHelper";
import { MusicItemView } from "../../music/MusicItemView";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import {
  clearEditorAudioStartTimeOverrideMs,
  getEditorAnimationCompleteTimestamp,
  setEditorAudioStartTimeOverrideMs,
  setRequestEditorReloadWithAudioTimestamp,
  setRequestEditorStopAnimationTimestamp
} from "../../ui/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import { musicItemHelper } from "../../music/musicItemHelper";
import { NO_MUSIC_TRACK_DISPLAY_NAME, TRACK_TYPE_NO_MUSIC } from "../../constants";
import { debounce, isNumber } from "lodash";
import { WaveformSlider } from "./waveformSlider";
import { postIdeaServices } from "../../../services/postIdeaServices";
import { PostIdeaDataAPI } from "../../assistantChat/assistantChatSlice";
import { RootState } from "../../../app/store";
import { amplitudeFeatureFlagsVariables } from "../../amplitude/amplitudeFeatureFlagsVariables";

export interface EditMusicDrawerProps
{
  handleDrawerClose: () => void;
}

export function EditMusicDrawer( props: EditMusicDrawerProps )
{
  const AUTO_SAVE_DELAY_MS = 500;
  const dispatch = useDispatch();
  const shouldShowWaveformStartTimeSelection = amplitudeFeatureFlagsVariables.shouldShowWaveformStartTimeSelection();
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const currentMusicData = useRef( getMusicDataFromPostJson( postIdea ) );
  const hasNoMusic = useRef( doesPostHaveNoMusicSelected( postIdea ) );
  const [showEditMusicDialog, setShowEditMusicDialog] = useState( hasNoMusic.current );
  const [showStopIcon, setShowStopIcon] = useState( false );

  const trackDurationMs = getTrackDurationMsFromPostJson( postIdea );
  const designDurationMs = getOutputDurationMsFromPostJson( postIdea );
  const initialTrackStartTimeMsRef = useRef( getTrackStartTimeMsFromPostJson( postIdea ) );
  const debounceApplyTrackStartTimeOverride = useCallback( debounce( applyTrackStartTimeOverride, AUTO_SAVE_DELAY_MS ), [] );
  const pendingTrackStartTimeMsRef = useRef( initialTrackStartTimeMsRef.current );

  const editorAnimationCompleteTimestamp = useSelector( ( state: RootState ) => getEditorAnimationCompleteTimestamp( state ) );

  useEffect( () =>
  {
    return () =>
    {
      persistPendingStartTimeSelection();
      dispatch( clearEditorAudioStartTimeOverrideMs() );
    }
  }, [] );

  useEffect( () =>
  {
    if ( editorAnimationCompleteTimestamp )
    {
      setShowStopIcon( false );
    }
  }, [editorAnimationCompleteTimestamp] );

  function setPendingTrackStartTimeMsRef( startTimeMs: number )
  {
    pendingTrackStartTimeMsRef.current = startTimeMs;
  }

  function applyTrackStartTimeOverride( startTimeMs: number )
  {
    if ( startTimeMs !== pendingTrackStartTimeMsRef.current )
    {
      setShowStopIcon( true );
      dispatch( setEditorAudioStartTimeOverrideMs( startTimeMs ) );
      replayDesignInEditor();
      setPendingTrackStartTimeMsRef( startTimeMs );
    }
  }

  function persistPendingStartTimeSelection()
  {
    const newStartTimeMs = pendingTrackStartTimeMsRef.current;
    if ( shouldShowWaveformStartTimeSelection && (newStartTimeMs !== initialTrackStartTimeMsRef.current) )
    {
      replaceMusicWithNewStartTimeMs( currentMusicData.current, newStartTimeMs, postIdea );
    }
  }

  const onApplyClick = () =>
  {
    persistPendingStartTimeSelection();
    props.handleDrawerClose();
  }

  const onCancelClick = () =>
  {
    if ( shouldShowWaveformStartTimeSelection )
    {
      setPendingTrackStartTimeMsRef( initialTrackStartTimeMsRef.current );
      const newStartTimeMs = initialTrackStartTimeMsRef.current;
      replaceMusicWithNewStartTimeMs( currentMusicData.current, newStartTimeMs, postIdea );
    }
    props.handleDrawerClose();
  }

  function replaceMusicWithNewStartTimeMs( musicData, newStartTimeMs: number, postIdea: PostIdeaDataAPI )
  {
    const newMusicTrack = musicItemHelper.getMusicTrackFromGlobalSettingsMusicData( musicData );
    const startTimeSeconds = newStartTimeMs / 1000.0;
    const replaceMusicTrack = musicItemHelper.convertMusicTrackToReplaceMusicAPIPayload( newMusicTrack, startTimeSeconds );
    postIdeaServices.replaceMusicWhenMelodieFeatureEnabled( postIdea.id, replaceMusicTrack )
  }

  function handleOpenEditMusicDialog()
  {
    setShowEditMusicDialog( true );
    dispatch( setRequestEditorStopAnimationTimestamp( new Date().getTime() ) )
  }

  function handleCloseEditMusicDialog()
  {
    setShowEditMusicDialog( false );
    if ( hasNoMusic.current )
    {
      props.handleDrawerClose();
    }
  }

  function handleNewMusicTrackApplied()
  {
    hasNoMusic.current = doesPostHaveNoMusicSelected( postIdea );
    currentMusicData.current = getMusicDataFromPostJson( postIdea );
    initialTrackStartTimeMsRef.current = getTrackStartTimeMsFromPostJson( postIdea );
    handleStartTimeChange( initialTrackStartTimeMsRef?.current || 0 );
    handleCloseEditMusicDialog();
  }

  function handleTogglePlayingMusic()
  {
    setShowStopIcon( !showStopIcon );
    if ( showStopIcon )
    {
      stopAnimation();
    }
    else
    {
      replayDesignInEditor();
    }
  }

  function replayDesignInEditor()
  {
    dispatch( setRequestEditorReloadWithAudioTimestamp( new Date().getTime() ) )
  }

  function stopAnimation()
  {
    dispatch( setRequestEditorStopAnimationTimestamp( new Date().getTime() ) )
  }

  function currentMusicView()
  {
    if ( !currentMusicData.current || (currentMusicData.current.type === TRACK_TYPE_NO_MUSIC) )
    {
      return <Typography variant={"body1"}>{NO_MUSIC_TRACK_DISPLAY_NAME}</Typography>
    }
    else
    {
      const commonTrackItemConvertedFromMusicData = musicItemHelper.getMusicTrackFromGlobalSettingsMusicData( currentMusicData.current );
      if ( !commonTrackItemConvertedFromMusicData )
      {
        return <Typography variant={"body1"}>Error retrieving music</Typography>
      }
      return <MusicItemView track={commonTrackItemConvertedFromMusicData}
                            handleClicked={handleTogglePlayingMusic}
                            selected={false}
                            loading={false}
                            showStopIcon={showStopIcon}
                            showBottomText={false}
                            showFavoriteIcon={false}/>
    }
  }

  function shouldRenderWaveForm()
  {
    return shouldShowWaveformStartTimeSelection && isNumber( trackDurationMs ) && isNumber( designDurationMs ) && !showEditMusicDialog;
  }

  function handleStartTimeChange( startTimeMs: number )
  {
    debounceApplyTrackStartTimeOverride( startTimeMs );
  }

  return (
    <PostEditingDrawerContainer sx={{ height: "auto" }}>
      <DrawerTitle>Music</DrawerTitle>
      <Stack spacing={2} sx={{ alignItems: "center" }}>
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-around" }}>
          {currentMusicView()}
          <IconButton sx={{ width: 45, height: 45 }} onClick={handleOpenEditMusicDialog}><EditIcon/></IconButton>
        </Stack>
      </Stack>
      {
        shouldRenderWaveForm() &&
        trackDurationMs !== undefined &&
        designDurationMs !== undefined &&
        <Stack sx={{ position: "relative", width: "100%", mb: 10 }}>
          <WaveformSlider trackLengthMs={trackDurationMs}
                          designDurationMs={designDurationMs}
                          initialStartTimeMs={initialTrackStartTimeMsRef.current}
                          handleStartTimeChange={handleStartTimeChange}/>
        </Stack>
      }
      <PostEditingDrawerCancelApply onCancelClick={onCancelClick} onApplyClick={onApplyClick}/>
      {showEditMusicDialog && <EditMelodieMusicFullScreenDialog externalStateOpen={showEditMusicDialog}
                                                                handleClose={handleCloseEditMusicDialog}
                                                                handleCloseAfterApplyingMusic={handleNewMusicTrackApplied}/>}
    </PostEditingDrawerContainer>
  );
}

