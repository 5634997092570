import { getToApi, makeAuthenticatedRequest, postJsonToApi, postToApi } from "./requestManager";
import { ENV_CONFIG } from "../constants";
import { store } from "../app/store";
import { addPost, PostAPIWrapper } from "../features/postIdea/postsSlice";
import { isEmpty, isUndefined } from "lodash";
import { setRecordingState } from "../features/loadingIndicator/loadingSlice";
import { MediaAssetSource, NavigateDirection, TiktokPrivacyOption, YoutubePrivacyOption } from "../features/constants";
import { MediaAssetAPI, PostSharingStatus, RefreshBusinessAPI, SocialNetworkAccountAPI, SocialNetworkPostAPI } from "./business.services";
import { PostIdeaDataAPI } from "../features/assistantChat/assistantChatSlice";
import { authenticationServices } from "./authentication.services";
import { setUpsellShown } from "../features/ui/uiSlice";
import { eventTracker } from "../helpers/eventTracker";
import { UPSELL_SOURCE_START_DOWNLOAD } from "../helpers/trackingConstants";
import { shouldBlockForUpsell } from "../features/user/userSlice";
import { UrlUtils } from "../features/utils/urlUtils";
import { currentUserBusinessId, getBusinessSlug, updateBusiness } from "../features/business/businessSlice";
import { getGAClientId } from "../features/tracking/trackingSlice";
import { DateUtils } from "../features/utils/dateUtils";
import { addOrUpdatePostIdeaFromMessage } from "../features/postIdea/postIdeaHelper";
import { logToConsole, logToConsoleError } from "../features/utils/devLoggingHelper";

export const postIdeaServices = {
  moveSlide,
  replaceSingleMedia,
  addMultipleMedia,
  addSingleMedia,
  removeSingleMedia,
  replaceText,
  getDownloadUrlWithTimeout,
  startDownload,
  replaceMusic,
  replaceMusicWhenMelodieFeatureEnabled,
  sendDownloadComplete,
  sendDirectShareStartComplete,
  generateNewLayoutVariant,
  generateSpecificLayoutVariant,
  generateSpecificHolidayLayoutVariant,
  revertHolidayLayoutVariant,
  generateNewColorVariant,
  generateNewFontVariant,
  applyBrandStyle,
  sendUserFeedbackShown,
  sendUserFeedbackCanceled,
  sendUserFeedbackSubmitted,
  getPostJson,
  rebuildPostIdea,
  getEditPrecheck,
  getPostIdeaData,
  startShareToSocialNetworks,
  retryShareToSocialNetworks,
  createShareBatch,
  createDraftShareBatch,
  startScheduleShareBatch,
  cancelShareBatch,
  generateHolidayPostIdea,
}

const POST_IDEAS_BASE_PATH = "post_ideas";
const POSTS_PATH = "posts";
const MOVE_SLIDE = "move_slide";
const REPLACE_SINGLE_MEDIA_PATH = "replace_single_media";
const VARIANT_PATH = "variant";
const LAYOUT_VARIANT_PATH = "layout_variant";
const HOLIDAY_LAYOUT_VARIANT_PATH = "holiday_layout_variant";
const REVERT_HOLIDAY_LAYOUT_PATH = "revert_holiday_layout";
const COLOR_VARIANT_PATH = "color_variant";
const FONT_VARIANT_PATH = "font_variant";
const APPLY_BRAND_STYLE_PATH = "apply_brand_style";
const EDIT_PRECHECK = "edit_precheck";
const POST_IDEA_DATA_PATH = "post_idea_data";
const ADD_SINGLE_MEDIA_PATH = "add_single_media";
const ADD_MULTIPLE_MEDIA_PATH = "add_multiple_media";
const REMOVE_SINGLE_MEDIA_PATH = "remove_single_media";
const REPLACE_MUSIC_PATH = "replace_music";
const REPLACE_TEXT_PATH = "replace_text";
const CHECK_DOWNLOAD_STATUS_PATH = "check_download_status";
const START_DOWNLOAD_PATH = "start_download";
const START_SHARE_TO_SOCIAL_NETWORKS_PATH = "start_share_to_social_networks";
const RETRY_SHARE_TO_SOCIAL_NETWORKS_PATH = "retry_share_to_social_networks";
const SCHEDULE_SHARE_BATCH_PATH = "schedule_share_batch";
const DOWNLOAD_COMPLETE_PATH = "download_complete";
const DIRECT_SHARE_START_COMPLETE_PATH = "direct_share_start_complete";
const FEEDBACK_PATH = "feedback"
const REBUILD_PATH = "rebuild"
const CREATE_SHARE_BATCH_PATH = "create_share_batch"
const CREATE_DRAFT_SHARE_BATCH_PATH = "create_draft_share_batch"
const CANCEL_SHARE_BATCH_PATH = "cancel_share_batch"
const GENERATE_HOLIDAY_POST_IDEA_PATH = "generate_holiday_post_idea";

const POST_JSON_PARAM = "post_json";
const POST_IDEA_MESSAGE_PARAM = "post_idea_message";

interface PostIdeaVariantPostJsonAPI
{
  post_json: PostAPIWrapper,
  post_idea_message: PartialMessageAPI,
  variant_id: string,
  added_music_for_beat_sync?: boolean
}

export interface PostIdeaEditPrecheckAPIResponse
{
  updated_post_and_post_idea?: UpdatedPostAndPostIdeaAPI
  has_variants: boolean,
  business?: RefreshBusinessAPI,
}

export interface UpdatedPostAndPostIdeaAPI
{
  post_json: any,
  post_idea_message: PartialMessageAPI,
}

export interface DownloadCompleteResponseAPI extends UpdatedPostAndPostIdeaAPI
{
  share_batch: ShareBatchAPI
}

interface PartialMessageAPI
{
  id: string
  data?: PostIdeaDataAPI
}

export interface PostIdeaApplyBrandStyleAPI
{
  enabled?: boolean,
  use_brand_in_brand_slide?: boolean,
  use_brand_in_post_idea?: boolean,
  font_set_slug?: string,
  color_palette_slug?: string,
}

export interface ShareBatchAPI
{
  id: string;
  status: PostSharingStatus;
  caption?: string;
  post_idea: PostIdeaDataAPI;
  created_at: string;
  direct_shared_at: string;
  first_downloaded_at: string;
  video_url?: string;
  image_url?: string;
  social_network_posts?: SocialNetworkPostAPI[];
  completed_at?: string;
  download_only: boolean;
  scheduled_for?: string;
  drafted_at?: string;
  output_format_slug?: string;
  content_goal?: string;
}

export interface CreateShareBatchResponse
{
  share_batch: ShareBatchAPI;
}

export interface CancelShareBatchResponse
{
  share_batch: ShareBatchAPI;
}

export interface ShareToSocialNetworksResponse
{
  post_idea_id: string;
  share_batch: ShareBatchAPI;
}

export interface ShareContextType
{
  shareBatch: ShareBatchAPI;
}

export interface PlanDateContextType
{
  planDate: Date;
}

export interface SocialNetworkAccountsContextType
{
  socialNetworkAccounts: SocialNetworkAccountAPI[];
  updateSocialNetworkAccounts: ( socialNetworkAccounts: SocialNetworkAccountAPI[] ) => void;
}

export interface TiktokSettingsAPI
{
  privacy_level: TiktokPrivacyOption;
  comment_disabled: boolean;
  duet_disabled: boolean;
  stitch_disabled: boolean;
  brand_organic_toggle: boolean;
  brand_content_toggle: boolean;
}

export interface YoutubeSettingsAPI
{
  privacy: YoutubePrivacyOption;
  video_title: string;
}

export interface ReplaceMusicUpdateParamsAPI
{
  track: MinimalReplaceMusicTrackAPI;
}

export interface MelodieFeatureEnabledReplaceMusicTrackAPI extends MinimalReplaceMusicTrackAPI
{
  display_name: string;
}

export interface MinimalReplaceMusicTrackAPI
{
  music_url: string;
  id: string;
  type?: string;
  expires?: string;
  start_time_in_seconds?: number;
}

function verifyBusinessExists()
{
  const state = store.getState()
  const business_id = currentUserBusinessId( state );

  if ( !business_id )
  {
    throw new Error( "Unable to update brand style without business" );
  }
}

async function rebuildPostIdea( postIdeaId: string )
{
  verifyBusinessExists();

  const endPoint = new UrlUtils().buildUrl( POST_IDEAS_BASE_PATH, [postIdeaId, REBUILD_PATH] );
  const response = await getToApi<UpdatedPostAndPostIdeaAPI>( endPoint, {} );
  if ( !!response )
  {
    updatePostJsonAndPostIdea( response );
    return response;
  }
  else
  {
    return Promise.reject( "Failed to rebuild post idea for post idea: id=" + postIdeaId );
  }
}

async function generateNewColorVariant( postIdeaId: string, colorPaletteSlug: string )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, COLOR_VARIANT_PATH );

    const queryParams = {
      color_palette_slug: colorPaletteSlug
    }
    const response = await postToApi<PostIdeaVariantPostJsonAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      return response;
    }
    else
    {
      return Promise.reject( `Failed to generate color variant for post idea: id=` + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( `Failed to generate color variant for post idea: id=` + postIdeaId );
  }
}

async function generateNewFontVariant( postIdeaId: string, fontSetSlug: string )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, FONT_VARIANT_PATH );

    const queryParams = {
      font_set_slug: fontSetSlug
    }
    const response = await postToApi<PostIdeaVariantPostJsonAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      return response;
    }
    else
    {
      return Promise.reject( `Failed to generate font variant for post idea: id=` + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( `Failed to generate font variant for post idea: id=` + postIdeaId );
  }
}

async function generateSpecificLayoutVariant( postIdeaId: string, presetName: string )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, LAYOUT_VARIANT_PATH );
    const queryParams = {
      preset_name: presetName
    }
    const response = await postToApi<PostIdeaVariantPostJsonAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      return response;
    }
    else
    {
      return Promise.reject( `Failed to generate layout variant for post idea: id=` + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( `Failed to generate layout variant for post idea: id=` + postIdeaId );
  }
}

async function generateSpecificHolidayLayoutVariant( postIdeaId: string, holidayLayoutSlug: string )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, HOLIDAY_LAYOUT_VARIANT_PATH );
    const queryParams = {
      holiday_layout_slug: holidayLayoutSlug
    }
    const response = await postToApi<PostIdeaVariantPostJsonAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      return response;
    }
    else
    {
      return Promise.reject( `Failed to generate holiday layout variant for post idea: id=` + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( `Failed to generate layout variant for post idea: id=` + postIdeaId );
  }
}

async function revertHolidayLayoutVariant( postIdeaId: string, holidayLayoutSlug: string )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, REVERT_HOLIDAY_LAYOUT_PATH );
    const queryParams = {
      holiday_layout_slug: holidayLayoutSlug
    }
    const response = await postToApi<PostIdeaVariantPostJsonAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      return response;
    }
    else
    {
      return Promise.reject( `Failed to revert holiday layout variant for post idea: id=` + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( `Failed to revert layout variant for post idea: id=` + postIdeaId );
  }
}

async function generateNewLayoutVariant( postIdeaId: string, direction: NavigateDirection )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, VARIANT_PATH );

    const queryParams = {
      direction,
    }

    const response = await postToApi<PostIdeaVariantPostJsonAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      return response;
    }
    else
    {
      return Promise.reject( `Failed to generate variant for post idea: id=` + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( `Failed to generate variant for post idea: id=` + postIdeaId );
  }
}

async function applyBrandStyle( postIdeaId: string, brandStyle: PostIdeaApplyBrandStyleAPI )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, APPLY_BRAND_STYLE_PATH );

    const response = await postJsonToApi<UpdatedPostAndPostIdeaAPI>( endpointUrl, {}, brandStyle );
    if ( !!response )
    {
      updatePostJsonAndPostIdea( response );
      return response;
    }
    else
    {
      return Promise.reject( "Failed to apply brand style for post idea: id=" + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( "Failed to apply brand style for post idea: id=" + postIdeaId );
  }
}

async function getEditPrecheck( postIdeaId: string )
{
  try
  {
    const businessSlug = getBusinessSlug( store.getState() );
    const endpointUrl = buildMemberUrl( postIdeaId, EDIT_PRECHECK );
    const response = await getToApi<PostIdeaEditPrecheckAPIResponse>( endpointUrl, { business_slug: businessSlug } );
    if ( !!response )
    {
      if ( response.updated_post_and_post_idea )
      {
        updatePostJsonAndPostIdea( response.updated_post_and_post_idea );
      }

      if ( response.business )
      {
        store.dispatch( updateBusiness( response.business ) );
      }
      return response;
    }
    else
    {
      return Promise.reject( `Edit precheck received invalid response for post idea: id=${postIdeaId}` );
    }
  }
  catch (error)
  {
    return Promise.reject( `Failed edit precheck for post idea: id=${postIdeaId} with ${error}` );
  }
}

async function getPostIdeaData( postIdeaId: string ): Promise<PostIdeaDataAPI>
{
  const businessSlug = getBusinessSlug( store.getState() );
  const endPoint = buildMemberUrl( postIdeaId, POST_IDEA_DATA_PATH );
  return getToApi<PostIdeaDataAPI>( endPoint, { business_slug: businessSlug } ).then(
    ( data ) =>
    {
      return data
    }
  );
}

async function moveSlide( postIdeaId: string, originalIndex: number, destinationIndex: number )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, MOVE_SLIDE );

    const queryParams = {
      original_index: originalIndex,
      destination_index: destinationIndex,
    }

    const response = await postToApi<UpdatedPostAndPostIdeaAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      updatePostJsonAndPostIdea( response );
      return response;
    }
    else
    {
      return Promise.reject( "Failed to move slide for post idea: id=" + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( "Failed to move slide for post idea: id=" + postIdeaId );
  }
}

async function replaceSingleMedia( postIdeaId: string, oldMediaUrl: string, newMediaUrl: string, mediaSource: MediaAssetSource )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, REPLACE_SINGLE_MEDIA_PATH );

    const queryParams = {
      old_media_url: oldMediaUrl,
      new_media_url: newMediaUrl,
      media_source: mediaSource,
    }

    const response = await postToApi<UpdatedPostAndPostIdeaAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      updatePostJsonAndPostIdea( response );
      return response;
    }
    else
    {
      return Promise.reject( "Failed to replace single media for post idea: id=" + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( "Failed to replace single media for post idea: id=" + postIdeaId );
  }
}

async function addMultipleMedia( postIdeaId: string, mediaAssets: MediaAssetAPI[] )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, ADD_MULTIPLE_MEDIA_PATH );

    const jsonBody = { media_assets: mediaAssets }

    const response = await postJsonToApi<UpdatedPostAndPostIdeaAPI>( endpointUrl, {}, jsonBody );
    if ( !!response )
    {
      updatePostJsonAndPostIdea( response );
      return response;
    }
    else
    {
      return Promise.reject( "Failed to add media for post idea: id=" + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( "Failed to add media for post idea: id=" + postIdeaId );
  }
}

async function addSingleMedia( postIdeaId: string, newMediaUrl: string, mediaSource: MediaAssetSource )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, ADD_SINGLE_MEDIA_PATH );

    const queryParams = {
      new_media_url: newMediaUrl,
      media_source: mediaSource,
    }

    const response = await postToApi<UpdatedPostAndPostIdeaAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      updatePostJsonAndPostIdea( response );
      return response;
    }
    else
    {
      return Promise.reject( "Failed to add media for post idea: id=" + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( "Failed to add media for post idea: id=" + postIdeaId );
  }
}

async function removeSingleMedia( postIdeaId: string, mediaUrl: string )
{
  try
  {
    const endpointUrl = new UrlUtils().buildUrl( POST_IDEAS_BASE_PATH, [postIdeaId, REMOVE_SINGLE_MEDIA_PATH] );

    const queryParams = {
      media_url: mediaUrl,
    }

    const response = await postToApi<UpdatedPostAndPostIdeaAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      updatePostJsonAndPostIdea( response );
      return response;
    }
    else
    {
      return Promise.reject( "Failed to remove media for post idea: id=" + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( "Failed to remove media for post idea: id=" + postIdeaId );
  }
}

async function updatePostIdeaWithMusic( jsonBody: ReplaceMusicUpdateParamsAPI, postIdeaId: string )
{
  const endpointUrl = buildMemberUrl( postIdeaId, REPLACE_MUSIC_PATH );

  try
  {
    const response = await postJsonToApi<UpdatedPostAndPostIdeaAPI>( endpointUrl, {}, jsonBody );
    if ( !!response )
    {
      updatePostJsonAndPostIdea( response );
      return response;
    }
    else
    {
      return Promise.reject( "Failed to update post idea: id=" + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( "Failed to update post idea: id=" + postIdeaId );
  }
}

async function replaceMusicWhenMelodieFeatureEnabled( postIdeaId: string,
                                                      newMusicTrack: MelodieFeatureEnabledReplaceMusicTrackAPI )
{
  const jsonBody = {
    track: newMusicTrack
  }

  return await updatePostIdeaWithMusic( jsonBody, postIdeaId );
}

async function replaceMusic( postIdeaId: string,
                             musicUrl: string,
                             id: string,
                             type: string,
                             expires?: string,
                             startTimeInSeconds?: number,
                             displayName?: string )
{
  let track: MinimalReplaceMusicTrackAPI = {
    music_url: musicUrl,
    id: id,
    expires: expires,
    start_time_in_seconds: startTimeInSeconds,
  };

  const jsonBody = {
    track: track
  }

  return await updatePostIdeaWithMusic( jsonBody, postIdeaId );
}

async function replaceText( postIdeaId: string, newExtraCaptions?: Record<string, string>, newPostIdeaCaption?: string )
{
  try
  {
    const endpointUrl = buildMemberUrl( postIdeaId, REPLACE_TEXT_PATH );
    let queryParams: Record<string, any> = {}

    if ( !isEmpty( newExtraCaptions ) )
    {
      queryParams.extraCaptions = JSON.stringify( newExtraCaptions )
    }

    if ( !isUndefined( newPostIdeaCaption ) )
    {
      queryParams.postIdeaCaption = newPostIdeaCaption
    }

    const response = await postToApi<UpdatedPostAndPostIdeaAPI>( endpointUrl, queryParams );
    if ( !!response )
    {
      updatePostJsonAndPostIdea( response );
      return response
    }
    else
    {
      return Promise.reject( "Failed to update text for post idea: id=" + postIdeaId );
    }
  }
  catch (error)
  {
    return Promise.reject( "Failed to update text for post idea: id=" + postIdeaId );
  }
}

function updatePostJsonAndPostIdea( response: UpdatedPostAndPostIdeaAPI )
{
  const updatedPostJson = response[POST_JSON_PARAM];
  const updatedPostIdeaMessage = response[POST_IDEA_MESSAGE_PARAM];

  store.dispatch( addPost( updatedPostJson ) );
  addOrUpdatePostIdeaFromMessage( updatedPostIdeaMessage );
}

const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;
const MAX_TIMEOUT_GET_DOWNLOAD_URL_MILLISECONDS = FIVE_MINUTES_IN_MILLISECONDS;

async function getDownloadUrlWithTimeout( postIdeaId: string )
{
  let startTime = new Date().getTime();
  logToConsole( "Start waitForRecordingWithTimeout: " + startTime )
  return await getDownloadUrl( postIdeaId, startTime );
}

async function startDownload( postIdeaId: string, shareBatchId?: string )
{
  const endpointUrl = buildMemberUrl( postIdeaId, START_DOWNLOAD_PATH );
  const queryParams = {
    share_batch_id: shareBatchId,
  }

  return getToApi( endpointUrl, queryParams ).catch( ( error ) =>
  {
    if ( error.refresh_user )
    {
      return authenticationServices.refreshUser().then( () =>
      {
        if ( shouldBlockForUpsell( store.getState() ) )
        {
          store.dispatch( setUpsellShown( true ) )
          eventTracker.logUpsellShown( UPSELL_SOURCE_START_DOWNLOAD );
        }
      } );
    }
  } );
}

async function createShareBatch( postIdeaId: string, downloadOnly: boolean, isScheduled: boolean, shareBatchId?: string )
{
  const endpointUrl = buildMemberUrl( postIdeaId, CREATE_SHARE_BATCH_PATH );
  const jsonBody = {
    is_scheduled: isScheduled,
    download_only: downloadOnly,
    share_batch_id: shareBatchId
  }
  return postJsonToApi<CreateShareBatchResponse>( endpointUrl, {}, jsonBody ).then( ( response ) =>
  {
    return response;
  } );
}

async function createDraftShareBatch( postIdeaId: string, draftedAtDate: Date )
{
  const endpointUrl = buildMemberUrl( postIdeaId, CREATE_DRAFT_SHARE_BATCH_PATH );
  const draftedAtDateTime = DateUtils.toISODateTimeString( draftedAtDate );

  return postJsonToApi<CreateShareBatchResponse>( endpointUrl, { drafted_at: draftedAtDateTime } ).then( ( response ) =>
  {
    return response;
  } );
}

async function cancelShareBatch( shareBatch: ShareBatchAPI )
{
  const endpointUrl = buildMemberUrl( shareBatch.post_idea.id, CANCEL_SHARE_BATCH_PATH );
  const json = {
    share_batch_id: shareBatch.id
  }
  return postJsonToApi<CancelShareBatchResponse>( endpointUrl, {}, json ).then( ( response ) =>
  {
    return response;
  } );
}

async function startShareToSocialNetworks( postIdeaId: string, socialNetworkAccountIds: string[], share_batch_id: string,
                                           tiktokSettings?: TiktokSettingsAPI, youtubeSettings?: YoutubeSettingsAPI )
{
  const endpointUrl = buildMemberUrl( postIdeaId, START_SHARE_TO_SOCIAL_NETWORKS_PATH );

  const jsonBody = {
    social_network_account_ids: socialNetworkAccountIds,
    share_batch_id: share_batch_id,
    tiktok_settings: tiktokSettings,
    youtube_settings: youtubeSettings,
  }

  return postJsonToApi<ShareToSocialNetworksResponse>( endpointUrl, {}, jsonBody );
}

async function retryShareToSocialNetworks( postIdeaId: string, socialNetworkPostIds: string[], shareBatchId: string )
{
  const endpointUrl = buildMemberUrl( postIdeaId, RETRY_SHARE_TO_SOCIAL_NETWORKS_PATH );

  const jsonBody = {
    social_network_post_ids: socialNetworkPostIds,
    share_batch_id: shareBatchId
  }

  return postJsonToApi( endpointUrl, {}, jsonBody );
}

async function sendShareBatchUpdate( postIdeaId: string, memberUrlPath: string, shareBatchId: string | undefined )
{
  const endpointUrl = buildMemberUrl( postIdeaId, memberUrlPath );
  const googleClientId = getGAClientId( store.getState() );
  const postBody = {
    ga_client_id: googleClientId,
    share_batch_id: shareBatchId
  };
  const response = await postJsonToApi<DownloadCompleteResponseAPI>( endpointUrl, {}, postBody );

  if ( !!response )
  {
    updatePostJsonAndPostIdea( response );
  }
  return response;
}

async function sendDirectShareStartComplete( postIdeaId: string, shareBatchId?: string )
{
  return await sendShareBatchUpdate( postIdeaId, DIRECT_SHARE_START_COMPLETE_PATH, shareBatchId );
}

async function sendDownloadComplete( postIdeaId: string, shareBatchId?: string )
{
  return await sendShareBatchUpdate( postIdeaId, DOWNLOAD_COMPLETE_PATH, shareBatchId );
}

async function startScheduleShareBatch( postIdeaId: string, socialNetworkAccountIds: string[], shareBatchId: string, scheduledForTime: Date,
                                        sendSharingReminder: boolean )
{
  const endpointUrl = buildMemberUrl( postIdeaId, SCHEDULE_SHARE_BATCH_PATH );

  const scheduledForTimeString = DateUtils.toISODateTimeString( scheduledForTime );
  const jsonBody = {
    social_network_account_ids: socialNetworkAccountIds,
    share_batch_id: shareBatchId,
    scheduled_for_time: scheduledForTimeString,
    send_sharing_reminder: sendSharingReminder,
  }

  return postJsonToApi<ShareToSocialNetworksResponse>( endpointUrl, {}, jsonBody );

}

async function getPostJson( postIdeaId: string ): Promise<PostAPIWrapper>
{
  const endPoint = POSTS_PATH + "/" + postIdeaId + ".json";
  return getToApi<PostAPIWrapper>( endPoint ).then( ( response ) =>
  {
    return response;
  } );
}

interface PostIdeaCheckDownloadStatusAPI
{
  recording_status: string;
  recording_phase_progress: number;
  output_url: string;
}

export enum RecordingStatusAPI
{
  INITIALIZING = "initializing",
  LOADING = "loading",
  RECORDING = "recording",
  UPLOADING = "s3_uploading",
  READY = "ready",
  FAILED = "failed",
  DIRTY = "dirty",
}

function updateRecordingState( response: PostIdeaCheckDownloadStatusAPI )
{
  const updatedRecordingState = { recording_status: response.recording_status, recording_phase_progress: response.recording_phase_progress }
  store.dispatch( setRecordingState( updatedRecordingState ) )
}

async function getDownloadUrl( postIdeaId: string, startTime )
{
  const endpointUrl = buildMemberUrl( postIdeaId, CHECK_DOWNLOAD_STATUS_PATH );

  const response = await makeAuthenticatedRequest<PostIdeaCheckDownloadStatusAPI>(
    { baseUrl: ENV_CONFIG.baseUrl, endPoint: endpointUrl, methodType: "GET" } );

  if ( new Date().getTime() - startTime > MAX_TIMEOUT_GET_DOWNLOAD_URL_MILLISECONDS )
  {
    logToConsole( "Hit max time out " );

    return Promise.reject( "Max timeout reached for downloading post idea " );
  }
  else if ( !!response )
  {
    if ( response.recording_status === RecordingStatusAPI.READY )
    {
      updateRecordingState( response );
      logToConsole( "Download ready" );
      return response.output_url;
    }
    else if ( response.recording_status === RecordingStatusAPI.INITIALIZING ||
              response.recording_status === RecordingStatusAPI.LOADING ||
              response.recording_status === RecordingStatusAPI.RECORDING ||
              response.recording_status === RecordingStatusAPI.UPLOADING )
    {
      updateRecordingState( response );
      logToConsole( "Download in progress, waiting 2 seconds" );
      await new Promise( resolve => setTimeout( resolve, 2000 ) );
      return await getDownloadUrl( postIdeaId, startTime );
    }
    else
    {
      updateRecordingState( response );
      logToConsoleError( "Download not ready nor in progress" );
      return Promise.reject( "Download unavailable for post idea with status " + response.recording_status );
    }
  }
  else
  {
    logToConsoleError( "Download bad response" );
    return Promise.reject( "Download unavailable for post idea" );
  }
}

async function generateHolidayPostIdea( businessHolidayId: string, holidayLayoutSlug: string ): Promise<PostIdeaDataAPI>
{
  const queryParams = {
    business_holiday_id: businessHolidayId,
    holiday_layout_slug: holidayLayoutSlug,
  }

  const endPoint = new UrlUtils().buildUrl( POST_IDEAS_BASE_PATH, [GENERATE_HOLIDAY_POST_IDEA_PATH] )
  return postToApi<PostIdeaDataAPI>( endPoint, queryParams ).then(
    ( data ) =>
    {
      return data
    }
  );
}

interface UserFeedbackPayload
{
  content?: string | null;
  overall_rating?: number;
  status: string;
}

function sendUserFeedbackShown( postIdeaId: string )
{
  return sendUserFeedback( postIdeaId, { status: "shown" } );
}

function sendUserFeedbackCanceled( postIdeaId: string )
{
  return sendUserFeedback( postIdeaId, { status: "canceled" } );
}

function sendUserFeedbackSubmitted( postIdeaId: string, overall_rating?: number, content?: string | null )
{
  return sendUserFeedback( postIdeaId, { content, overall_rating, status: "submitted" } );
}

function sendUserFeedback( postIdeaId: string, userFeedbackPayload: UserFeedbackPayload )
{
  const endPointUrl = buildMemberUrl( postIdeaId, FEEDBACK_PATH );

  return postJsonToApi( endPointUrl, {}, userFeedbackPayload ).then( ( data ) =>
    {
      addOrUpdatePostIdeaFromMessage( data );
    }
  );
}

function buildMemberUrl( postIdeaId, path )
{
  return new UrlUtils().buildUrl( POST_IDEAS_BASE_PATH, [postIdeaId, path] )
}
