import { Box, Button, Stack } from "@mui/material";
import { filter, isEmpty, map } from "lodash";
import { businessServices, CurrentStreakAPI, PlannerAPI, PlannerDateAPI, SocialNetworkAccountAPI } from "../../services/business.services";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentUserBusinessId, getFirstPostDrafted, getHasSeenCustomPlanAnnouncementDialog } from "../business/businessSlice";
import { RootState } from "../../app/store";
import { DateStack } from "./dateStack";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  CREATE_ACCOUNT_SHOWN_VIA_PLAN_DIALOG,
  NAVIGATE_NEXT,
  NAVIGATE_PREVIOUS,
  POSTING_STREAK_NOTIFICATION_STATUS_UNREAD,
  ROUTE_PARAM,
  ROUTE_SEO,
  ROUTES
} from "../constants";
import AlertBanner from "../alert/alertBanner";
import useRemoveSearchParamByKey from "../hooks/useRemoveSearchParamByKey";
import { StreakDialogFullScreen } from "./streakDialog";
import { StreakHeader } from "./streakHeader";
import { PostSuccessDialogFullScreen } from "./postSuccessDialog";
import { WeekHeader } from "./weekHeader";
import { eventTracker } from "../../helpers/eventTracker";
import { isGuestUser } from "../user/userSlice";
import { CreateAccountOrSignIn } from "../registration/createAccountOrSignIn";
import {
  FullScreenDialogWallWithBlurredBackground,
  FullScreenDialogWallWithBlurredBackgroundProps
} from "./fullScreenDialogWallWithBlurredBackground";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { PlanModalBodyText } from "./planModalBodyText";
import { Helmet } from "react-helmet";
import { AutoAwesome } from "@mui/icons-material";
import { clearPlanLastDateClicked, clearPlanLastPostIdeaEdited, getPlanLastDateClicked, setPlanLastDateClicked } from "../ui/uiSlice";
import { PlannerDateAPIUtils } from "../utils/plannerDateAPIUtils";
import { DateUtils } from "../utils/dateUtils";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { parseISO, startOfToday } from "date-fns";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { UrlUtils } from "../utils/urlUtils";

export function PlanTab()
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const requestedDateParam = searchParams.get( ROUTE_PARAM.PLAN_TAB_DATE );
  const requestedDate = safelyParseRequestedDateIntoDateObject()

  const startOfWeekForRequestedDate = DateUtils.getMondayOfWeekForDate( requestedDate );

  const selectedWeekPlanData = useRef<PlannerAPI>( { dates: [], week_start_date: "" } );
  const [selectedWeekPlanDataDates, setSelectedWeekPlanDataDates] = useState<PlannerDateAPI[]>( [] );
  const [hasPreviousWeek, setHasPreviousWeek] = useState( false );
  const [hasNextWeek, setHasNextWeek] = useState( true );
  const mondayOfCurrentWeek = DateUtils.getMondayOfWeekForDate( new Date() );
  const mondayOfRequestedWeek = DateUtils.getMondayOfWeekForDate( requestedDate );

  const [selectedWeekStartDate, setSelectedWeekStartDate] = useState<Date>( mondayOfRequestedWeek );
  const isViewingPastWeek = !!selectedWeekStartDate && selectedWeekStartDate < mondayOfCurrentWeek;

  const containerRef = useRef<HTMLDivElement>( null )

  const removeSearchParamByKey = useRemoveSearchParamByKey();
  const [showStreakDialog, setShowStreakDialog] = useState( false );
  const [showPostSuccessDialog, setShowPostSuccessDialog] = useState( false );
  const navigatedFromPostComplete = UrlUtils.isParamValueTrue( searchParams.get( ROUTE_PARAM.PLAN_TAB_POST_COMPLETE ) )
  const navigatedWithRefreshRequest = UrlUtils.isParamValueTrue( searchParams.get( ROUTE_PARAM.PLAN_TAB_REFRESH_REQUEST ) )
  const navigatedWithParameters = navigatedFromPostComplete || navigatedWithRefreshRequest;
  const location = useLocation();
  const isGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const [loading, setLoading] = useState( false );
  const [showCreateLogin, setShowCreateLogin] = useState<boolean>( false );
  const [showPlanModalDialog, setShowPlanModalDialog] = useState<boolean>( false );
  const hasPostBeenDraftedForBusiness = useSelector( ( state: RootState ) => getFirstPostDrafted( state ) );
  const planExtensionStatus = selectedWeekPlanData.current?.plan_extension_status;
  const isPlanExtensionInProgress = planExtensionStatus === 'extending';
  const hasSeenCustomPlanAnnouncementDialog = useSelector( ( state: RootState ) => getHasSeenCustomPlanAnnouncementDialog( state ) );
  const lastDateClicked = useSelector( ( state: RootState ) => getPlanLastDateClicked( state ) )
  const [streak, setStreak] = useState<CurrentStreakAPI | undefined>( undefined );
  const [errorRefreshingPlan, setErrorRefreshingPlan] = useState<boolean>( false );
  const [socialNetworkAccounts, setSocialNetworkAccounts] = useState<SocialNetworkAccountAPI[]>( [] );
  const isCurrentBusinessSet = useSelector( ( state: RootState ) => !!currentUserBusinessId( state ) );

  function safelyParseRequestedDateIntoDateObject()
  {
    return !!requestedDateParam && requestedDateParam !== "undefined" ? parseISO( requestedDateParam ) : new Date();
  }

  const hasPlannedDatesInFuture = () =>
  {
    const hasFutureDatesWithPlanSuggestions = PlannerDateAPIUtils.futureDatesWithDraftsOrPostingPlanDates( selectedWeekPlanDataDates ).length > 0;
    const hasFutureDatesWithCompletedPosts = PlannerDateAPIUtils.futureDatesWithCompletedPosts( selectedWeekPlanDataDates ).length > 0;

    return hasFutureDatesWithPlanSuggestions || hasFutureDatesWithCompletedPosts;
  }

  function futureDatesWithoutPlanSuggestions()
  {
    const futureDates: PlannerDateAPI[] = PlannerDateAPIUtils.getFutureDates( selectedWeekPlanDataDates );
    return filter( futureDates, ( date ) => date.suggestions.length === 0 );
  }

  useEffect( () =>
  {
    fetchSocialNetworkAccounts();
    if ( !navigatedWithParameters )
    {
      refreshPlanner();
    }

    return cleanUpPlanUIState;
  }, [] );

  useEffect( () =>
  {
    if ( navigatedWithParameters )
    {
      refreshPlanner( true );
    }
  }, [navigatedFromPostComplete, navigatedWithRefreshRequest] );

  useEffect( () =>
  {
    if ( isCurrentBusinessSet )
    {
      refreshPlanner( true );
    }
  }, [isCurrentBusinessSet] );

  useEffect( () =>
  {
    const showPlanModal = shouldShowPlanModalDialog();
    setShowPlanModalDialog( showPlanModal );
  }, [isGuest, hasSeenCustomPlanAnnouncementDialog, selectedWeekPlanData.current, hasPostBeenDraftedForBusiness] );

  async function fetchSocialNetworkAccounts()
  {
    const socialNetworkAccountsResponseAPI = await businessServices.listSocialNetworkAccounts();
    setSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
  }

  async function refreshPlanner( afterCompletedPost?: boolean )
  {
    eventTracker.logPlanShown( location.state?.prevPath, searchParams.toString() );

    cleanUpSearchParams();

    let adjustedWeekStartDate = selectedWeekStartDate;

    if ( afterCompletedPost && !!lastDateClicked )
    {
      const postWasShownOnDate = parseISO( lastDateClicked );

      if ( DateUtils.isBeforeDay( postWasShownOnDate ) )
      {
        const today = startOfToday();
        const todayDateString = DateUtils.toISODateString( today );
        dispatch( setPlanLastDateClicked( todayDateString ) );
        adjustedWeekStartDate = DateUtils.getMondayOfWeekForDate( today );
      }
      else
      {
        adjustedWeekStartDate = DateUtils.getMondayOfWeekForDate( postWasShownOnDate );
      }
      setSelectedWeekStartDate( adjustedWeekStartDate );
    }

    await getPlannerDataForWeek( startOfWeekForRequestedDate )
    if ( !showPlanModalDialog && !shouldShowPlanModalDialog() )
    {
      setTimeout( () =>
      {
        document.getElementById( lastDateClicked )?.scrollIntoView( true );
      }, 1 );
    }
    const currentStreak = await businessServices.getCurrentStreak()
    setStreak( currentStreak );
    showPostCompleteDialog( currentStreak );
  }

  function cleanUpSearchParams()
  {
    removeSearchParamByKey( ROUTE_PARAM.PLAN_TAB_POST_COMPLETE );
    removeSearchParamByKey( ROUTE_PARAM.PLAN_TAB_REFRESH_REQUEST );
  }

  function cleanUpPlanUIState()
  {
    dispatch( clearPlanLastDateClicked() );
    dispatch( clearPlanLastPostIdeaEdited() );
  }

  function showPostCompleteDialog( result: CurrentStreakAPI )
  {
    if ( navigatedFromPostComplete && result )
    {
      const currentPostingStreak = result.posting_streak;
      if ( !!currentPostingStreak && currentPostingStreak.notification_status === POSTING_STREAK_NOTIFICATION_STATUS_UNREAD )
      {
        setShowStreakDialog( true );
      }
      else if ( !showStreakDialog )
      {
        setShowPostSuccessDialog( true );
      }
    }
  }

  function handleStreakDialogClose()
  {
    setShowStreakDialog( false );
  }

  function handlePostSuccessDialogClose()
  {
    setShowPostSuccessDialog( false );
  }

  function shouldShowPlanModalDialog()
  {
    const planModalDialogProps = getPlanModalDialogProps();
    return !!planModalDialogProps;
  }

  function handleDialogOnClose()
  {
    setShowCreateLogin( false );
  }

  function redirectToChat()
  {
    navigateWithSearchParams( ROUTES.CHAT );
  }

  function refreshPlanFromErrorDialog()
  {
    setLoading( true );
    hideRefreshPlanError();
    refreshPlanner();
  }

  async function closeModalAndMarkCustomPlanAsShown()
  {
    await businessServices.updateBusinessClientUiFlagsJson( { has_seen_custom_plan_announcement_dialog: true } );
    setShowPlanModalDialog( false );
  }

  function closeModal()
  {
    setShowPlanModalDialog( false );
  }

  async function showSignUpDialogAndMarkCustomPlanAsShown()
  {
    await businessServices.updateBusinessClientUiFlagsJson( { has_seen_custom_plan_announcement_dialog: true } );
    showSignUpDialog();
  }

  function showSignUpDialog()
  {
    setShowCreateLogin( true );
  }

  async function handleExtendPlan()
  {
    const datesWithoutPlanSuggestions = futureDatesWithoutPlanSuggestions();
    eventTracker.logExtendPlanClicked( datesWithoutPlanSuggestions.length );

    await businessServices.extendPlan().then( () =>
    {
      getPlannerDataForWeek();
    } );
  }

  function getPlanModalDialogProps(): FullScreenDialogWallWithBlurredBackgroundProps | null
  {
    if ( !isCurrentBusinessSet )
    {
      return null;
    }

    const lastExtendedAt = selectedWeekPlanData.current?.last_extended_at;
    const planHasBeenExtended = !!lastExtendedAt;

    if ( errorRefreshingPlan )
    {
      return {
        loading,
        header: "Oops!",
        body: <Stack>
          <PlanModalBodyText>Something went wrong. There was a problem refreshing your plan.</PlanModalBodyText>
        </Stack>,
        primaryCTAText: "Retry",
        handleCloseXClicked: refreshPlanFromErrorDialog,
        handlePrimaryCTAClicked: refreshPlanFromErrorDialog,
      }
    }
    else if ( !hasPostBeenDraftedForBusiness )
    {
      return {
        loading,
        header: "I still need to learn about your business",
        body: <Stack>
          <PlanModalBodyText>I'll build a posting plan for you once I've learned about your business.</PlanModalBodyText>
        </Stack>,
        primaryCTAText: "Continue",
        handleCloseXClicked: redirectToChat,
        handlePrimaryCTAClicked: redirectToChat,
      }
    }
    else if ( isGuest )
    {
      if ( planHasBeenExtended && !hasSeenCustomPlanAnnouncementDialog )
      {
        return {
          loading,
          header: "Your plan is ready!",
          body: <Stack>
            <PlanModalBodyText>I'll automatically create posts for you on each posting day.</PlanModalBodyText>
            <PlanModalBodyText>Sign up so I can let you know when your new posts are ready to review!</PlanModalBodyText>
          </Stack>,
          primaryCTAText: "Sign up",
          handleCloseXClicked: closeModalAndMarkCustomPlanAsShown,
          handlePrimaryCTAClicked: showSignUpDialogAndMarkCustomPlanAsShown,
        }
      }
      else if ( !planHasBeenExtended )
      {
        return {
          loading,
          header: "Your plan is being generated",
          body: <Stack>
            <PlanModalBodyText>I'm building you a custom posting plan.</PlanModalBodyText>
            <PlanModalBodyText>Sign up to be notified when your plan is ready!</PlanModalBodyText>
          </Stack>,
          primaryCTAText: "Sign up",
          handleCloseXClicked: closeModal,
          handlePrimaryCTAClicked: showSignUpDialog,
        }
      }
    }
    else
    {
      if ( planHasBeenExtended && !hasSeenCustomPlanAnnouncementDialog )
      {
        return {
          loading,
          header: "Your plan is ready!",
          body: <Stack>
            <PlanModalBodyText>I'll create a new post for you each day according to the plan.</PlanModalBodyText>
            <PlanModalBodyText>Let's grow your business together!</PlanModalBodyText>
          </Stack>,
          primaryCTAText: "I'm Excited!",
          handleCloseXClicked: closeModalAndMarkCustomPlanAsShown,
          handlePrimaryCTAClicked: closeModalAndMarkCustomPlanAsShown,
        }
      }
      else if ( !planHasBeenExtended )
      {
        return {
          loading,
          header: "Your plan is being generated",
          body: <Stack>
            <PlanModalBodyText>I'm building you a custom posting plan.</PlanModalBodyText>
            <PlanModalBodyText>I'll notify you when it’s ready for review!</PlanModalBodyText>
          </Stack>,
          primaryCTAText: "Awesome!",
          handleCloseXClicked: closeModal,
          handlePrimaryCTAClicked: closeModal,
        }
      }
    }
    return null;
  }

  function handleNextWeekSelected()
  {
    if ( !!selectedWeekStartDate && hasNextWeek )
    {
      const newDate = new Date( selectedWeekStartDate );
      newDate?.setDate( newDate.getDate() + 7 );
      setSelectedWeekStartDate( newDate );
      const startDateOfNextWeek = DateUtils.formatDateToYearMonthDay( newDate );
      eventTracker.logPlanWeekChanged( NAVIGATE_NEXT, startDateOfNextWeek );

      getPlannerDataForWeek( newDate ).then( () =>
      {
        containerRef.current?.scrollTo( 0, 0 );
      } );
    }
  }

  async function handlePreviousWeekSelected()
  {
    if ( !!selectedWeekStartDate && hasPreviousWeek )
    {
      const newDate = new Date( selectedWeekStartDate );
      newDate?.setDate( newDate.getDate() - 7 );
      setSelectedWeekStartDate( newDate );
      const startDateOfPreviousWeek = DateUtils.formatDateToYearMonthDay( newDate );
      eventTracker.logPlanWeekChanged( NAVIGATE_PREVIOUS, startDateOfPreviousWeek );

      getPlannerDataForWeek( newDate ).then( () =>
      {
        containerRef.current?.scrollTo( 0, 0 );
      } );
    }
  }

  async function getPlannerDataForWeek( startDate?: Date )
  {
    setLoading( true );
    const dateToRequest = startDate || selectedWeekStartDate;
    try
    {
      const requestedWeekData = await businessServices.getPlannerDataForWeek( dateToRequest )
      if ( !!requestedWeekData )
      {
        if ( window.location.pathname === ROUTES.PLAN )
        {
          setSearchParams( { date: requestedWeekData.week_start_date } );
        }

        const dataForWeek = requestedWeekData.dates;
        setSelectedWeekStartDate( parseISO( requestedWeekData.week_start_date ) );
        selectedWeekPlanData.current = requestedWeekData;
        setSelectedWeekPlanDataDates( dataForWeek );
        setHasPreviousWeek( requestedWeekData.has_previous_week || false );
        setHasNextWeek( requestedWeekData.has_next_week || false );
      }
      else
      {
        showRefreshPlanError();
      }
    }
    catch (error)
    {
      showRefreshPlanError();
    }
    setLoading( false );
  }

  function showRefreshPlanError()
  {
    setShowPlanModalDialog( true );
    setErrorRefreshingPlan( true );
  }

  function hideRefreshPlanError()
  {
    setShowPlanModalDialog( false );
    setErrorRefreshingPlan( false );
  }

  function buildWeekStack()
  {
    if ( !!selectedWeekPlanDataDates && !!selectedWeekStartDate )
    {
      return <Stack>
        <WeekHeader date={selectedWeekStartDate}
                    onNextWeek={handleNextWeekSelected}
                    onPreviousWeek={handlePreviousWeekSelected}
                    hasNextWeeks={hasNextWeek}
                    hasPreviousWeeks={hasPreviousWeek}
                    dates={selectedWeekPlanDataDates}
                    handleUpdatePlannerData={getPlannerDataForWeek}/>
        {
          map( selectedWeekPlanDataDates, ( plannerDateAPI: PlannerDateAPI ) =>
          {
            return <SocialNetworkAccountsContext.Provider key={plannerDateAPI.date_with_timezone}
                                                          value={{ socialNetworkAccounts, updateSocialNetworkAccounts }}>
              <DateStack key={plannerDateAPI.date_with_timezone}
                         plannerDateAPI={plannerDateAPI}
                         handleUpdatePlannerData={getPlannerDataForWeek}/>
            </SocialNetworkAccountsContext.Provider>
          } )
        }
      </Stack>;
    }
  }

  function updateSocialNetworkAccounts( updatedSocialNetworkAccounts: SocialNetworkAccountAPI[] )
  {
    setSocialNetworkAccounts( updatedSocialNetworkAccounts );
  }

  function getPlanExtensionMessage()
  {
    if ( isGuest )
    {
      return <>Plan is being extended, sign up to get notified or check back soon.</>;
    }
    return <>Your plan is being updated. This may take a few minutes. Refresh this page to view.</>;
  }

  const planModalDialogProps = getPlanModalDialogProps();
  return <Stack ref={containerRef}
                sx={{
                  backgroundColor: 'white',
                  position: 'relative',
                  height: "100%",
                  overflow: showPlanModalDialog ? "hidden" : "auto"
                }}>
    <Helmet>
      <title>{ROUTE_SEO.PLAN.title}</title>
      <meta name="description" content={ROUTE_SEO.PLAN.description}/>
    </Helmet>
    {!!streak && <StreakHeader streak={streak}/>}
    {!loading && !hasPlannedDatesInFuture() && !isPlanExtensionInProgress && !isViewingPastWeek && <Button variant={"contained"}
                                                                                                           sx={{ mx: 10 }}
                                                                                                           startIcon={<AutoAwesome/>}
                                                                                                           onClick={handleExtendPlan}>Extend my
                                                                                                                                      plan</Button>}
    {!hasPlannedDatesInFuture() && isPlanExtensionInProgress && <Box sx={{ mx: 10 }}>{getPlanExtensionMessage()}</Box>}
    {isCurrentBusinessSet && selectedWeekStartDate && buildWeekStack()}
    {streak && showStreakDialog && <StreakDialogFullScreen streak={streak}
                                                           externalStateOpen={showStreakDialog}
                                                           handleClose={handleStreakDialogClose}/>}
    {streak && showPostSuccessDialog && <PostSuccessDialogFullScreen streak={streak}
                                                                     externalStateOpen={showPostSuccessDialog}
                                                                     handleClose={handlePostSuccessDialogClose}/>}
    { //@ts-ignore
      showPlanModalDialog && !isEmpty( planModalDialogProps ) && <FullScreenDialogWallWithBlurredBackground {...planModalDialogProps}/>}

    {showCreateLogin && <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_PLAN_DIALOG}
                                               shouldShowSignIn={showCreateLogin}
                                               onClose={handleDialogOnClose}/>}
    {loading && !showPlanModalDialog && <ProgressOverlay hideBackDrop={false} wheelColor="primary.main" message="Loading plan..."/>}

    <AlertBanner/>
  </Stack>
}
