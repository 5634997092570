import { concat } from "lodash";
import { store } from "../app/store";
import { getUserSlug } from "../features/user/userSlice";
import { postJsonToApi } from "./requestManager";
import { detect } from "detect-browser";
import { ALKAI_VERSION } from "../constants";
import { getBusinessSlug } from "../features/business/businessSlice";

const USER_BASE_PATH = "users";
const NEW_SUPPORT_TICKET_PATH = "submit_ticket";

const browser = detect();

export const customerSupportServices = {
  submitTicket
}

export interface NewSupportTicketAPI
{
  success: boolean;
}

function submitTicket( emailAddress: string, description: string, subject: string )
{
  const state = store.getState();

  if ( !!state.user.slug )
  {
    const jsonBody = {
      user_slug: getUserSlug( state ),
      user_email: emailAddress,
      business_slug: getBusinessSlug( state ),
      message: description,
      subject: subject,
      feedback_text: getFeedbackText(),
    }

    const endpoint = buildUserMemberUrl( state.user.slug, NEW_SUPPORT_TICKET_PATH );
    return postJsonToApi<NewSupportTicketAPI>( endpoint, {}, jsonBody ).then( ( response ) =>
    {
      if ( response.success )
      {
        return response;
      }
      else
      {
        throw new Error( "Failed to submit ticket" );
      }
    } );
  }
}

function getFeedbackText()
{
  let browserName = "unknown";
  if ( browser )
  {
    browserName = browser.name;
  }
  // @ts-ignore userAgentData is not in the types but does exist
  const platform = navigator?.userAgentData?.platform || navigator?.platform;

  return {
    client_id: "app.alkai.ai",
    version: ALKAI_VERSION,
    user: store.getState().user.slug,
    current_business: store.getState().business.name,
    current_business_slug: getBusinessSlug( store.getState() ),
    subscription: store.getState().user.is_subscribed,
    browser_name: browserName,
    browser_platform: platform,
    data_cookies_enabled: navigator.cookieEnabled,
    size_screen_w: window.screen.width,
    size_screen_h: window.screen.height
  }
}

function buildUserMemberUrl( userSlugOrId: string, path: string )
{
  return buildUrl( [userSlugOrId, path] );
}

function buildUrl( pathPieces: string[] )
{
  return concat( [USER_BASE_PATH], pathPieces ).join( "/" );
}