import { SxProps } from "@mui/material";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { PlayCircleOutlined } from "@mui/icons-material";
import { hasInputVideosWithAudio } from "../postIdea/postDataHelper";
import { getPostForPostIdea } from "../postIdea/postsSlice";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";
import { DEFAULT_VIDEO_AUDIO_TRACK_DISPLAY_NAME } from "../constants";
import { SelectableMusicItemView } from "./selectableMusicItemView";

interface VideoAudioMusicItemProps
{
  postIdea: PostIdeaDataAPI;
  selected: boolean;
  handleTrackSelected: () => void;
  iconSize?: number;
  sx?: SxProps;
}

export function VideoAudioMusicItem( props: VideoAudioMusicItemProps )
{
  const iconSize = props.iconSize || 56;
  const iconSizePx = `${iconSize}px`;
  const post = useSelector( ( state: RootState ) => getPostForPostIdea( state, props.postIdea.id ) );
  const hasVideosWithAudio = hasInputVideosWithAudio( post );

  if ( hasVideosWithAudio )
  {
    return (
      <SelectableMusicItemView
        sx={props.sx}
        selected={props.selected}
        handleOnClick={props.handleTrackSelected}
        icon={<PlayCircleOutlined sx={{ height: iconSizePx, width: iconSizePx, color: props.selected ? "primary.main" : "#000000" }}/>}
        text={DEFAULT_VIDEO_AUDIO_TRACK_DISPLAY_NAME}
      />
    );
  }
  else
  {
    return null;
  }
}
