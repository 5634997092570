import { Typography } from "@mui/material";
import { ConfirmDialog } from "../ui/confirmDialog";
import React from "react";
import { GlobalErrorDialogCallbackProps } from "./globalErrorDialog";

export default function YoutubeOauthErrorNoLinkedChannelDialog( props: GlobalErrorDialogCallbackProps )
{

  function getDialogContent()
  {
    return <Typography sx={{ px: 20 }} align={"left"}>
      <p>There are no YouTube Channels associated with your Google Account.</p>
      <p>Before attempting to connect again, add a YouTube Channel, or connect with a different Google Account.</p>
    </Typography>
  }

  return <ConfirmDialog {...props}
                        title={"YouTube Channel not found."}
                        dialogContent={getDialogContent()}
                        showNeedHelpLink={true}
                        confirmText={"OK"}
  />
}