import { Box, Button, Stack, SxProps, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import * as React from "react";
import { useCallback, useContext, useRef, useState } from "react";
import { first, join, merge } from "lodash";
import { musicServices } from "../../services/music.services";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { CommonTrackAPI } from "./musicSlice";
import { ALLOWED_MUSIC_FILE_EXTENSIONS, MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE } from "../constants";
import { logToConsoleError } from "../utils/devLoggingHelper";
import CircularProgress from "@mui/material/CircularProgress";
import { eventTracker } from "../../helpers/eventTracker";
import { PostIdeaContext } from "../context/postIdeaContext";

interface UploadMusicItemProps
{
  setLoading: ( value: boolean ) => void;
  handleUploadedMusicSelected: ( track: CommonTrackAPI ) => void;
  onOpenUploadedMusicPicker: () => void;
  sx?: SxProps
}

export function UploadMusicItem( props: UploadMusicItemProps )
{
  const [uploadInProgress, setUploadInProgress] = useState( false );
  const postIdea = useContext( PostIdeaContext ).postIdea
  const dispatch = useDispatch();
  const inputButtonRef = useRef<HTMLInputElement>( null );
  const allowed_file_extensions = join( ALLOWED_MUSIC_FILE_EXTENSIONS, "," )

  function handleOnClick()
  {
    eventTracker.logAddNewUploadedMusicClicked( postIdea );
    props.onOpenUploadedMusicPicker();
  }

  const handleFileSelection = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async ( event ) =>
    {
      props.setLoading( true );
      setUploadInProgress( true );

      const selectedMusicFile = event.currentTarget.files;

      let selectedFile = first( selectedMusicFile );
      if ( !!selectedFile )
      {
        if ( selectedFile.size > MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE )
        {
          const sizeInMegaBytes = MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE / (1024 * 1024);
          dispatch( setAlertMessage( errorAlert( `the size of selected file is bigger than ${sizeInMegaBytes} megabytes` ) ) )
        }
        else
        {
          const musicFileData = { file: selectedFile }
          await musicServices.uploadMusic( musicFileData ).then( ( track ) =>
          {
            props.handleUploadedMusicSelected( track );
            eventTracker.logUploadMusicCompleted( track );
          } ).catch(
            ( error ) =>
            {
              logToConsoleError( "error uploading music", error );
              dispatch( setAlertMessage( errorAlert( "Could not upload music. Please try again." ) ) );
              eventTracker.logUploadMusicFailed( error );
            }
          )
        }
      }

      setUploadInProgress( false );
      props.setLoading( false );

      if ( inputButtonRef && !!inputButtonRef.current )
      {
        inputButtonRef.current.value = "";
      }
    },
    []
  );

  function uploadButton()
  {
    return <Button component="label" variant={"text"} sx={{ p: 0 }}>
      <input ref={inputButtonRef}
             hidden
             type="file"
             accept={allowed_file_extensions}
             id={"pick-music-input"}
             onChange={handleFileSelection}
             onClick={handleOnClick}
      />
      <DriveFolderUploadIcon sx={{ fontSize: 40, color: "black" }}/>
      <Typography variant={"subtitle1"} sx={{ ml: 5, mr: 5, textTransform: "none", color: "black", fontSize: "17px" }}>
        Upload music from device
      </Typography>
    </Button>;
  }

  function uploadingView()
  {
    return <Stack direction={"row"} alignItems={"center"} sx={{ ml: 5 }}>
      <CircularProgress/>
      <Typography variant={"h6"} sx={{ ml: 5, mr: 5, textTransform: "none", color: "black" }}>Uploading</Typography>
    </Stack>;
  }

  function contents()
  {
    return uploadInProgress ? uploadingView() : uploadButton();
  }

  const border = "2px solid transparent";

  return (
    <Box sx={merge( { display: "flex", m: 5, border, alignItems: "center" }, props.sx )}>
      {contents()}
    </Box>
  );
}
