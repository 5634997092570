import { amplitudeFeatureFlagsWrapper } from "./amplitudeFeatureFlagsWrapper";
import { getIsInternalUser } from "../user/userSlice";
import { store } from "../../app/store";

let injectableAmplitudeFeatureFlagsWrapper: typeof amplitudeFeatureFlagsWrapper;
export const GRANDFATHERED_DEFAULT_TO_PLAN_TAB = "grandfathereddefaulttoplantab";
export const ALWAYS_SEND_POST_IDEA_NOTIFICATIONS = "always_send_post_idea_notifications";
export const SHOULD_USE_NEW_GENERAL_POST_PROMPT = "should_use_new_general_post_prompt";
export const SHOULD_SHOW_JAN_2025_PAYWALL = "should_show_jan_2025_paywall";
export const SHOULD_RESCRAPE_WEBSITE_ON_EXTEND_PLAN = "should_rescrape_website_on_extend_plan";

const BOOLEAN_FEATURE_FLAGS: string[] = [GRANDFATHERED_DEFAULT_TO_PLAN_TAB, ALWAYS_SEND_POST_IDEA_NOTIFICATIONS, SHOULD_USE_NEW_GENERAL_POST_PROMPT,
                                         SHOULD_SHOW_JAN_2025_PAYWALL, SHOULD_RESCRAPE_WEBSITE_ON_EXTEND_PLAN];

async function getAmplitudeVariablesForSignIn()
{
  const shouldUseNewGeneralPostPromptValue = await shouldUseNewGeneralPostPrompt()
  const shouldRescrapeWebsiteOnExtendPlanValue = await shouldRescrapeWebsiteOnExtendPlan()
  return {
    should_use_new_general_post_prompt_feature: shouldUseNewGeneralPostPromptValue,
    should_rescrape_website_on_extend_plan_feature: shouldRescrapeWebsiteOnExtendPlanValue,
  }
}

function shouldDefaultToPlanTab()
{
  return isFeatureEnabled( GRANDFATHERED_DEFAULT_TO_PLAN_TAB )
}

function shouldAlwaysSendPostIdeaNotifications()
{
  return isFeatureEnabled( ALWAYS_SEND_POST_IDEA_NOTIFICATIONS );
}

function shouldUseNewGeneralPostPrompt()
{
  return isFeatureEnabled( SHOULD_USE_NEW_GENERAL_POST_PROMPT );
}

function shouldRescrapeWebsiteOnExtendPlan()
{
  return isFeatureEnabled( SHOULD_RESCRAPE_WEBSITE_ON_EXTEND_PLAN );
}

function shouldAllowUserUploadedMusic()
{
  return true;
}

function shouldShowWaveformStartTimeSelection()
{
  return true;
}

function shouldAllowVideoAudioMusic()
{
  return true;
}

function shouldShowMusicFavorites()
{
  return true;
}

function shouldAllowEditingOfCompletedPosts()
{
  return getIsInternalUser( store.getState() );
}

function shouldShowJan2025Paywall()
{
  return isFeatureEnabled( SHOULD_SHOW_JAN_2025_PAYWALL );
}

function isFeatureEnabled( name: string ): Promise<boolean>
{
  return getWrapper().isFeatureFlagEnabled( name );
}

function getWrapper()
{
  return injectableAmplitudeFeatureFlagsWrapper ? injectableAmplitudeFeatureFlagsWrapper : amplitudeFeatureFlagsWrapper;
}

export const amplitudeFeatureFlagsVariables = {
  BOOLEAN_FEATURE_FLAGS,
  getAmplitudeVariablesForSignIn,
  shouldDefaultToPlanTab,
  shouldShowMusicFavorites,
  isFeatureEnabled,
  shouldAllowUserUploadedMusic,
  shouldShowWaveformStartTimeSelection,
  shouldAllowVideoAudioMusic,
  shouldAllowEditingOfCompletedPosts,
  shouldAlwaysSendPostIdeaNotifications,
  shouldShowJan2025Paywall,
  shouldRescrapeWebsiteOnExtendPlan,
};
