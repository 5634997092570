import * as React from 'react';
import { useContext, useState } from 'react';
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { CommonTrackAPI, MelodieGenreGroupOrPurposeAPI, MusicSelection } from "./musicSlice";
import { CircularProgress, Stack } from "@mui/material";
import { musicServices } from "../../services/music.services";
import { isNumber, map } from "lodash";
import { MusicItem } from "./MusicItem";
import { TRACK_TYPE_MELODIE } from "../constants";
import { AudioDrawer } from "../ui/audioDrawer";
import { eventTracker } from "../../helpers/eventTracker";
import { PostIdeaContext } from "../context/postIdeaContext";
import { musicItemHelper } from "./musicItemHelper";
import { getSelectedTrackIdFromPostJson, getSelectedTrackTypeFromPostJson } from "../postIdea/postIdeaHelper";
import { EditMelodieMusicHeader } from "../editing/editMelodieMusicHeader";
import InfiniteScroll from "react-infinite-scroller";
import { FullScreenModalInnerWrap } from "../editing/fullScreenModalInnerWrap";
import { LoadMusicErrorView } from "./LoadMusicErrorView";
import RefreshIcon from "@mui/icons-material/Refresh";

const HEADER_HEIGHT = "65px";

interface MelodieTrackListProps extends WithFullScreenDialogProps
{
  melodieGenreGroupOrPurpose: MelodieGenreGroupOrPurposeAPI;
  handleCloseAfterApplyingMusic: () => void;

}

function MelodieTrackList( props: MelodieTrackListProps )
{
  const melodieGenreGroupOrPurpose = props.melodieGenreGroupOrPurpose;
  const [pendingSelection, setPendingSelection] = useState<MusicSelection | undefined>( undefined );
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const selectedTrackId = getSelectedTrackIdFromPostJson( postIdea );
  const selectedTrackType = getSelectedTrackTypeFromPostJson( postIdea );
  const [tracks, setTracks] = React.useState<CommonTrackAPI[]>( [] );
  const [nextPage, setNextPage] = useState<number | undefined>( 1 );
  const [hasError, setHasError] = useState<boolean>( false );

  function handleMusicSelected( track: MusicSelection )
  {
    logMusicChanged( track.id );
    setPendingSelection( track );
  }

  function logMusicChanged( toTrackId: string )
  {
    const fromTrackType = pendingSelection ? pendingSelection.type : selectedTrackType;
    const fromTrackId = pendingSelection ? pendingSelection.id : selectedTrackId;
    const fromTrackIdToReport = musicItemHelper.getTrackIdForReporting( fromTrackType, fromTrackId );

    eventTracker.logEditPostMusicChanged( postIdea, fromTrackIdToReport, toTrackId );
  }

  async function loadMore( page: number )
  {
    // ReactInfiniteScroller page param is ignored because it doesn't sync up with the nextPage that we want to load from server
    if ( isNumber( nextPage ) )
    {
      await loadNextPageOfMelodieTracks( nextPage );
    }
  }

  async function loadNextPageOfMelodieTracks( page: number )
  {
    if ( !page )
    {
      return;
    }

    try
    {
      const response = await musicServices.getMelodieTracks( melodieGenreGroupOrPurpose.type, melodieGenreGroupOrPurpose.name, page )
      let concatTracks = [...tracks, ...response.tracks];
      setTracks( concatTracks );
      if ( response.next_page )
      {
        setNextPage( response.next_page );
      }
      else
      {
        setNextPage( undefined );
      }
    }
    catch (e)
    {
      setHasError( true );
    }
  }

  function hasMore()
  {
    return !hasError && isNumber( nextPage );
  }

  function retryOnError()
  {
    setHasError( false );
  }

  return (
    <FullPageMobileContainer>
      <EditMelodieMusicHeader title="Add music"
                              subtitle={melodieGenreGroupOrPurpose.name}
                              handleClose={props.handleClose}
                              useArrowIcon={true}
                              sx={{ height: HEADER_HEIGHT }}/>
      <FullScreenModalInnerWrap>
        <Stack width="100%" sx={{ overflow: "auto", height: `calc(100vh - ${HEADER_HEIGHT})` }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore()}
            loader={<Stack key="0" alignItems="center" sx={{ my: 4 }}><CircularProgress className="spinner small" id="spinner"/></Stack>}
            useWindow={false}>
            {map( tracks, ( track ) => <MusicItem key={track.id} track={track} handleTrackSelected={handleMusicSelected}
                                                  selected={musicItemHelper.isTrackIdSelected( track.id, TRACK_TYPE_MELODIE,
                                                    selectedTrackId,
                                                    selectedTrackType,
                                                    pendingSelection )}/> )}
            {hasError && <LoadMusicErrorView title={"Error loading tracks"}>
              <RefreshIcon onClick={retryOnError}/>
            </LoadMusicErrorView>}
          </InfiniteScroll>
        </Stack>
      </FullScreenModalInnerWrap>
      <AudioDrawer pendingSelection={pendingSelection} setPendingSelection={setPendingSelection}
                   closeAfterApplying={props.handleCloseAfterApplyingMusic}/>
    </FullPageMobileContainer>
  );
}

export const MelodieTracksFullScreenDialog = withFullScreenDialog( MelodieTrackList )
